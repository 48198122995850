import axios from "axios";
import {ENGINE_AUX_MAKER} from "@/services/ConstantService";
import {GlobalService} from "@/services/GlobalService";
import {UrlService} from "@/services/UrlService";

export const EngineAuxMakerService = {
  async getEngineAuxMakers(params) {
    return await axios.get(ENGINE_AUX_MAKER + UrlService.setQueryString(params)).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET ENGINE AUX TYPE LIST SERVICE')
      return false
    })
  },
  async createEngineAuxMaker(params) {
    return await axios.post(ENGINE_AUX_MAKER,params).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'CREATE ENGINE AUX TYPE SERVICE')
      return error?.response?.data
    })
  },

  async updateEngineAuxMaker(params) {
    return await axios.put(ENGINE_AUX_MAKER + `/${params.id}`,params).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'UPDATE ENGINE AUX TYPE SERVICE')
      return error?.response?.data ?? false
    })
  },

  async deleteEngineAuxMaker(params) {
    return await axios.delete(ENGINE_AUX_MAKER + `/${params.id}`).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'DELETE ENGINE AUX TYPE SERVICE')
      return false
    })
  },
}
