import axios from 'axios'

import {
  IMPERSONATOR_LOGOUT,
  IMPERSONATOR_TOKEN_NAME,
  IMPERSONATOR_USER_DETAILS,
  LOGIN,
  LOGOUT,
  TOKEN_NAME,
  USER_DETAILS
} from './ConstantService'
import { GlobalService } from './GlobalService'
import { AlertService } from './AlertService'
import loginModule from "@/store/modules/LoginModule";

export const AuthenticationService = {

  setHeader (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
  },

  deleteHeader () {
    delete axios.defaults.headers.common.Authorization
  },

  clearStorage () {
    // localStorage.clear(TOKEN_NAME);
    // localStorage.clear(USER_DETAILS);

    localStorage.removeItem(TOKEN_NAME);
    localStorage.removeItem(USER_DETAILS);
    localStorage.removeItem(IMPERSONATOR_TOKEN_NAME);
    localStorage.removeItem(IMPERSONATOR_USER_DETAILS);
  },

  async authenticate (credentials) {
    return await axios.post(LOGIN, credentials)
      .then(response => {
        const user = response.data.user
        const token = response.data.token
        if (user != null && token != null) {
          localStorage.setItem(TOKEN_NAME, token)
          this.setHeader(token)
          localStorage.setItem(USER_DETAILS, JSON.stringify(user))
          return response;
        }
        return false
      }).catch(error => {
        GlobalService.handleError(error, 'AUTHENTICATION SERVICE')
        this.clearStorage()
        return error?.response;
      })
  },

  async logout () {
    return await axios.get(LOGOUT).then(response => {
        const impersonator_details = localStorage.getItem(IMPERSONATOR_USER_DETAILS);
        const impersonator_token = localStorage.getItem(IMPERSONATOR_TOKEN_NAME);

        this.deleteHeader();
        this.clearStorage();

        if (response.data.hasOwnProperty('user')) {
          AuthenticationService.setHeader(impersonator_token);
          localStorage.setItem(TOKEN_NAME, impersonator_token);
          localStorage.setItem(USER_DETAILS, impersonator_details);
          localStorage.setItem(IMPERSONATOR_LOGOUT, '1');
        }

        return true;
      }).catch(error => { AlertService.errorAlert(error); return false; });
  }
}

export default {
  AuthenticationService
}
