<template>
  <div>
    <table class="small e-tbl">
      <thead class="table-thead">
      <slot name="table-header"></slot>
      </thead>
      <tbody class="table-tbody">
      <slot name="table-body"></slot>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name : 'Table',
}
</script>

<style scoped>

</style>
