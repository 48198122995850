import { KeyService } from '../../services/KeyService'

const state = {
  mainCauseKeys: [],
  subCauseKeys: [],
  errorPersonalKeys: [],
  errorManningKeys: [],
  errorOtherPartyKeys: [],
}

const mutations = {
  // eslint-disable-next-line no-return-assign
  setMainCauseKeys: (state, mainCauseKeys) => state.mainCauseKeys = mainCauseKeys,
  // eslint-disable-next-line no-return-assign
  setSubCauseKeys: (state, subCauseKeys) => state.subCauseKeys = subCauseKeys,

  // eslint-disable-next-line no-return-assign
  setErrorPersonalKeys: (state, errorPersonalKeys) => state.errorPersonalKeys = errorPersonalKeys,
  // eslint-disable-next-line no-return-assign
  setErrorManningKeys: (state, errorManningKeys) => state.errorManningKeys = errorManningKeys,
  // eslint-disable-next-line no-return-assign
  setErrorOtherPartyKeys: (state, errorOtherPartyKeys) => state.errorOtherPartyKeys = errorOtherPartyKeys,
}

const actions = {
  async getMainCauseKeys ({ commit }, params) {
    const mainCauseKeys = await KeyService.getMainCauseKeys(params)
    if (mainCauseKeys) commit('setMainCauseKeys', mainCauseKeys)
  },
  async getSubCauseKeys ({ commit }, params) {
    const subCauseKeys = await KeyService.getSubCauseKeys(params)
    if (subCauseKeys) commit('setSubCauseKeys', subCauseKeys)
  },
  async getErrorPersonalKeys ({ commit }, params) {
    const keys = await KeyService.getCauseErrorPersonalKeys(params)
    if (keys) commit('setErrorPersonalKeys', keys)
  },
  async getErrorManningKeys ({ commit }, params) {
    const keys = await KeyService.getCauseErrorManningKeys(params)
    if (keys) commit('setErrorManningKeys', keys)
  },
  async getErrorOtherKeys ({ commit }, params) {
    const keys = await KeyService.getCauseErrorOtherKeys(params)
    if (keys) commit('setErrorOtherPartyKeys', keys)
  }
}

const getters = {
  mainCauseKeys: state => state.mainCauseKeys,
  subCauseKeys: state => state.subCauseKeys,
  errorPersonalKeys: state => state.errorPersonalKeys,
  errorManningKeys: state => state.errorManningKeys,
  errorOtherPartyKeys: state => state.errorOtherPartyKeys,
}

export default {
  state,
  mutations,
  actions,
  getters
}
