import {GlobalService} from "@/services/GlobalService";

import axios from "axios";
import {PSC_INDEX, UPDATE_IA_REPORT_TYPE_BY_ID, UPDATE_ICBT_SCORE, VPI} from "@/services/ConstantService";

const state = {
  vessels_vpi : [],
}

const actions = {
  async getVesselsVpi({commit}, params) {


  //   commit('SET_LOADING_PSC_REPORTS', true);
    const response = await GlobalService.ajax('get', VPI,{params});
    const data = response?.data;

    if (params.vessel_id !== undefined && params.for_list === false) {
      return data;
    }
    commit('SET_VESSELS_VPI', data?.data);
    commit('setPagination',data);

  },
  async updateICBT({commit}, params) {
    const uri = UPDATE_ICBT_SCORE.replace('{vessel}', params.vessel_id);
    const response = await GlobalService.ajax('patch', uri, params);
    if (response.hasOwnProperty('status') && response.status === 200) {
      return {
        result : true,
        data : response.data
      };
    }
    return {
      result : false,
      message : response.message
    };
  },
}

const mutations = {
  SET_VESSELS_VPI : (state, vessels_vpi) => {
    state.vessels_vpi = vessels_vpi;
  }
}

const getters = {
  vessels_vpi: (state) => state.vessels_vpi
}

export default {
  state,
  actions,
  mutations,
  getters
}
