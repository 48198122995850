<template>
  <div class="card p-3 shadow-sm">
    <template v-if="isLoading">
      <app-loading></app-loading>
    </template>
    <template v-else>
      <div v-if="isAllowed('accident-incident.attachment.documents.view-download') && isOwnVessel()">
        <div class="d-flex justify-content-between mb-2">
          <h6 class="font-weight-bold">Attached Reports / Documents</h6>
          <div>
            <template v-if="isAllowed('accident-incident.attachment.documents.create') && isOwnVessel()">
              <button href="#" class="e-btn e-btn-outlined-red" id="cancelBtn" role="button" v-if="toEditOther" @click="cancelUploadDocs">Cancel</button>
              <button href="#" class="e-btn e-btn-green ml-2" id="addBtn" type="button" v-else @click="toEditOther=true">Add Attachment</button>
            </template>
            <template v-if="isAllowed('accident-incident.attachment.documents.download-all') && isOwnVessel()">
              <button href="#" class="e-btn e-btn-blue ml-2" id="downloadBtn" role="button" v-show="documents.length"  v-if="!toEditOther"   @click.prevent="downloadAttachments('file')">Download All Documents</button>
            </template>
          </div>
        </div>
        <div class="row px-2" v-if="!toEditOther" id="tableAttachment">
          <template v-if="documents.length">
            <div class="col-12" v-for="(attachment) in documents">
                <div class="row">
                  <div class="col-4">
                    <img :src="attachment.thumbnail" class="mr-2" alt="Attachment Thumbnail"
                         style="width: 20px">{{ attachment.attachment_name }}
                  </div>
                  <div class="col-2">
                    {{ attachment.created_at }}
                  </div>
                  <div class="col-2">
                    {{ attachment.file_size }}
                  </div>
                  <div class="col-3">
                    <button class="btn btn-secondary btn-xs mr-2" @click="viewAttachment(attachment)"
                            v-if="isAllowed('accident-incident.attachment.documents.view-download') && isOwnVessel()">
                      View / Download Attachment
                    </button>
                    <button class="btn btn-danger btn-xs" @click="removeAttachment(attachment)"
                            v-if="isAllowed('accident-incident.attachment.documents.delete') && isOwnVessel()">
                      Delete Attachment
                    </button>
                  </div>
                </div>
              </div>
          </template>
        </div>
        <div class="row  no-gutters" v-else>
          <div class="col-3">
            <div class="form-group">
              <label for="attachmentList">Upload file(s) you want to add</label>
              <input type="file" class="form-control-file" id="docLists"
                     @change.prevent="saveDocument"
                     :accept="mimes.join(',')"
                     multiple>
            </div>
          </div>
        </div>
        <hr>
      </div>
      <div>
        <div class="d-flex justify-content-between mb-2">
          <h6 class="font-weight-bold">Attached Images / Drawings</h6>
          <div>
            <template v-if="isAllowed('accident-incident.attachment.images.create') && isOwnVessel()">
              <button class="e-btn e-btn-outlined-red" type="button" v-if="toEditImage" @click="cancelUploadImages">Cancel</button>
              <button class="e-btn e-btn-green ml-2" type="button" v-else @click="toEditImage=true">Add Attachment</button>
            </template>
            <template v-if="isAllowed('accident-incident.attachment.images.download-all') && isOwnVessel()">
              <button class="e-btn e-btn-blue ml-2" v-show="images.length" type="submit" v-if="!toEditImage"  @click.prevent="downloadAttachments('image')">Download All Images</button>
            </template>
            <!--            <button class="btn btn-xs btn-primary ml-2" type="submit" v-if="toEditImage" @click.prevent="saveDocument(false)" :disabled="imagesUpload ===0">Save</button>-->
          </div>
        </div>
        <div class="row" v-if="!toEditImage">
          <div  v-if="images.length" class="col-lg-1 col-md-4 col-sm-12 mb-1 justify-content-center align-content-center"
                v-for="(attachment, attachmentIndex) in images">
            <image-comp :imageInfo="attachment"
                        @initDownload="downloadImage"
                        @initDelete="removeAttachment"
                        @initView="viewImage(attachmentIndex, images, {
                                         'path'      : 'thumbnail',
                                         'caption'   : 'attachment_name',
                                         })"
            >

            </image-comp>
          </div>
        </div>
        <div class="row no-gutters" v-else>
          <div class="col-3">
            <div class="form-group">
              <label for="attachmentList">Upload file(s) you want to add</label>
              <input type="file" class="form-control-file" id="attachmentList"
                     :accept="imageMimes"
                     @change.prevent="saveDocument(false)"
                     multiple>
<!--                     @change="countImages"-->

            </div>
          </div>
        </div>
        <!--    FOR IMAGE SLIDER OF ATTACHMENTS   -->
        <image-slider
          :selected-image-index="selectedImageIndex"
          :images="sliderImages"
          :visible="showSlider"
          @hideSlider="resetSlider()"
        >
        </image-slider>
      </div>
    </template>

  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {ReportService} from "@/services/ReportService";
import {FileService} from "@/services/FileService";
import {AlertService} from "@/services/AlertService";
import AppLoading from "@/components/elements/AppLoading";
import ImageComp from "@/components/elements/ImageComp";
import OtherComp from "@/components/elements/OtherComp";
import {permissionMixin} from "@/mixins/permissionMixins";
import ImageSlider from "@/components/common/ImageSlider.vue";
import ImageSliderMixin from "@/mixins/ImageSliderMixin";
import PscImageComponent from "@/components/modules/psc/PscImageComponent.vue";

export default {
  name: "Attachment",
  mixins:[permissionMixin, ImageSliderMixin],
  components:{
    PscImageComponent,
    ImageComp : ImageComp ,
    OtherComp : OtherComp,
    AppLoading,
    ImageSlider
  },
  data() {
    return {
      toEditImage: false,
      toEditOther:false,
      reportId: this.$route.params.id,
      isLoading:false,
      imagesUpload:0,
      filesUpload:0,
      imageMimes:[
        'image/apng',
        'image/avif',
        'image/gif',
        'image/jpeg',
        'image/png',
        'image/svg+xml',
        'image/webp',
        'image/jpg',
        'image/jpeg',
        'image/png',
        'image/tiff',
        'image/gif',
      ],
      mimes: [
        'application/pdf,application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/msword',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/zip',
        'application/octet-stream',
        'application/x-zip-compressed',
        'multipart/x-zip'
      ]
    }
  },
  computed: {
    ...mapGetters(['accidentIncidentReport','images','documents']),
  },
  async created() {
    const params = {
      reportId: this.$route.params.id
    }
    this.isLoading=true;
    params.isImage=1;
    await this.getAccidentIncidentReportAttachments(params);
    params.isImage=0
    await this.getAccidentIncidentReportAttachments(params);
    this.isLoading=false;
  },
  methods: {
    ...mapActions(['getAccidentIncidentReport','getAccidentIncidentReportAttachments','addAttachments','deleteAttachment']),
    async cancelUploadImages(){

      if(await AlertService.cancelAlert()){
        this.imagesUpload=0;
        this.toEditImage = false
      }

    },
    async cancelUploadDocs(){
      if(await AlertService.cancelAlert()) {
        this.filesUpload = 0;
        this.toEditOther = false
      }
    },
    async viewAttachment(attachment) {
      swal.fire({
        title: 'Please wait',
      });
      swal.showLoading();
      const response = await ReportService.generateAttachment({id:attachment.id});
        if (response) {
          if (attachment.extension === 'pdf' || attachment.mime_type.includes("image")) {
             await FileService.viewDocument(response,attachment)
          }
          else {
            await FileService.downloadDocument(response,attachment);
          }
          swal.close();
        }
    },

    async removeAttachment(attachment) {
      if (await AlertService.confirmDeleteAlert(null,'Are you sure you want to Delete this Attachment?', 'Yes, Delete Attachment!')) {
        swal.fire({
          title: 'Deleting Attachment Please Wait...',
        });
        swal.showLoading();
        const response=await this.deleteAttachment(attachment)
        if (response.success) {
          swal.close();
          const params={
            reportId:this.$route.params.id,
            isImage: attachment.isImage ? 1 : 0
          }
          await this.getAccidentIncidentReportAttachments(params)
          await AlertService.successAlert('ATTACHMENT DELETED SUCCESSFULLY','REMOVE ATTACHMENT');
          await this.getAccidentIncidentReport(this.reportId)
        }
      }
    },

    async saveDocument(isFile=true) {
       const fileMessage=  isFile ? 'files' : 'images'
       swal.fire({
         'title':`Uploading ${fileMessage} <br> Please wait.....`
       })

       swal.showLoading()

       let formData = new FormData();

       const file = isFile ? document.getElementById('docLists') : document.getElementById('attachmentList');
        Array.prototype.forEach.call(file.files, file => {
            formData.append('attachments[]', file);
        })

        formData.append('id',this.reportId);

        await this.addAttachments(formData)
        swal.close();
        await AlertService.successAlert(`New ${fileMessage} uploaded successfully`, 'UPLOAD ATTACHMENTS');
        await this.getAccidentIncidentReport(this.reportId)
        if(!isFile) this.toEditImage = false;

        if(isFile)this.toEditOther = false;
    },
    async downloadImage(attachment){
      swal.fire({
        title: 'Please wait',
      });
      swal.showLoading();
      const response = await ReportService.generateAttachment({id:attachment.id});
      if (response) {
        await FileService.downloadDocument(response,attachment)
        swal.close();
      }
    },
    async downloadAttachments(file_type='image'){
      swal.fire({
        title: 'Please wait',
      });
      swal.showLoading();
     let response=await ReportService.downloadAttachment({
        fileType:file_type,
        id:this.$route.params.id
     })
      if(response){
          let fileBlob=FileService.base64FileToBlob(response.file,response.mime_type);
          let url = window.URL.createObjectURL(fileBlob);
          const link = document.createElement('a');
          link.setAttribute('href', url);
          link.setAttribute('download', response.file_name);
          document.body.appendChild(link);
          link.click();
      }
      swal.close();
    }
  }
}
</script>

<style scoped>
  #tableAttachment div {
    padding: 1px 10px;
    border:0;
  }
  #tableAttachment .row:hover{
    background: #e5e3e3;
  }
</style>
