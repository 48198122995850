<template>
  <vue-image-lightbox-carousel
    ref="lightbox"
    :show="sliderVisibility"
    :images="images"
    @change="changeImage"
  >
  </vue-image-lightbox-carousel>
</template>
<script>

import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'

export default {
  props:{
    selectedImageIndex:{
      type: Number,
      default:null,
    },
    images : {
      type: Array,
      default: [],
    },
    visible: {
      type: Boolean,
      default : false
    }
  },
  components: {VueImageLightboxCarousel},
  data() {
    return {
      activeImageIndex : 0,
      sliderVisibility : false,
      keyDownPressed : false,
      keyUpPressed : false,
    }
  },
  methods: {
    changeImage(index) {
      this.activeImageIndex = index;
    },
    handleKeyDown(event) {
      if (this.keyDownPressed === false) {
        this.keyDownPressed = true;
        if (event.keyCode === 37) {
          $('button.swiper-button-prev').first().click();
        }
        if (event.keyCode === 39) {
          $('button.swiper-button-next').first().click();
        }
      }
    },
    handleKeyUp() {
      this.keyDownPressed = false;
    }
  },
  watch: {
    selectedImageIndex: function () {
      this.activeImageIndex = this.selectedImageIndex;
    },
    visible: function () {
      this.sliderVisibility = this.visible;
      this.$refs.lightbox.showImage(this.activeImageIndex);
      if (this.visible === true) {
        setTimeout(() => {
          $('.vue-lightbox-info').find('h4').css({
            color: '#fff',
          }).addClass('no-select');
        }, 150);
      }
    }
  },
  mounted() {
    $('.vue-lightbox-close').click(() => {
      this.$emit('hideSlider');
    });

    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('keyup', this.handleKeyUp);
  },
  beforeDestroy() {
    // Remove the event listener when the component is destroyed
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('keyup', this.handleKeyUp);
  },
};
</script>

<style>
</style>
