import {DashboardService} from "@/services/DashboardService";

const state = {
  airReportStatusCounts: [],
  airChartCounts:[],
};


const mutations = {
  SET_AIR_REPORT_STATUS_COUNTS: (state, payload) => state.airReportStatusCounts = payload,
  SET_AIR_CHART_COUNT: (state, payload) => state.airChartCounts = payload,
};


const actions = {
  async getAirReportStatusCounts({commit},params){
    const response= await DashboardService.getAirReportStatusCounts(params);
    if(response){
      commit('SET_AIR_REPORT_STATUS_COUNTS',response)
    }
  },
  async getAccidentCharts({commit},params){
    const response= await DashboardService.getAccidentCharts(params);
    if(response){
      commit('SET_AIR_CHART_COUNT',response)
    }
  },
};


const getters = {
  airReportStatusCounts: state => state.airReportStatusCounts,
  airChartCounts: state => state.airChartCounts,
};

export default {state, actions, mutations, getters};
