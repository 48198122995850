import axios from "axios";
import {GlobalService} from "@/services/GlobalService";
import {
  ADD_SISTER_SHIP_GROUP,
  DELETE_SISTER_SHIP_GROUP, GER_SISTER_VESSEL_INFO,
  GET_SISTER_SHIP_GROUPS, GET_SISTER_VESSEL_INFO,
  UPDATE_SISTER_GROUP
} from "@/services/ConstantService";


export const SisterShipService={
  async getSisterGroups(){
    return await axios.get(GET_SISTER_SHIP_GROUPS).then(response=>{
      return response.data?.data ?? false
    }).catch(error=>{
      GlobalService.handleError(error,'GET SISTER GROUP')
      return false;
    })
  },
  async addSisterGroup(params){
    return await axios.post(ADD_SISTER_SHIP_GROUP,params).then(response=>{
      return response.data?.data ?? false
    }).catch(error=>{
      GlobalService.handleError(error,'ADD SISTER GROUP')
      return error.response.data;
    })
  },

  async deleteSisterGroup(params){
    return await axios.post(DELETE_SISTER_SHIP_GROUP,params).then(response=>{
      return response?.data ?? false
    }).catch(error=>{
      return GlobalService.handleError(error,'DELETE SISTER GROUP')
    })
  },

  async updateSisterGroup(params){
    return await axios.post(UPDATE_SISTER_GROUP,params).then(response=>{
      return response?.data ?? false
    }).catch(error=>{
      GlobalService.handleError(error,'UPDATE SISTER GROUP')
      return false;
    })
  },

  async getSisterVesselInfo(params){
    console.log('get sistership vessel info params', params)
    return await axios.post(GET_SISTER_VESSEL_INFO, params).then(response=>{
      return response.data ?? false
    }).catch(error=>{
      GlobalService.handleError(error,'GET SISTER VESSELS')
      return false;
    })
  }


}
