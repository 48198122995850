import {EngineTcMakerService} from "@/services/EngineTcMakerService";
import {GlobalService} from "@/services/GlobalService";

const state={
  engineTcMaker:[],
}

const mutations={
  SET_ENGINE_TC_MAKER : (state,payload)=>state.engineTcMaker=payload,
  ADD_ENGINE_TC_MAKER : (state,payload)=>{
    if(state.engineTcMaker.length > 15) state.engineTcMaker.pop()

    state.engineTcMaker.push(payload)

    state.engineTcMaker.sort((a,b)=> GlobalService.sortFunc(a,b));
  },

  UPDATE_ENGINE_TC_MAKER : (state,payload) => {
    let index= state.engineTcMaker.findIndex(maker=> maker.id===payload.id);

    state.engineTcMaker.splice(index,1,payload);

    state.engineTcMaker.sort((a,b)=> GlobalService.sortFunc(a,b));
  }
}

const actions={
  async getEngineTcMakers({commit},params){
    const response= await EngineTcMakerService.getEngineTcMakers(params)
    const isGet= params?.get ?? false
    if(response && !isGet){
      commit ('setPagination',response)
      commit ('SET_ENGINE_TC_MAKER',response.data)
    }
    else{
      commit ('SET_ENGINE_TC_MAKER',response)
    }

    return response;
  },

  async createEngineTcMaker({commit},params){
    const response= await EngineTcMakerService.createEngineTcMaker(params)
    if(!response.errors){
      commit ('ADD_ENGINE_TC_MAKER',response)
    }
    return response;
  },

  async updateEngineTcMaker({commit},params){
    const response= await EngineTcMakerService.updateEngineTcMaker(params)
    if(!response.errors){
      commit ('UPDATE_ENGINE_TC_MAKER',response)
    }
    return response;
  },

  async deleteEngineTcMaker({commit},params){
    return await EngineTcMakerService.deleteEngineTcMaker(params);
  }
}

const getters={
  engineTcMaker:(state)=>GlobalService.capitalizeProperties(state.engineTcMaker)
}


export default {state,actions,mutations,getters}
