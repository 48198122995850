import axios from "axios";
import {ALL_USERS, ENGINE_MAKER} from "@/services/ConstantService";
import {GlobalService} from "@/services/GlobalService";
import {UrlService} from "@/services/UrlService";

export const EngineMakerService = {
  async getEngineMakers(params) {
    return await axios.get(ENGINE_MAKER + UrlService.setQueryString(params) ).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET ENGINE MAKER LIST SERVICE')
      return false
    })
  },
  async createEngineMaker(params) {
    return await axios.post(ENGINE_MAKER,params).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'CREATE ENGINE MAKER SERVICE')
      return error?.response?.data
    })
  },

  async updateEngineMaker(params) {
    return await axios.put(ENGINE_MAKER + `/${params.id}`,params).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'UPDATE ENGINE MAKER SERVICE')
      return error?.response?.data ?? false
    })
  },

  async deleteEngineMaker(params) {
    return await axios.delete(ENGINE_MAKER + `/${params.id}`).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'DELETE ENGINE MAKER SERVICE')
      return false
    })
  },
}
