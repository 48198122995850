import axios from "axios";
import {ENGINE_DG_MAKER} from "@/services/ConstantService";
import {GlobalService} from "@/services/GlobalService";
import {UrlService} from "@/services/UrlService";

export const EngineDgMakerService = {
  async getEngineDgMakers(params) {
    return await axios.get(ENGINE_DG_MAKER + UrlService.setQueryString(params)).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET ENGINE DG MAKER LIST SERVICE')
      return false
    })
  },
  async createEngineDgMaker(params) {
    return await axios.post(ENGINE_DG_MAKER,params).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'CREATE ENGINE DG MAKER SERVICE')
      return error?.response?.data
    })
  },

  async updateEngineDgMaker(params) {
    return await axios.put(ENGINE_DG_MAKER + `/${params.id}`,params).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'UPDATE ENGINE DG MAKER SERVICE')
      return error?.response?.data ?? false
    })
  },

  async deleteEngineDgMaker(params) {
    return await axios.delete(ENGINE_DG_MAKER + `/${params.id}`).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'DELETE ENGINE DG MAKER SERVICE')
      return false
    })
  },
}
