import store from '@/store';
import {AlertService} from "@/services/AlertService";

export default {
  data() {
    return {
      vessel_id       : null,
      audit_record_id : null,
      audit_type      : null,
      ncr_id          : null,
      oe_id           : null
    }
  },
  methods: {
    async initialize(params) {
      this.vessel_id = params.vessel_id ?? null;
      this.audit_record_id = params.id ?? null;
      this.audit_type = params.type ?? null;
      this.ncr_id = params.ncr_id ?? null;
      this.oe_id = params.oe_id ?? null;

      if (this.vessel_id !== null) {
        await store.dispatch('getVesselDetail', params.vessel_id)
      }

      if (this.audit_record_id !== null) {
        await store.dispatch('getVesselAuditRecordById', params.id);
      }
    },
    routeUnderAudit(routeName) {
      const routeNames = [
        'internalAuditReport',
        'NonConformity',
        'IaCorrectiveActionReport',
        'IAShipParticular'
      ];

      return routeNames.includes(routeName);
    },
    async initializeNcr(routeName) {
      if ((this.ncr_id !== null && store.getters.selectedAuditRecord[this.audit_type] !== null) || this.routeUnderAudit(routeName) === true) {
        await store.dispatch('getRootCausesList', this.audit_type);
        await store.dispatch('getNonConformities', {
          id   : store.getters.selectedAuditRecord[this.audit_type].id,
          type : this.audit_type
        });
        await store.dispatch('getInternalAudit', {
          id : store.getters.selectedAuditRecord[this.audit_type].id,
          type : this.audit_type
        });
      }
    },
    async validateVesselById() {
      return !(this.vessel_id !== null && !(Object.keys(store.getters.vessel).length > 0));
    },
    async validateAuditReportById() {
      return !(this.audit_record_id !== null && store.getters.selectedAuditRecord[this.audit_type] === null);
    },
    async validateSelectedAuditType() {
      const auditTypes = ['sms', 'isps', 'cyber'];
      return !(this.audit_type !== null && (auditTypes.includes(this.audit_type) === false || store.getters.selectedAuditRecord[this.audit_type] === null));
    },
    async validateNonConformityById() {
      const invalidValueToRequestNcr = [undefined, 'index'];
      if (this.ncr_id !== null && invalidValueToRequestNcr.includes(this.ncr_id) === false) {
        const selectedNcrIndex = store.getters.nonConformities[this.audit_type].findIndex(ncr => ncr.id === parseInt(this.ncr_id));
        if (selectedNcrIndex === -1) {
          return false;
        }
      }
      return true;
    },
    async validateObjectiveEvidenceById() {
      const invalidOeParams = [undefined, 'index'];
      if (this.oe_id !== null && invalidOeParams.includes(this.oe_id) === false) {
        const selectedNcrIndex = store.getters.nonConformities[this.audit_type].findIndex(ncr => ncr.id === parseInt(this.ncr_id));
        const selectedOeIndex = store.getters.nonConformities[this.audit_type][selectedNcrIndex].objective_evidences.findIndex(oe => oe.id === parseInt(this.oe_id));
        if (selectedOeIndex === -1) {
          return false;
        }
      }
      return true;
    },
    async redirectToVesselList(next) {
      await AlertService.errorAlert('The Audit Record Link you are trying to access is invalid!', 'ACCESSING AUDIT REPORT');
      await next({name: 'VesselList'});
    }
  },
}
