import {ACKNOWLEDGEMENT_STATUSES} from "../../services/ConstantService";
import {UrlService} from "../../services/UrlService";
import {GlobalService} from "../../services/GlobalService";

const state={
  acknowledgementStatuses:{}
}

const mutations={

  SET_ACKNOWLEDGEMENT_STATUSES : (state,status) => state.acknowledgementStatuses=status

}

const actions = {

  async getAcknowledgementStatuses({commit},payload = null){
    const uri = [ACKNOWLEDGEMENT_STATUSES].join('/');
    const queryString= payload ? UrlService.setQueryString(payload) : '';
    const response  = await GlobalService.ajax('get',uri+queryString,{});
    if(response.status == 200) {
      commit('SET_ACKNOWLEDGEMENT_STATUSES',response.data);
      return true;
    }
    return false;
  }

}

const getters = {
  acknowledgementStatuses: (state)=>state.acknowledgementStatuses
}


export default { state,mutations,actions,getters }
