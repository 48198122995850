import axios from "axios";
import {ENGINE_DG_TYPE} from "@/services/ConstantService";
import {GlobalService} from "@/services/GlobalService";
import {UrlService} from "@/services/UrlService";

export const EngineDgTypeService = {
  async getEngineDgTypes(params) {
    return await axios.get(ENGINE_DG_TYPE+UrlService.setQueryString(params)).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET ENGINE DG TYPE LIST SERVICE')
      return false
    })
  },
  async createEngineDgType(params) {
    return await axios.post(ENGINE_DG_TYPE,params).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'CREATE ENGINE DG TYPE SERVICE')
      return error?.response?.data
    })
  },

  async updateEngineDgType(params) {
    return await axios.put(ENGINE_DG_TYPE + `/${params.id}`,params).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'UPDATE ENGINE DG TYPE SERVICE')
      return error?.response?.data ?? false
    })
  },

  async deleteEngineDgType(params) {
    return await axios.delete(ENGINE_DG_TYPE + `/${params.id}`).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'DELETE ENGINE DG TYPE SERVICE')
      return false
    })
  },
}
