<template>
  <div id="attachment-card" class="card py-1"  :title="imageInfo.name + '.'+ imageInfo.extension">
    <div class="dropdown dropright mb-2">
      <div
        data-toggle="dropdown"
        id="attachmentDropdown"
        class="text-center"
        aria-haspopup="true" style="cursor:pointer"
      >
        <div class="card-img-top text-center">
          <img :src="imageInfo.thumbnail" alt="Attachment Thumbnail" style="width: 300px">
        </div>
        <small id="attachment-name">{{imageInfo.name + '.'+ imageInfo.extension}}</small>
      </div>
      <div aria-labelledby="attachmentDropdown" class="dropdown-menu">
        <button class="dropdown-item text-secondary font-weight-bolder"
                type="button"
                @click="$emit('viewImage')"
                v-if="isAllowed(viewPermission) && isOwnVessel() && attachment.id !== 0">
          <font-awesome-icon class="ml-2 mr-1 text-primary" icon="eye"/>
          View Attachment
        </button>
        <button class="dropdown-item text-secondary font-weight-bolder"
                type="button"
                @click="viewDownloadFile(attachment,type,'download')"
                v-if="isAllowed(downloadPermssion) && isOwnVessel() && attachment.id !== 0">
          <font-awesome-icon class="ml-2 mr-1 text-primary" icon="download"/>
          Download Attachment
        </button>
        <button class="dropdown-item  text-danger font-weight-bolder"
                type="button"
                @click="$emit('deleteEmitter',{attachment:attachment,type:type})"
                v-if="isAllowed(deletePermission) && isOwnVessel() && toEdit === true">
          <font-awesome-icon class="ml-2 mr-1 force-danger-all" icon="trash"/>
          Delete Attachment
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import {permissionMixin} from "@/mixins/permissionMixins";
import attachmentMixin from "@/mixins/attachmentMixin";
import {mapGetters} from "vuex";

export default {
  name: "PscImageComponent",
  mixins:[permissionMixin,attachmentMixin],
  data() {
    return{
      image:''
    }
  },
  props:{
    imageInfo:{
      type:Object,
      default:null,
    },
    viewPermission: {
      type:String,
      default:null,
    },
    downloadPermssion: {
      type:String,
      default:null,
    },
    deletePermission: {
      type:String,
      default:null,
    },
    type:{
      type:Number,
      default:null,
    }
  },
  computed:{
    ...mapGetters(['toEdit']),
    attachment() {
      return this.imageInfo;
    }
  },
}
</script>

<style scoped>
#attachment-card:hover > .dropdown small {
  color: white;
}

#attachment-card:hover {
  background: rgba(255, 0, 0, 0.7);
  transition: 0.5s;
}

#attachment-name{
  width: 100%;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#attachment-card.e-bg-green  small {
  color: white;
}
</style>
