import Swal from 'sweetalert2'

export const AlertService = {
  initializeSwal () {
    window.swal = Swal

    window.confirmCreateAlert = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-warning font-weight-bolder mr-2',
        cancelButton: 'btn btn-outline-secondary font-weight-bolder'
      },
      buttonsStyling: false
    })

    window.confirmUpdateAlert = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-warning font-weight-bolder mr-2',
        cancelButton: 'btn btn-outline-secondary font-weight-bolder'
      },
      buttonsStyling: false
    })

    window.deleteAlert = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-danger font-weight-bolder mr-2',
        cancelButton: 'btn btn-outline-secondary font-weight-bolder'
      },
      buttonsStyling: false
    })

    window.serverErrorAlert = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-danger font-weight-bolder mr-2'
      },
      buttonsStyling: false
    })

    window.successAlert = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success font-weight-bolder mr-2'
      },
      buttonsStyling: false
    })

    window.errorAlert = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-danger font-weight-bolder mr-2'
      },
      buttonsStyling: false,
      allowOutsideClick: false
    })

    window.cancelAlert = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-sm btn-primary mr-2',
        cancelButton:  'btn btn-sm btn-danger'
      },
      buttonsStyling: false
    })

    window.companyInstructionSend = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success font-weight-bolder mr-2',
        cancelButton: 'btn btn-outline-secondary font-weight-bolder'
      },
      icon: 'question',
      buttonsStyling: false
    })

    window.companyInstructionCancel = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-danger font-weight-bolder mr-2',
        cancelButton: 'btn btn-outline-secondary font-weight-bolder'
      },
      icon: 'question',
      buttonsStyling: false
    })

    window.systemUpdateModal = Swal.mixin({
      showCloseButton: true,
      showConfirmButton: false,
      focusConfirm: false,
      customClass: 'swal-wide'
    })
  },

  serverErrorAlert (message) {
    // eslint-disable-next-line no-undef
    serverErrorAlert.fire({
      title: 'SERVER ERROR',
      text: `ERROR ON ${message}`,
      footer: '<a href="mailto:albert.arbowez@elitesdsi.com">PLEASE CONTACT SYSTEM ADMINISTRATOR</a>',
      scrollbarPadding: false
    })
  },

  loading () {
    Swal.fire({
      title: 'PLEASE WAIT..',
      imageWidth: 100,
      imageHeight: 50,
      imageAlt: 'Custom image',
      showConfirmButton: false,
      scrollbarPadding: false
    })
  },

  async confirmCreateAlert (message) {
    if (!message) message = 'Are You Sure You Want To Add This Record?'
    // eslint-disable-next-line no-undef
    return await confirmCreateAlert.fire({
      title: 'ADD RECORD CONFIRMATION',
      text: message,
      showCancelButton: true,
      confirmButtonText: 'Yes, Add it!',
      scrollbarPadding: false
    }).then((result) => {
      return result.value
    })
  },

  async confirmUpdateAlert (message,confirmButtonText='Yes, Update it!') {
    if (!message) message = 'Are You Sure You Want To Update This Record?'
    // eslint-disable-next-line no-undef
    return await confirmUpdateAlert.fire({
      title: 'CONFIRM UPDATE',
      text: message,
      showCancelButton: true,
      confirmButtonText: confirmButtonText,
      scrollbarPadding: false
    }).then((result) => {
      return result.value
    })
  },

  async confirmDeleteAlert (title, message, confirmBtnMsg) {
    if (!title) title = 'CONFIRM DELETION'
    if (!message) message = 'Are you sure you want to Delete this Record?'
    if (!confirmBtnMsg) confirmBtnMsg = 'Are you sure you want to Delete this Record?'
    // eslint-disable-next-line no-undef
    return await deleteAlert.fire({
      title: title,
      text: message,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: confirmBtnMsg,
      scrollbarPadding: false
    }).then((result) => {
      return result.value
    })
  },

  async cancelAlert (){
    return await cancelAlert.fire({
      title:'CONFIRM CANCELLATION',
      text: 'Do you want to Cancel?',
      type: 'question',
      showCancelButton: true,
      allowOutsideClick:false,
      allowEscapeKey:false,
      allowEnterKey:false,
    }).then((result) => {
      return result.value
    })
  },

  async cancelChanges (){
    return await cancelAlert.fire({
      title:'YOU HAVE UNSAVED CHANGES',
      text: 'Are you sure you want to Leave without Saving? Any Unsaved Progress will be Lost.',
      type: 'question',
      showCancelButton: true,
      allowOutsideClick:false,
      allowEscapeKey:false,
      allowEnterKey:false,
    }).then((result) => {
      return result.value
    })
  },

  successAlert (message, actionType) {
    // eslint-disable-next-line no-undef
    successAlert.fire({
      title: `${actionType} SUCCESS!`,
      text: message,
      showConfirmButton: true,
      timer: 1500,
      scrollbarPadding: false
    })

    return message;
  },

  async companyInstructionAlertSend (message = 'Do you want to Send this Company Instruction?'){
    return await companyInstructionSend.fire({
      title:`SEND COMPANY INSTRUCTION`,
      text:  message,
      type: 'question',
      showCancelButton: true,
      allowOutsideClick:false,
      allowEscapeKey:false,
      allowEnterKey:false,
    }).then((result) => {
      return result.value
    })
  },

  async companyInstructionAlertCancel (message = 'Do you want to Cancel this Company Instruction?'){
    return await companyInstructionCancel.fire({
      title:`CANCELLATION OF COMPANY INSTRUCTION`,
      text:  message,
      type: 'warning',
      showCancelButton: true,
      allowOutsideClick:false,
      allowEscapeKey:false,
      allowEnterKey:false,
    }).then((result) => {
      return result.value
    })
  },


  errorAlert (message, actionType) {
    // actionType = actionType + "";
    swal.close();
    // eslint-disable-next-line no-undef
    errorAlert.fire({
      title: `${actionType.toUpperCase()} FAILED`,
      html: message,
      showConfirmButton: true,
      allowOutsideClick:false,
      allowEscapeKey:false,
      scrollbarPadding: false
    })
  }
}
