import {EngineAuxMakerService} from "@/services/EngineAuxMakerService";
import {GlobalService} from "@/services/GlobalService";

const state={
  engineAuxMaker:[],
}

const mutations={
  SET_ENGINE_AUX_MAKER : (state,payload)=>state.engineAuxMaker=payload,
  ADD_ENGINE_AUX_MAKER : (state,payload)=>{
    if(state.engineAuxMaker.length > 15) state.engineAuxMaker.pop()

    state.engineAuxMaker.push(payload)

    state.engineAuxMaker.sort((a,b)=> GlobalService.sortFunc(a,b));
  },

  UPDATE_ENGINE_AUX_MAKER : (state,payload) => {
    let index= state.engineAuxMaker.findIndex(maker=> maker.id===payload.id);

    state.engineAuxMaker.splice(index,1,payload);

    state.engineAuxMaker.sort((a,b)=> GlobalService.sortFunc(a,b));
  }
}

const actions={
  async getEngineAuxMakers({commit},params){
    const response= await EngineAuxMakerService.getEngineAuxMakers(params)
    const isGet= params?.get ?? false
    if(response && !isGet){
      commit('setPagination',response)
      commit ('SET_ENGINE_AUX_MAKER',response.data)
    }
    else {
      commit ('SET_ENGINE_AUX_MAKER',response)
    }
    return response;
  },

  async createEngineAuxMaker({commit},params){
    const response= await EngineAuxMakerService.createEngineAuxMaker(params)
    if(!response.errors){
      commit ('ADD_ENGINE_AUX_MAKER',response)
    }
    return response;
  },

  async updateEngineAuxMaker({commit},params){
    const response= await EngineAuxMakerService.updateEngineAuxMaker(params)
    if(!response.errors){
      commit ('UPDATE_ENGINE_AUX_MAKER',response)
    }
    return response;
  },

  async deleteEngineAuxMaker({commit},params){
    return await EngineAuxMakerService.deleteEngineAuxMaker(params);
  }
}

const getters={
  engineAuxMaker:(state)=>GlobalService.capitalizeProperties(state.engineAuxMaker)
}


export default {state,actions,mutations,getters}
