import { KeyService } from '../../services/KeyService'

const state = {
  timezoneKeys: []
}

const mutations = {
  // eslint-disable-next-line no-return-assign
  setTimezoneKeys: (state, timezoneKeys) => state.timezoneKeys = timezoneKeys
}

const actions = {
  async getTimezoneKeys ({ commit }) {
    const timezoneKeys = await KeyService.getTimezonKeys()
    if (timezoneKeys) commit('setTimezoneKeys', timezoneKeys)
  }
}

const getters = {
  timezoneKeys: state => state.timezoneKeys
}

export default {
  state,
  mutations,
  actions,
  getters
}
