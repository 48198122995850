import {GlobalService} from "@/services/GlobalService";
import {EngineTypeService} from "@/services/EngineTypeService";

const state={
  engineType:[],
}

const mutations={
  SET_ENGINE_TYPE : (state,payload)=>state.engineType=payload,
  ADD_ENGINE_TYPE : (state,payload)=>{
    if(state.engineType.length > 15) state.engineType.pop()

    state.engineType.push(payload)

    state.engineType.sort((a,b)=> GlobalService.sortFunc(a,b));
  },

  UPDATE_ENGINE_TYPE : (state,payload) => {
    let index= state.engineType.findIndex(maker=> maker.id===payload.id);

    state.engineType.splice(index,1,payload);

    state.engineType.sort((a,b)=> GlobalService.sortFunc(a,b));
  }
}

const actions={
  async getEngineTypes({commit},params){
    const response= await EngineTypeService.getEngineType(params)
    const isGet= params?.get ?? false
    if(response && !isGet){
      commit ('setPagination',response)
      commit ('SET_ENGINE_TYPE',response.data)
    }
    else{
      commit ('SET_ENGINE_TYPE',response)
    }
    return response;
  },

  async createEngineType({commit},params){
    const response= await EngineTypeService.createEngineType(params)
    if(!response.errors){
      commit ('ADD_ENGINE_TYPE',response)
    }
    return response;
  },

  async updateEngineType({commit},params){
    const response= await EngineTypeService.updateEngineType(params)
    if(!response.errors){
      commit ('UPDATE_ENGINE_TYPE',response)
    }
    return response;
  },

  async deleteEngineType({commit},params){
    return await EngineTypeService.deleteEngineType(params);
  }
}

const getters={
  engineType:(state)=>GlobalService.capitalizeProperties(state.engineType)
}


export default {state,actions,mutations,getters}
