import { KeyService } from '@/services/KeyService'

/** state **/
const state = {
  mngmtCompanyKeys: []
}

/** mutations **/
const mutations = {
  // eslint-disable-next-line no-return-assign
  setMngmtCompanyKeys: (state, mngmtCompanyKeys) => state.mngmtCompanyKeys = mngmtCompanyKeys
}

/** actions **/
const actions = {
  async getManagementCompanyKeys ({ commit }) {
    const managementComapnyKeys = await KeyService.getMngmtCompanyKeys()
    if (managementComapnyKeys) commit('setMngmtCompanyKeys', managementComapnyKeys)
  }
}

/** getters **/
const getters = {
  mngmtCompanyKeys: state => state.mngmtCompanyKeys
}

/** export **/
export default { state, actions, mutations, getters }
