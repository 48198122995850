import axios from "axios";
import {ENGINE_TYPE} from "@/services/ConstantService";
import {GlobalService} from "@/services/GlobalService";
import {UrlService} from "@/services/UrlService";

export const EngineTypeService = {
  async getEngineType(params) {
    return await axios.get(ENGINE_TYPE + UrlService.setQueryString(params)).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET ENGINE TYPE LIST SERVICE')
      return false
    })
  },
  async createEngineType(params) {
    return await axios.post(ENGINE_TYPE,params).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'CREATE ENGINE TYPE SERVICE')
      return error?.response?.data
    })
  },

  async updateEngineType(params) {
    return await axios.put(ENGINE_TYPE + `/${params.id}`,params).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'UPDATE ENGINE TYPE SERVICE')
      return error?.response?.data ?? false
    })
  },

  async deleteEngineType(params) {
    return await axios.delete(ENGINE_TYPE + `/${params.id}`).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'DELETE ENGINE TYPE SERVICE')
      return false
    })
  },
}
