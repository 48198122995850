import {KeyService} from "@/services/KeyService";

const state={
  ailmentKeys:[],
}
const getters={
  ailmentKeys: (state)=>state.ailmentKeys
}

const actions={
  async getAilmentKeys({commit}){
    const response=await KeyService.getAilmentKeys();
    if(response){
      commit('SET_AILMENT_KEYS',response)
    }
    return false;
  },
}

const mutations={
  SET_AILMENT_KEYS:(state,params)=>{
    state.ailmentKeys=params;
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
