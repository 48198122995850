<template>
  <table class="w-100">
    <tr>
      <td colspan="2">
        <h6 class="font-weight-bold">Personnel</h6>
      </td>
    </tr>
    <tr>
      <td style="width: 30%">Name of Crew</td>
      <td>
        <input type="text" class="form-control form-control-sm" v-model="personnelDetails.crew_name">
      </td>
    </tr>
    <tr>
      <td>Personnel - Lost Time Injury</td>
      <td>
        <input type="text" class="form-control form-control-sm" v-model="personnelDetails.lost_time">
      </td>
    </tr>
    <tr>
      <td>Personnel - Restricted Work Case</td>
      <td>
        <input type="text" class="form-control form-control-sm" v-model="personnelDetails.restrict_work">
      </td>
    </tr>
    <tr>
      <td>Personnel - Medical Treatment Case</td>
      <td>
        <input type="text" class="form-control form-control-sm" v-model="personnelDetails.medical_treatment">
      </td>
    </tr>
    <tr>
      <td>Cost</td>
      <td>
        <input type="text" class="form-control form-control-sm" v-model="personnelDetails.cost">
      </td>
    </tr>
    <tr>
      <td>Cost Detail</td>
      <td>
        <textarea rows="5" class="form-control form-control-sm" v-model="personnelDetails.cost_detail"></textarea>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  name: 'Personnel',
  data () {
    return {
      personnelDetails: {
        accident_incident_report_id: '',
        crew_name: '',
        lost_time: '',
        restrict_work: '',
        medical_treatment: '',
        cost: '',
        cost_detail: ''
      }
    }
  }
}
</script>

<style scoped>
table td {
  padding: 2px;
}
</style>
