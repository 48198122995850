import { AuthenticationService } from '../../services/AuthenticationService'
import { USER_DETAILS } from '../../services/ConstantService'

const state = {
  user: false,
  sideNavHidden: false
}

const mutations = {
  setUser: (state, user) => {
    state.user = user
  },
  // eslint-disable-next-line no-return-assign
  toggleSideNavHidden: state => state.sideNavHidden = !state.sideNavHidden
}

const actions = {

  async authenticateLogin ({ commit }, credentials) {
    const user = await AuthenticationService.authenticate(credentials)
    if (user.status === 200) {
      commit('setUser', user.data)
      return true
    }
    return {status:user.status,data:user.data}
  },

  pullUserDetails ({ commit }) {
    commit('setUser', JSON.parse(localStorage.getItem(USER_DETAILS)))
  },

  async clearStorage ({ commit }) {
    await AuthenticationService.logout()
    commit('setUser', false)
  },

  toggleSideNavHidden ({ commit }) {
    commit('toggleSideNavHidden')
  }

}

const getters = {
  user: state => state.user,
  sideNavHidden: state => state.sideNavHidden
}

export default {
  state,
  getters,
  actions,
  mutations
}
