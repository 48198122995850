'use strict'

import _ from 'lodash';
export const DataService = {
  caseException: ['status', 'vmc_email'
    , 'email', 'fb_mail', 'route',
    'allowance', 'password', 'username',
    'confirmPassword', 'personal_email',
    'crewImage', 'vessel_image'],

  keyExceptions: ['attachments'],
  dataKeysChanges : [],
  capitalizeProperties(value) {
    try {
      Object.keys(value).forEach(key => {
        if (this.keyExceptions.includes(key) || !value[key]) {
          return value
        } else if (value[key] && typeof value[key] === 'string') {
          if (!this.caseException.includes(key)) {
            value[key] = (value[key]) ? String(value[key]).toUpperCase() : ''
          }
        } else {
          value[key] = this.capitalizeProperties(value[key])
        }

        // if(value[key] && typeof value[key] === 'string') {
        //   if(!this.caseException.includes(key)) {
        //     value[key] = (value[key]) ? String(value[key]).toUpperCase() : '';
        //   }
        // } else if(this.keyExceptions.includes(key)) {
        //   return value;
        // } else if(!value[key]) {
        //   return value;
        // } else {
        //   value[key] = this.capitalizeProperties(value[key]);
        // }
      })
    } catch (e) {
      return value
    }
    return value
  },
  computeAge(dateBuild,eventDate = null) {
    const birthday = new Date(dateBuild).getTime()
    const currentDate = eventDate ? new Date(eventDate).getTime() :  new Date()
    return ((currentDate - birthday) / 3.15576e+10).toFixed(1)
  },
  paramParser(params,paramFields){
    let paramArray = {};
    Object.keys(params).forEach(key=>{
      if(Array.isArray(paramFields)){
        if(paramFields.includes(key)){
          Object.assign(paramArray,{[key]:params[key]});
        }
      }
      else{
        if( (params[key] || params[key] === 0) ) {
          Object.assign(paramArray,{[key]:params[key]});
        }
      }
    })
    return paramArray;
  },
  checkIfChanged (originalData, editedData) {
    let keysChanged = [];

    for (let key in editedData) {
      if (Array.isArray(originalData[key]) && Array.isArray(editedData[key])) {
        let equalArrayValue = _.isEqual(originalData[key].sort(), editedData[key].sort());
        if (equalArrayValue === false) {
          keysChanged.push(key);
        }
      } else if (originalData[key] === undefined || originalData[key] !== editedData[key]) {
        keysChanged.push(key);
      }
    }
    this.dataKeysChanges = keysChanged;
    // uncomment only when debugging
    // console.log(keysChanged); // used for debugging, to know the keys of the data that has been changed in specific module
    return keysChanged.length > 0;
  },
  checkFileType(file) {
    const pdfMimeType = 'application/pdf';
    const excelMimeTypes = [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ];
    const wordMimeTypes = [
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];

    if (file.type === pdfMimeType) {
      return 'pdf';
    }

    if (excelMimeTypes.includes(file.type)) {
      return 'xlsx';
    }

    if (wordMimeTypes.includes(file.type)) {
      return 'docx';
    }
    return 'Unknown';
  },
  stripHtml(html) {
    var tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  },
  normalizeWhitespace(text) {
    return text.replace(/\s+/g, " ").trim();
  },
  getDataKeysChanges() {
    return this.dataKeysChanges;
  },
  pushOrRemoveInArray(arrayData, newValue) {
    if (arrayData.includes(newValue) === true) {
      arrayData = arrayData.filter(item => item !== newValue);
    } else {
      arrayData.push(newValue);
    }
    return arrayData;
  },
  pushOrRemoveInData(data, key, value) {
    if (data[key] === null) {
      data[key] = [];
    }
    data[key] = this.pushOrRemoveInArray(data[key], value);
    return data;
  },
  assignObjectKeysToOtherObject(objectData, keysToAssign) {
    let newObject = {};
    keysToAssign.forEach((key) => {
      if (Array.isArray(objectData[key]) === true) {
        let newArray = [];
        objectData[key].forEach((data) => {
          newArray.push(data);
        })
        newObject[key] = newArray;
      } else {
        newObject[key] = objectData[key];
      }

    });
    return newObject;
  },
  setValuesFromNewObject(objectData, newObjectValues) {
    for (let key in newObjectValues) {
      objectData[key] = newObjectValues[key];
    }
    return objectData;
  }
}

export default {DataService}
