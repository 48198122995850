import axios from 'axios'

import {
  KEYS_VESSEL,
  KEYS_EVENT_ONE,
  KEYS_COUNTRY,
  KEYS_TIMEZONE,
  KEYS_REPORT_STATUS,
  KEYS_MNGMT_OFFICE,
  KEYS_CREW,
  KEYS_USER,
  KEYS_EVENT_TWO,
  KEYS_EVENT_THREE,
  KEYS_EVENT_FOUR,
  KEYS_EVENT_FIVE,
  KEYS_MAIN_CAUSE,
  KEYS_SUB_CAUSE,
  KEYS_MNGMT_COMPANY,
  KEYS_FLEET,
  KEYS_VESSEL_TYPES,
  KEYS_HERROR_PERSONALS,
  KEYS_HERROR_MANNINGS,
  KEYS_HERROR_OTHERS,
  GET_CURRENCIES,
  KEYS_EVENT_IMPACT,
  CI_TYPE_KEYS,
  CMS_ONBOARD_CREW,
  RELATED_PARTY,
  KEY_MEDICAL_TYPE,
  KEY_MEDICAL_TREATMENT,
  KEY_MEDICAL_SEVERITY,
  KEY_AILMENT,
  KEY_AILMENT_PART,
  KEY_AILMENT_REASON,
  PERMISSION_KEYS,
  STATIC_PERMISSION_KEYS,
  GET_KEYS, PSC_INDEX, KEYS_MNGMT_OFFICE_VESSELS, KEYS_USER_RANKS,
  // KEY_AILMENT,
} from './ConstantService'
import { GlobalService } from './GlobalService'
import {UrlService} from "./UrlService";

export const KeyService = {
  async getRequestKeys(params) {
    return await GlobalService.ajax('post', GET_KEYS, params, {source: 'GET REQUEST KEYS'});

    // return await axios.get(GET_KEYS + '/' + uri).then(response => {
    //   return response.data ?? false
    // }).catch(error => {
    //   GlobalService.handleError(error, 'GET REQUEST KEYS SERVICE')
    //   return false
    // })
  },
  async getVesselKeys () {
    return await axios.get(KEYS_VESSEL).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET VESSEL KEYS SERVICE')
      return false
    })
  },
  async getTimezonKeys () {
    return await axios.get(KEYS_TIMEZONE).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET TIMEZONE KEYS SERVICE')
      return false
    })
  },
  async getEventOneKeys () {
    return await axios.get(KEYS_EVENT_ONE).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET EVENT ONE KEYS SERVICE')
      return false
    })
  },
  async getEventTwoKeys (params) {
    return await axios.post(KEYS_EVENT_TWO, params).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET EVENT TWO KEYS SERVICE')
      return false
    })
  },
  async getEventThreeKeys (params) {
    return await axios.post(KEYS_EVENT_THREE, params).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET EVENT THREE KEYS SERVICE')
      return false
    })
  },
  async getEventFourKeys (params) {
    return await axios.post(KEYS_EVENT_FOUR, params).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET EVENT FOUR KEYS SERVICE')
      return false
    })
  },
  async getEventFiveKeys (params) {
    return await axios.post(KEYS_EVENT_FIVE, params).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET EVENT FIVE KEYS SERVICE')
      return false
    })
  },
  async getMngmtOfficeKeys (params) {
    let queryString=UrlService.setQueryString(params)
    return await axios.get(KEYS_MNGMT_OFFICE + queryString).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET MANAGEMENT OFFICE KEYS SERVICE')
      return false
    })
  },
  async getMngmtOfficeVesselsKeys (params) {
    let queryString=UrlService.setQueryString(params)
    return await axios.get(KEYS_MNGMT_OFFICE_VESSELS).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET MANAGEMENT OFFICE KEYS SERVICE')
      return false
    })
  },
  async getCrewKeys (params) {
    return await axios.post(CMS_ONBOARD_CREW, params).then(response => {
      return response.data ?? false
    }).catch(error => {
      return error.response.data
    })
  },
  async getUserKeys () {
    return await axios.get(KEYS_USER).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET USER KEYS SERVICE')
      return false
    })
  },
  async getUserRanksKeys () {
    return await axios.get(KEYS_USER_RANKS).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET USER RANKS KEYS SERVICE')
      return false
    })
  },
  async getMainCauseKeys (params) {
    return await axios.post(KEYS_MAIN_CAUSE, params).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET MAIN CAUSE KEYS SERVICE')
      return false
    })
  },
  async getSubCauseKeys (params) {
    return await axios.post(KEYS_SUB_CAUSE, params).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET SUB CAUSE KEYS SERVICE')
      return false
    })
  },
  async getCountryKeys () {
    return await axios.get(KEYS_COUNTRY).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET COUNTRY KEYS SERVICE')
      return false
    })
  },
  async getReportStatusKeys (report_status_id = null) {
    report_status_id = report_status_id ? '/' + report_status_id : ''

    return await axios.get(KEYS_REPORT_STATUS + report_status_id).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET REPORT STATUS KEYS SERVICE')
      return false
    })
  },
  async getMngmtCompanyKeys () {
    return await axios.get(KEYS_MNGMT_COMPANY).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET MANAGEMENT COMPANY KEYS SERVICE')
      return false
    })
  },
  async getFleetKeys () {
    return await axios.get(KEYS_FLEET).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET FLEET KEYS SERVICE')
      return false
    })
  },
  async getVesselTypeKeys () {
    return await axios.get(KEYS_VESSEL_TYPES).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET VESSEL TYPE KEYS SERVICE')
      return false
    })
  },
  async getCauseErrorPersonalKeys () {
    return await axios.get(KEYS_HERROR_PERSONALS).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET ERROR PERSONAL KEYS SERVICE')
      return false
    })
  },
  async getCauseErrorManningKeys () {
    return await axios.get(KEYS_HERROR_MANNINGS).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CAUSE ERROR MANNING KEYS SERVICE')
      return false
    })
  },
  async getCauseErrorOtherKeys () {
    return await axios.get(KEYS_HERROR_OTHERS).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CAUSE ERROR OTHER KEYS SERVICE')
      return false
    })
  },
  async getEventClassImpactKeys () {
    return await axios.get(KEYS_EVENT_IMPACT).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET EVENT CLASS IMPACT KEYS SERVICE')
      return false
    })
  },
  async getRelatedParties () {
    return await axios.get(RELATED_PARTY).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'getRelatedParties SERVICE')
      return false
    })
  },
  async getCiTypeKeys () {
    return await axios.get(CI_TYPE_KEYS).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'getCompanyInstructionTypeKeys SERVICE')
      return false
    })
  },

  async getMedicalSeverityKeys () {
    return await axios.get(KEY_MEDICAL_SEVERITY).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'getMedicalSeverities SERVICE')
      return false
    })
  },

  async getMedicalTreatmentKeys () {
    return await axios.get(KEY_MEDICAL_TREATMENT).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'getMedicalTreatmentKeys SERVICE')
      return false
    })
  },

  async getMedicalTypeKeys () {
    return await axios.get(KEY_MEDICAL_TYPE).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'getMedicalTypeKeys SERVICE')
      return false
    })
  },

  async getAilmentKeys () {
    return await axios.get(KEY_AILMENT).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'getAilmentKeys SERVICE')
      return false
    })
  },

  async getAilmentPartKeys () {
    return await axios.get(KEY_AILMENT_PART).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'getAilmentPartKeys SERVICE')
      return false
    })
  },

  async getAilmentReasonKeys () {
    return await axios.get(KEY_AILMENT_REASON).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'getAilmentReasonKeys SERVICE')
      return false
    })
  },

  async getUserPermissionKeys () {
    return await axios.get(PERMISSION_KEYS).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'getUserPermissionKeys SERVICE')
      return false
    })
  },

  async getUserStaticPermissionKeys () {
    return await axios.get(STATIC_PERMISSION_KEYS).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'getUserStaticPermissionKeys SERVICE')
      return false
    })
  },
  // async getCurrencyKeys () {
  //   return await axios.get(KEYS_GET_CURRENCIES).then(response => {
  //     console.log(response, 'SET_CURRENCIES')
  //     return response.data ?? false
  //   }).catch(error => {
  //     GlobalService.handleError(error, 'getCurrencies SERVICE')
  //     return false
  //   })
  // },

  // async getCurrencyKeys() {
  //   return await axios.get(KEYS_GET_CURRENCIES)
  //     .then(r => {
  //       console.log(r.data, 'currency service')
  //       return r.data ?? false
  //       // return r.data ? r.data : false;
  //     }).catch(error => {
  //       GlobalService.handleError(error, 'getCurrencies SERVICE');
  //       return false;
  //     });
  // },

}

export default { KeyService }
