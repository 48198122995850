import {KeyService} from "@/services/KeyService";

const state={
  ailmentPartKeys:[],
}
const getters={
  ailmentPartKeys: (state)=>state.ailmentPartKeys
}

const actions={
  async getAilmentPartKeys({commit}){
    const response=await KeyService.getAilmentPartKeys();
    if(response){
      commit('SET_AILMENT_PART_KEYS',response)
    }
    return false;
  },
}

const mutations={
  SET_AILMENT_PART_KEYS:(state,params)=>{
    state.ailmentPartKeys=params;
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
