import {GlobalService} from "@/services/GlobalService";
import {EngineDgMakerService} from "@/services/EngineDgMakerService";

const state={
  engineDgMaker:[],
}

const mutations={
  SET_ENGINE_DG_MAKER : (state,payload)=>state.engineDgMaker=payload,
  ADD_ENGINE_DG_MAKER : (state,payload)=>{
    if(state.engineDgMaker.length > 15) state.engineDgMaker.pop()

    state.engineDgMaker.push(payload)

    state.engineDgMaker.sort((a,b)=> GlobalService.sortFunc(a,b));
  },

  UPDATE_ENGINE_DG_MAKER : (state,payload) => {
    let index= state.engineDgMaker.findIndex(maker=> maker.id===payload.id);

    state.engineDgMaker.splice(index,1,payload);

    state.engineDgMaker.sort((a,b)=> GlobalService.sortFunc(a,b));
  }
}

const actions={
  async getEngineDgMakers({commit},params){
    const response= await EngineDgMakerService.getEngineDgMakers(params)
    const isGet= params?.get ?? false
    if(response && !isGet) {
      commit('setPagination', response)
      commit('SET_ENGINE_DG_MAKER', response.data)
    }
    else {
      commit('SET_ENGINE_DG_MAKER', response)
    }
    return response;
  },

  async createEngineDgMaker({commit},params){
    const response= await EngineDgMakerService.createEngineDgMaker(params)
    if(!response.errors){
      commit ('ADD_ENGINE_DG_MAKER',response)
    }
    return response;
  },

  async updateEngineDgMaker({commit},params){
    const response= await EngineDgMakerService.updateEngineDgMaker(params)
    if(!response.errors){
      commit ('UPDATE_ENGINE_DG_MAKER',response)
    }
    return response;
  },

  async deleteEngineDgMaker({commit},params){
    return await EngineDgMakerService.deleteEngineDgMaker(params);
  }
}

const getters={
  engineDgMaker:(state)=>GlobalService.capitalizeProperties(state.engineDgMaker)
}


export default {state,actions,mutations,getters}
