<script>
import {vueAppMixin} from "@/mixins/vueAppMixin";
import FileTree from "@/components/vessel/documents/components/FileTree.vue";
import ImageSlider from "@/components/common/ImageSlider.vue";
import ImageSliderMixin from "@/mixins/ImageSliderMixin";
import BaseModal from "@/components/common/BaseModal.vue";
import {VesselService} from "@/services/VesselService";
import AppLoading from "@/components/elements/AppLoading.vue";
import {
  API_URL,
  TOKEN_NAME,
  UPLOAD_VESSEL_FILE,
  UPLOAD_VESSEL_VISITATION, VESSEL_DOWNLOAD_ALL_FILE,
  VESSEL_VISITATION
} from "@/services/ConstantService";
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'
import vueFilePond from 'vue-filepond'

// Import styles
import 'filepond/dist/filepond.min.css';

import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import {AlertService} from "@/services/AlertService";
import Table from "@/components/layouts/Table.vue";
import axios from "axios";

// Create FilePond component
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);


export default {
  components: {Table, AppLoading, FileTree, BaseModal, FilePond, },
  data() {
    return {
      deleteModal: false,
      password: null,
      idToDelete: null,
      files: [],
      name: null,
      uploadModal: false,
      isUploadSuccess: false,
      serverOptions: {
        process: {
          url: API_URL + UPLOAD_VESSEL_FILE.replace('{vessel_id}', this.$route.params.id),
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('kidsToken'),
          },
          ondata: (formData) => {
            formData.append('tag', 'file');
            return formData;
          }
        }
      }
    }
  },
  methods: {
    closeDeleteModal(){
      this.deleteModal = false
      this.password = null
    },
    handleCloseUploadModal(){
      this.uploadModal = false
      this.isUploadSuccess = false
    },
    async handleUploadedFile(error, file){
      this.files = await VesselService.getFiles(this.$route.params.id)
    },
    handleUploadSuccess(){
      this.isUploadSuccess = true
      this.$refs.success.togglePlayPause()
    },
    handleUpload(){
      this.$refs.pond.processFiles()
    },
    deleteFileById(id)
    {
      this.deleteModal = true
      this.idToDelete = id
    },
    async handleDelete(){
      try {
        await VesselService.deleteFile(
          {
            vessel_id: this.$route.params.id,
            file_ids: new Array(1).fill(this.idToDelete),
            password: this.password
          }
        )
        this.deleteModal = false
        this.files = await VesselService.getFiles(this.$route.params.id)
        AlertService.successAlert('File Deleted Successfully', 'FILE DELETION')
        this.selected = []
        this.password = null
      } catch (e) {
        AlertService.errorAlert(e.message, 'FILE DELETION')
      }

    },
    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', title)
      document.body.appendChild(link)
      link.click()
    },
    download(url, name) {
      axios({
        method: 'get',
        url,
        responseType: 'arraybuffer',
      })
        .then((response) => {
          this.forceFileDownload(response, name)
        })
        .catch(() => console.log('error occured'))
    },
    downloadAll(){
      const url = VESSEL_DOWNLOAD_ALL_FILE.replace('{vessel_id}', this.$route.params.id)

      axios({
        method: 'get',
        url,
        responseType: 'blob',
      })
        .then((response) => {
          const today = new Date().toISOString().slice(0, 10)
          this.forceFileDownload(response, `Cape_Zenith-${today}.zip`)
        })
        .catch(() => console.log('error occured'))
    }
  },
  async created(){
    this.files = await VesselService.getFiles(this.$route.params.id)
  },
}
</script>

<template>
  <div>
    <div style="display: flex; gap: 10px;">
      <button class="e-btn e-btn-blue force-white-all pr-3" @click="uploadModal = true">
        <font-awesome-icon icon="upload" style="width: 25px" />
        <small class="font-weight-bold">
          UPLOAD FILE
        </small>
      </button>
      <button class="e-btn e-btn-blue force-white-all pr-3" @click="downloadAll" v-if="this.files.length > 0">
        <font-awesome-icon icon="download" style="width: 25px" />
        <small class="font-weight-bold">
          DOWNLOAD ALL FILES
        </small>
      </button>
    </div>


    <div class="mt-2">
      <table class="table">
        <thead>
        <tr>
          <th>File name</th>
          <th>File size</th>
          <th>Date Uploaded</th>
          <th>Uploaded by</th>
          <th  class="text-center">Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="files.length  === 0">
          <td colspan="100%" class="text-center" style="justify-content: center; align-items: center; padding: 25px; background-color: #f7f7f7; width: 100%">
            No File
          </td>
        </tr>

        <tr v-for="file in files" v-else>
          <th scope="row" class="align-middle">
            <img :src="file.thumbnail" class="mr-2" style="width: 30px" alt="">
            {{ file.name }}
          </th>
          <td class="align-middle">{{ file.file_size }}</td>
          <td class="align-middle">{{ file.uploaded_at }}</td>
          <td class="align-middle">{{ file.uploaded_by.first_name }} {{ file.uploaded_by.last_name  }}</td>
          <td class="text-center align-middle">
            <button class="btn" @click="download(file.url, file.name)"><font-awesome-icon icon="download" /></button>
            <button   class="btn" @click="deleteFileById(file.id)"><font-awesome-icon icon="trash" class="e-text-red"/></button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>


    <BaseModal :isVisible="uploadModal" @close="uploadModal = false" :close-button="false">
      <template v-slot:title>Upload file</template>
      <template v-slot:body>
        <template v-if="!isUploadSuccess">
          <file-pond id="test" name="file" ref="pond" allow-multiple="true" max-files="5" instantUpload="false" :server="serverOptions"
                     :allow-reorder="true"
                     allow-revert="false"
                     :accepted-file-types="[
                       'application/vnd.ms-excel',
                       'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                       'text/csv',
                       'application/msword',
                       'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                       'application/pdf'
                     ]"
                     :max-file-size="5000000"
                     allow-process="false"
                     @processfile="handleUploadedFile"
                     @processfiles="handleUploadSuccess"
          />
          <span class="text-danger">*</span>max file 5
        </template>
        <div v-show="isUploadSuccess">
          <lottie-vue-player
            ref="success"
            class="w-full"
            :src="`https://lottie.host/39967dbf-5dd8-47fc-a32f-f9e84dabbe7b/2WFUeDHfZU.json`"
            :autoplay="false"
            :loop="false"
            style="height: 190px"
          ></lottie-vue-player>
          <h3 class="text-success text-center">Uploaded Successfully</h3>
        </div>

      </template>
      <template v-slot:footer>
        <template v-if="!isUploadSuccess">
          <button type="button" class="btn btn-sm btn-secondary" @click="handleCloseUploadModal">Cancel</button>
          <button type="button" class="btn btn-sm btn-primary" @click="handleUpload">Upload</button>
        </template>
        <template v-else>
          <button type="button" class="btn btn-sm btn-primary" @click="handleCloseUploadModal">Done</button>
        </template>
      </template>
    </BaseModal>


    <BaseModal :isVisible="deleteModal" @close="deleteModal = false" :close-button="false">
      <template v-slot:title>Delete file</template>
      <template v-slot:body>
        <p style="font-size: 13px;">This action <b style="color:red">CANNOT BE UNDONE</b>. This will permanently delete the file(s).</p>
        <span style="font-size: 12px"><b>Please type your password to confirm.</b></span>
        <form @submit.prevent="handleDelete">
          <input type="password" v-model="password" class="form-control form-control-sm" placeholder="Enter Password" autocomplete="off">
        </form>
      </template>
      <template v-slot:footer>
        <button type="button" class="btn btn-sm btn-secondary" @click="closeDeleteModal">Cancel</button>
        <button type="button" class="btn btn-sm btn-primary" @click="handleDelete">Confirm</button>
      </template>
    </BaseModal>
  </div>
</template>

<style scoped>

</style>
