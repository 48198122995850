<template>
  <div v-if="spinnerOnly === false" class="text-center" style="background: white">
    <div class="spinner-border text-primary my-3" :style="{width: width, height: height}" role="status">
      <span class="sr-only">Loading...</span>
    </div>

    <h5 class="font-weight-bolder">Loading Please Wait . . . . .</h5>
  </div>

  <div v-else class="spinner-border text-primary my-1" :style="{width: width, height: height}" role="status">
  </div>
</template>

<script>
export default {
  name: "AppLoading",
  props: {
    spinnerOnly : {
      type: Boolean,
      default: false,
    },
    width: {
      type : String,
      default: '3rem'
    },
    height : {
      type : String,
      default: '3rem'
    }
  }
}
</script>

<style scoped>

</style>
