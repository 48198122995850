'use strict'

import RouteInternalAuditMixin from "@/mixins/RouteInternalAuditMixin";
import store from "@/store";
import Swal from "sweetalert2";
import RouteCompanyInternalAuditMixin from "@/mixins/RouteCompanyInternalAuditMixin";
const VesselAuditMain = () => import('@/components/modules/ia/vessel/VesselAuditMain.vue')
const InternalAudit = () => import('../views/InternalAudit.vue')
const AuditorList = () => import('../components/modules/ia/auditor/AuditorList.vue')
const CompanyList = () => import( "@/components/modules/ia/company/CompanyList.vue");
const VesselAudit = () => import('../views/VesselAudit.vue')
const CompanyAudit = () => import('../views/CompanyAudit.vue')
const VesselList = () => import( "@/components/modules/ia/vessel/VesselList.vue");
const VPIMain = () => import( "@/components/modules/ia/vpi/VPIMain");
const VPIIntroduction = () => import( "@/components/modules/ia/vpi/SubComponents/VPIIntroduction.vue");
const VPIGraph = () => import( "@/components/modules/ia/vpi/SubComponents/VPIGraph.vue");
const VPI = () => import( "@/components/modules/ia/vpi/SubComponents/VPI.vue");
const VesselAuditDue = () => import( "@/components/modules/ia/vessel/VesselAuditDue.vue");
const AuditorProfile = () => import('@/components/modules/ia/auditor/AuditorProfile.vue')
const InternalAuditReport = () => import('@/components/modules/ia/vessel/InternalAuditReport.vue')
const NonConformity = () => import('@/components/modules/ia/vessel/NonConformity.vue')

const NcrMainDetails = () => import('@/components/modules/ia/vessel/nonConformity/details/NcrMainDetails')
const ObjectiveEvidenceDetail = () => import('@/components/modules/ia/vessel/nonConformity/details/ObjectiveEvidenceDetail')

const OEMainDetails = () => import('@/components/modules/ia/vessel/nonConformity/details/ObjectiveEvidenceDetail/OESubComponents/OEMainDetails');
const OEAttachments = () => import('@/components/modules/ia/vessel/nonConformity/details/ObjectiveEvidenceDetail/OESubComponents/OEAttachments');
const IaCompanyInstructions = () => import('@/components/modules/ia/vessel/nonConformity/details/ObjectiveEvidenceDetail/OESubComponents/IaCompanyInstructions');


const ObjectiveEvidenceList = () => import('@/components/modules/ia/vessel/ObjectiveEvidenceList.vue');
const IAShipParticular = () => import('@/components/modules/ia/vessel/IAShipParticular.vue');
const IaCorrectiveActionReport = () => import('@/components/modules/ia/vessel/IaCorrectiveActionReport.vue')


// COMPANY AUDIT COMPONENTS

const CompanyAuditMain = () => import('@/components/modules/ia/company/CompanyAuditMain.vue')
const CompanyObjectiveEvidenceList = () => import('@/components/modules/ia/company/CompanyObjectiveEvidenceList');
const CompanyAuditDue = () => import('@/components/modules/ia/company/CompanyAuditDue');
const CompanyInternalAuditReport = () => import('@/components/modules/ia/company/CompanyInternalAuditReport.vue');
const CompanyNonConformity = () => import('@/components/modules/ia/company/CompanyNonConformity.vue')
const CompanyNcrMainDetails = () => import('@/components/modules/ia/company/nonConformity/details/CompanyNcrMainDetails')
const CompanyObjectiveEvidenceDetail = () => import('@/components/modules/ia/company/nonConformity/details/CompanyObjectiveEvidenceDetail')
const CompanyOEMainDetails = () => import('@/components/modules/ia/company/nonConformity/details/ObjectiveEvidenceDetail/OESubComponents/CompanyOEMainDetails');
const CompanyOEAttachments = () => import('@/components/modules/ia/company/nonConformity/details/ObjectiveEvidenceDetail/OESubComponents/CompanyOEAttachments');
const CompanyIaCompanyInstructions = () => import('@/components/modules/ia/company/nonConformity/details/ObjectiveEvidenceDetail/OESubComponents/CompanyIaCompanyInstructions');
const CompanyIaCorrectiveActionReport = () => import('@/components/modules/ia/company/CompanyIaCorrectiveActionReport')
const CompanyIAShipParticular = () => import('@/components/modules/ia/company/CompanyIAShipParticular');

export const RouteInternalAudit = {
  path: 'ia/',
  name: 'InternalAudit',
  redirect: 'ia/auditor',
  component: InternalAudit,
  children: [
    {
      path: 'auditor/',
      name: 'AuditorList',
      component: AuditorList,
    },
    {
      path : 'auditor/:id',
      name : 'AuditorProfile',
      component: ()=> import('@/components/modules/ia/auditor/AuditorProfile.vue'),
    },
    {
      path: 'vpi-status/',
      name: 'VPIMain',
      component: VPIMain,
      children: [
        {
          path: 'introduction',
          name: 'VPIIntroduction',
          component: VPIIntroduction,
        },
        {
          path: 'graph',
          name: 'VPIGraph',
          component: VPIGraph,
        },
        {
          path: 'vpi',
          name: 'VPI',
          component: VPI,
        },
      ]
    },
    {
      path : 'vessel-audit/',
      name : 'VesselAudit',
      component : VesselAudit,
      children: [
        {
          path: 'vessels/',
          name: 'VesselList',
          component: VesselList,
        },
        {
          path: 'objective-evidence/list',
          name: 'ObjectiveEvidenceList',
          component: ObjectiveEvidenceList
        },
        {
          path: 'audit-due/',
          name: 'VesselAuditDue',
          component: VesselAuditDue,
        },
        {
          path : 'vessels',
          name : 'VesselAuditRecordMain',
          component: ()=> import('@/components/modules/ia/vessel/VesselAuditRecordMain.vue'),
          children: [
            {
              path : ':vessel_id/audit',
              name : 'VesselAuditRecordList',
              component: ()=> import('@/components/modules/ia/vessel/VesselAuditRecordList.vue'),
            },
            {
              path: 'audit-due/:vessel_id',
              name: 'VesselAuditDueByVesselId',
              component: VesselAuditDue
            }
          ]
        },
        {
          path: 'vessels/:vessel_id/audit/:id/:type',
          name: 'AuditDetails',
          beforeEnter:  async (to, from, next) => {
            let pleaseWait = Swal.fire({
              title: 'Loading...',
              html: 'Please wait...',
              allowEscapeKey: false,
              allowOutsideClick: false,
              didOpen: () => {
                Swal.showLoading()
              }
            });


            //   // Note: all validation if false will redirect to route named VesselList
            await RouteInternalAuditMixin.methods.initialize(to.params);
            const validAuditType = await RouteInternalAuditMixin.methods.validateSelectedAuditType();
            if (validAuditType === false) {
              return RouteInternalAuditMixin.methods.redirectToVesselList(next);
            }
            await RouteInternalAuditMixin.methods.initializeNcr(to.name);
            const validVesselId = await RouteInternalAuditMixin.methods.validateVesselById();
            if (validVesselId === false) {
              return RouteInternalAuditMixin.methods.redirectToVesselList(next);
            }

            const validAuditReportId = await RouteInternalAuditMixin.methods.validateAuditReportById();
            if (validAuditReportId === false) {
              return RouteInternalAuditMixin.methods.redirectToVesselList(next);
            }

            const validNcrId = await RouteInternalAuditMixin.methods.validateNonConformityById();
            if (validNcrId === false) {
              return RouteInternalAuditMixin.methods.redirectToVesselList(next);
            }

            const validOeId = await RouteInternalAuditMixin.methods.validateObjectiveEvidenceById();
            if (validOeId === false) {
              return RouteInternalAuditMixin.methods.redirectToVesselList(next);
            }

            pleaseWait.close();
            next();
          },
          component: VesselAuditMain,
          children: [
            {
              path: 'internal-audit-report',
              name: 'internalAuditReport',
              component: InternalAuditReport
            },
            {
              path: 'non-conformity/:ncr_id/:ncr_type',
              name: 'NonConformity',
              component: NonConformity,
              children : [
                {
                  path : 'ncr-details',
                  name : 'NcrMainDetails',
                  component : NcrMainDetails,
                },
                {
                  path : 'objective-evidence/:oe_id',
                  name : 'ObjectiveEvidenceDetail',
                  component : ObjectiveEvidenceDetail,
                  children : [
                    {
                      path : 'oe-details',
                      name : 'OEMainDetails',
                      component : OEMainDetails
                    },
                    {
                      path : 'oe-attachments',
                      name : 'OEAttachments',
                      component : OEAttachments
                    },
                    {
                      path : 'company-instruction',
                      name : 'IaCompanyInstructions',
                      component : IaCompanyInstructions
                    }
                  ]
                }
              ]
            },
            {
              path: 'corrective-action',
              name: 'IaCorrectiveActionReport',
              component: IaCorrectiveActionReport
            },
            {
              path: 'ship-particular',
              name: 'IAShipParticular',
              component: IAShipParticular
            }
          ]
        },

      ]
    },
    {
      path : 'company-audit/',
      name : 'CompanyAudit',
      component : CompanyAudit,
      children: [
        {
          path: 'companies/',
          name: 'CompanyList',
          component: CompanyList,
        },
        {
          path: 'objective-evidence/list',
          name: 'CompanyObjectiveEvidenceList',
          component: CompanyObjectiveEvidenceList
        },
        {
          path: 'audit-due/',
          name: 'CompanyAuditDue',
          component: CompanyAuditDue,
        },
        {
          path: 'companies',
          name: 'CompanyAuditRecordMain',
          component: ()=> import('@/components/modules/ia/company/CompanyAuditRecordMain.vue'),
          children: [
            {
              path : ':company_id/audit',
              name : 'CompanyAuditRecordList',
              component: ()=> import('@/components/modules/ia/company/CompanyAuditRecordList.vue'),
            },
            {
              path: 'audit-due/:company_id',
              name: 'CompanyAuditDueByCompanyId',
              component: CompanyAuditDue
            }
          ]
        },
        {
          path: 'companies/:company_id/audit/:id/:type',
          name: 'CompanyAuditDetails',
          beforeEnter:  async (to, from, next) => {
            let pleaseWait = Swal.fire({
              title: 'Loading...',
              html: 'Please wait...',
              allowEscapeKey: false,
              allowOutsideClick: false,
              didOpen: () => {
                Swal.showLoading()
              }
            });

            //   // Note: all validation if false will redirect to route named CompanyList
            await RouteCompanyInternalAuditMixin.methods.initialize(to.params);
            const validAuditType = await RouteCompanyInternalAuditMixin.methods.validateSelectedAuditType();
            if (validAuditType === false) {
              return RouteCompanyInternalAuditMixin.methods.redirectToCompanyList(next);
            }
            await RouteCompanyInternalAuditMixin.methods.initializeNcr(to.name);
            const validCompanyId = await RouteCompanyInternalAuditMixin.methods.validateCompanyById();
            if (validCompanyId === false) {
              return RouteCompanyInternalAuditMixin.methods.redirectToCompanyList(next);
            }

            const validAuditReportId = await RouteCompanyInternalAuditMixin.methods.validateAuditReportById();
            if (validAuditReportId === false) {
              return RouteCompanyInternalAuditMixin.methods.redirectToCompanyList(next);
            }
            const validNcrId = await RouteCompanyInternalAuditMixin.methods.validateNonConformityById();
            if (validNcrId === false) {
              return RouteCompanyInternalAuditMixin.methods.redirectToCompanyList(next);
            }

            const validOeId = await RouteCompanyInternalAuditMixin.methods.validateObjectiveEvidenceById();
            if (validOeId === false) {
              return RouteCompanyInternalAuditMixin.methods.redirectToCompanyList(next);
            }
            pleaseWait.close();
            next();
          },
          component: CompanyAuditMain,
          children: [
            {
              path: 'internal-audit-report',
              name: 'CompanyInternalAuditReport',
              component: CompanyInternalAuditReport
            },
            {
              path: 'non-conformity/:ncr_id/:ncr_type',
              name: 'CompanyNonConformity',
              component: CompanyNonConformity,
              children : [
                {
                  path : 'ncr-details',
                  name : 'CompanyNcrMainDetails',
                  component : CompanyNcrMainDetails,
                },
                {
                  path : 'objective-evidence/:oe_id',
                  name : 'CompanyObjectiveEvidenceDetail',
                  component : CompanyObjectiveEvidenceDetail,
                  children : [
                    {
                      path : 'oe-details',
                      name : 'CompanyOEMainDetails',
                      component : CompanyOEMainDetails
                    },
                    {
                      path : 'oe-attachments',
                      name : 'CompanyOEAttachments',
                      component : CompanyOEAttachments
                    },
                    {
                      path : 'company-instruction',
                      name : 'CompanyIaCompanyInstructions',
                      component : CompanyIaCompanyInstructions
                    }
                  ]
                }
              ]
            },
            {
              path: 'corrective-action',
              name: 'CompanyIaCorrectiveActionReport',
              component: CompanyIaCorrectiveActionReport
            },
            {
              path: 'ship-particular',
              name: 'CompanyIAShipParticular',
              component: CompanyIAShipParticular
            }
          ]
        },
      ]
    },
  ]
}
