import {PSC_REPORT_INDEX} from "@/services/ConstantService";
import {GlobalService} from "@/services/GlobalService";
import {UrlService} from "@/services/UrlService";

const state = {
  committeeReport: [],
  inspectionByCountry: [],
  inspectionByYear: [],
  inspectionBySpan: [],
  inspectionMonthlySummary: [],
  inspectionDeficiencyCodeGroup: [],
  pscReportStatusCount: [],
  pscBounties: [],
  pscVesselPerformance: []
}

const mutations = {
  SET_COMMITTEE_REPORT: (state, payload) => state.committeeReport = payload,
  SET_INSPECTION_REPORT_BY_COUNTRY: (state, payload) => state.inspectionByCountry = payload,
  SET_INSPECTION_REPORT_BY_YEAR: (state, payload) => state.inspectionByYear = payload,
  SET_INSPECTION_REPORT_BY_SPAN: (state, payload) => state.inspectionBySpan = payload,
  SET_INSPECTION_MONTHLY_SUMMARY: (state, payload) => state.inspectionMonthlySummary = payload,
  SET_INSPECTION_DEFICIENCY_CODE_GROUP: (state, payload) => state.inspectionDeficiencyCodeGroup = payload,
  SET_PSC_REPORT_STATUS_COUNT : (state,payload)=>state.pscReportStatusCount=payload,
  SET_PSC_BOUNTY : (state,payload)=>state.pscBounties=payload,
  SET_VESSEL_PERFORMANCE: (state, payload) => state.pscVesselPerformance = payload
}

const actions = {
  async getInspectionDeficiencyCodeGroup({commit}, params) {
    const uri = [PSC_REPORT_INDEX, 'deficiency-code-group'].join('/')
    const queryString = UrlService.setQueryString(params);
    const response = await GlobalService.ajax('get', uri + queryString, {});
    if (response.status == 200) {
      commit('SET_INSPECTION_DEFICIENCY_CODE_GROUP', response.data)
      return true;
    }
    return false;
  },
  async getInspectionMonthlySummary({commit}, params) {
    const uri = [PSC_REPORT_INDEX, 'monthly-summary'].join('/')
    const queryString = UrlService.setQueryString(params);
    const response = await GlobalService.ajax('get', uri + queryString, {});
    if (response.status == 200) {
      commit('SET_INSPECTION_MONTHLY_SUMMARY', response.data)
      return true;
    }
    return false;
  },

  async getCommitteeReports({commit}, params) {
    const uri = [PSC_REPORT_INDEX, 'committee'].join('/')
    const queryString = UrlService.setQueryString(params);
    const response = await GlobalService.ajax('get', uri + queryString, {});

    if (response.status == 200) {
      commit('SET_COMMITTEE_REPORT', response.data.data)
      commit('setPagination', response.data)
      return true;
    }
    return false;
  },

  async getInspectionReportByCountry({commit}, params) {
    const uri = [PSC_REPORT_INDEX, 'deficiency-rate-country'].join('/')
    const queryString = UrlService.setQueryString(params);
    const response = await GlobalService.ajax('get', uri + queryString, {});
    if (response.status == 200) {
      commit('SET_INSPECTION_REPORT_BY_COUNTRY', response.data)
      return true;
    }
    return false;
  },

  async getVesselPerformance({commit}, params) {
    const uri = [PSC_REPORT_INDEX, 'vessel-performance'].join('/')
    const queryString = UrlService.setQueryString(params);
    const response = await GlobalService.ajax('get', uri + queryString, {});
    if (response.status == 200) {
      commit('SET_VESSEL_PERFORMANCE', response.data)
      return true;
    }
    return false;
  },

  async getInspectionReportByMou({commit}, params) {
    const uri = [PSC_REPORT_INDEX, 'inspection-by-mou'].join('/')
    const queryString = UrlService.setQueryString(params);
    console.log(queryString);
    const response = await GlobalService.ajax('get', uri + queryString, {});
    if (response.status == 200) {
      commit('SET_INSPECTION_REPORT_BY_COUNTRY', response.data)
      return true;
    }
    return false;
  },

  async getInspectionReportByYear({commit}, params) {
    const uri = [PSC_REPORT_INDEX, 'deficiency-rate'].join('/')
    const queryString = UrlService.setQueryString(params);
    const response = await GlobalService.ajax('get', uri + queryString, {});

    if (response.status == 200) {
      commit('SET_INSPECTION_REPORT_BY_YEAR', response.data)
      return true;
    }
    return false;
  },

  async getInspectionReportBySpan({commit}, params) {
    const uri = [PSC_REPORT_INDEX, 'deficiency-rate'].join('/')
    const queryString = UrlService.setQueryString(params);
    const response = await GlobalService.ajax('get', uri + queryString, {});
    if (response.status == 200) {
      commit('SET_INSPECTION_REPORT_BY_SPAN', response.data)
      return true;
    }
    return false;
  },

  async getPscReportStatusCount({commit}, params) {
    const uri = [PSC_REPORT_INDEX, 'report-status'].join('/')
    const queryString = UrlService.setQueryString(params);
    const response = await GlobalService.ajax('get', uri + queryString, {});
    if (response.status == 200) {
      commit('SET_PSC_REPORT_STATUS_COUNT', response.data)
      return true;
    }
    return false;
  },

  async getPscBounties({commit}, params) {
    const uri = [PSC_REPORT_INDEX, 'bounty'].join('/')
    const queryString = UrlService.setQueryString(params);
    const response = await GlobalService.ajax('get', uri + queryString, {});
    if (response.status == 200) {
      commit('SET_PSC_BOUNTY', response.data)
      return true;
    }
    return false;
  },
}

const getters = {
  committeeReport: (state) => state.committeeReport,
  inspectionByCountry: (state) => state.inspectionByCountry,
  inspectionByYear: (state) => state.inspectionByYear,
  inspectionBySpan: (state) => state.inspectionBySpan,
  inspectionMonthlySummary: (state) => state.inspectionMonthlySummary,
  inspectionDeficiencyCodeGroup: (state) => state.inspectionDeficiencyCodeGroup,
  pscReportStatusCount: (state) => state.pscReportStatusCount,
  pscBounties: (state)=>state.pscBounties,
  pscVesselPerformance: (state) => state.pscVesselPerformance
}

export default {getters, actions, mutations, state}
