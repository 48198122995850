/** state **/
import { KeyService } from '@/services/KeyService'

const state = {
  fleetKeys: []
}

/** mutations **/
const mutations = {
  // eslint-disable-next-line no-return-assign
  setFleetKeys: (state, fleetKeys) => state.fleetKeys = fleetKeys
}

/** actions **/
const actions = {
  async getFleetListKeys ({ commit }) {
    const fleetKeys = await KeyService.getFleetKeys()
    if (fleetKeys) commit('setFleetKeys', fleetKeys)
  }
}

/** getters **/
const getters = {
  fleetKeys: state => state.fleetKeys
}

/** export **/
export default { state, actions, mutations, getters }
