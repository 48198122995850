import {KeyService} from "@/services/KeyService";

const state={
  medicalSeverityKeys:[],
}
const getters={
  medicalSeverityKeys: (state)=>state.medicalSeverityKeys
}

const actions={
  async getMedicalSeverityKeys({commit}){
    const response=await KeyService.getMedicalSeverityKeys();
    if(response){
      commit('SET_MEDICAL_SEVERITY_KEYS',response)
    }
    return false;
  },
}

const mutations={
  SET_MEDICAL_SEVERITY_KEYS:(state,params)=>{
    state.medicalSeverityKeys=params;
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
