import {GlobalService} from "../../services/GlobalService";
import {PSC_ACTION_CODES, PSC_CODES, PSC_INDEX, PSC_USCG_CODES} from "../../services/ConstantService";
import axios from "axios";
import {UrlService} from "@/services/UrlService";

const state = {
  pscKeys: [],
  pscReport: {},
  pscAttachments: [],
  pscCodeKeys: [],
  pscActionCodeKeys: [],
  preparationAttachments:[],
  preparationAttachmentsToAdd : [],
  preparationAttachmentsToDelete : [],
  verificationAttachments:[],
  verificationAttachmentsToAdd : [],
  verificationAttachmentsToDelete : [],
  beforeRecAttachments:[],
  afterRecAttachments:[],
  occasionalSurveyAttachments : [],
  befRecAttachmentsToAdd : [],
  befRecAttachmentsToDelete : [],
  afRecAttachmentsToAdd : [],
  afRecAttachmentsToDelete : [],
  deficiency:{},
  toEdit:false,
  hasChanges : false,
  uscgCodes:[],
  mouKeys: [],
}

const mutations = {
  SET_PSC_KEYS: (state, pscKeys) => state.pscKeys = pscKeys,
  SET_PSC_REPORT: (state, pscReport) => state.pscReport = pscReport,
  SET_PSC_CODE_KEYS: (state, pscCodeKeys) => state.pscCodeKeys = pscCodeKeys,
  SET_PSC_ACTION_CODE_KEYS: (state, actionCodeKeys) => state.pscActionCodeKeys = actionCodeKeys,
  SET_TO_EDIT:(state,editStatus)=>state.toEdit=editStatus,
  SET_HAS_CHANGES:(state,hasChangesStatus)=>state.hasChanges=hasChangesStatus,
  SET_USCG_CODES:(state,uscgCodes)=>state.uscgCodes=uscgCodes,
  SET_PSC_ATTACHMENTS: (state, response) => {
    switch (response.type_id){
      case 2:
        state.preparationAttachments = response?.attachments ?? []
        break;
      case 3:
        state.verificationAttachments = response?.attachments ?? []
        break;
      case 4:
        state.beforeRecAttachments = response?.attachments ?? []
        break;
      case 5:
        state.afterRecAttachments = response?.attachments ?? []
        break;
      case 6:
        state.occasionalSurveyAttachments = response?.attachments ?? []
        break;
      default:
        state.pscAttachments = response?.attachments ?? []
        break;
    }
  },
  EMPTY_OCCASIONAL_SURVEY_ATTACHMENTS : (state) => {
    state.occasionalSurveyAttachments = [];
  },
  /**
   * COMMITS FOR PREPARATIONS ATTACHMENTS
   */
  ADD_PREPARATION_ATTACHMENT : (state, data) => {
    state.preparationAttachments.push(data);
  },
  PUSH_PREPARATION_ATTACHMENTS_TO_ADD : (state, data) => {
    state.preparationAttachmentsToAdd.push(data)
  },
  PUSH_PREPARATION_ATTACHMENTS_TO_DELETE : (state, data) => {
    state.preparationAttachmentsToDelete.push(data)
  },
  EMPTY_PREPARATION_ATTACHMENTS_TO_ADD : () => {
    state.preparationAttachmentsToAdd = [];
  },
  EMPTY_PREPARATION_ATTACHMENTS_TO_DELETE : () => {
    state.preparationAttachmentsToDelete = [];
  },
  POP_PREPARATION_ATTACHMENT: (state, payload) => {
    let index = 0;
    if (payload.attachment.id === 0) {
      index = state.preparationAttachments.findIndex((val, index) => {
        return val.temp_file_name === payload.attachment.temp_file_name;
      });
    } else {
      index = state.preparationAttachments.findIndex((val, index) => {
        return val.id === payload.attachment.id;
      });
    }
    state.preparationAttachments.splice(index, 1);
  },
  POP_PREPARATION_ATTACHMENT_TO_ADD : (state, payload) => {
    let index = state.preparationAttachmentsToAdd.findIndex((val, index) => {
      return val.temp_file_name === payload.attachment.temp_file_name;
    });
    state.preparationAttachmentsToAdd.splice(index, 1);
  },
  POP_PREPARATION_ATTACHMENT_TO_DELETE : (state, payload) => {
    let index = state.preparationAttachmentsToDelete.findIndex((val, index) => {
      return val.id === payload.attachment.id;
    });
    state.preparationAttachmentsToDelete.splice(index, 1);
  },
  /**
   * COMMITS FOR PREPARATIONS ATTACHMENTS
   */
  ADD_VERIFICATION_ATTACHMENT : (state, data) => {
    state.verificationAttachments.push(data);
  },
  PUSH_VERIFICATION_ATTACHMENTS_TO_ADD : (state, data) => {
    state.verificationAttachmentsToAdd.push(data)
  },
  PUSH_VERIFICATION_ATTACHMENTS_TO_DELETE : (state, data) => {
    state.verificationAttachmentsToDelete.push(data)
  },
  EMPTY_VERIFICATION_ATTACHMENTS_TO_ADD : () => {
    state.verificationAttachmentsToAdd = [];
  },
  EMPTY_VERIFICATION_ATTACHMENTS_TO_DELETE : () => {
    state.verificationAttachmentsToDelete = [];
  },
  POP_VERIFICATION_ATTACHMENT: (state, payload) => {
    let index = 0;
    if (payload.attachment.id === 0) {
      index = state.verificationAttachments.findIndex((val, index) => {
        return val.temp_file_name === payload.attachment.temp_file_name;
      });
    } else {
      index = state.verificationAttachments.findIndex((val, index) => {
        return val.id === payload.attachment.id;
      });
    }
    state.verificationAttachments.splice(index, 1);
  },
  POP_VERIFICATION_ATTACHMENT_TO_ADD : (state, payload) => {
    let index = state.verificationAttachmentsToAdd.findIndex((val, index) => {
      return val.temp_file_name === payload.attachment.temp_file_name;
    });
    state.verificationAttachmentsToAdd.splice(index, 1);
  },
  POP_VERIFICATION_ATTACHMENT_TO_DELETE : (state, payload) => {
    let index = state.verificationAttachmentsToDelete.findIndex((val, index) => {
      return val.id === payload.attachment.id;
    });
    state.verificationAttachmentsToDelete.splice(index, 1);
  },
  /**
   * COMMITS FOR BEFORE REC ATTACHMENTS
   */
  ADD_BEFORE_REC_ATTACHMENT : (state, data) => {
    state.beforeRecAttachments.push(data);
  },
  PUSH_BEF_ATTACHMENTS_TO_ADD : (state, data) => {
    state.befRecAttachmentsToAdd.push(data)
  },
  PUSH_BEF_ATTACHMENTS_TO_DELETE : (state, data) => {
    state.befRecAttachmentsToDelete.push(data)
  },
  EMPTY_BEF_ATTACHMENTS_TO_ADD : () => {
    state.befRecAttachmentsToAdd = [];
  },
  EMPTY_BEF_ATTACHMENTS_TO_DELETE : () => {
    state.befRecAttachmentsToDelete = [];
  },
  POP_BEFORE_REC_ATTACHMENT : (state, payload) => {
    let index = 0;
    if (payload.attachment.id === 0) {
      index = state.beforeRecAttachments.findIndex((val, index) => {
        return val.temp_file_name === payload.attachment.temp_file_name;
      });
    } else {
      index = state.beforeRecAttachments.findIndex((val, index) => {
        return val.id === payload.attachment.id;
      });
    }
    state.beforeRecAttachments.splice(index, 1);
  },
  POP_BEF_ATTACHMENT_TO_ADD : (state, payload) => {
    let index = state.befRecAttachmentsToAdd.findIndex((val, index) => {
      return val.temp_file_name === payload.attachment.temp_file_name;
    });
    state.befRecAttachmentsToAdd.splice(index, 1);
  },
  POP_BEF_ATTACHMENT_TO_DELETE : (state, payload) => {
    let index = state.befRecAttachmentsToDelete.findIndex((val, index) => {
      return val.attachment.id === payload.attachment.id;
    });
    state.befRecAttachmentsToDelete.splice(index, 1);
  },
  /**
   * COMMITS FOR AFTER REC ATTACHMENTS
   */
  ADD_AFTER_REC_ATTACHMENT : (state, data) => {
    state.afterRecAttachments.push(data);
  },
  PUSH_AF_ATTACHMENTS_TO_ADD : (state, data) => {
    state.afRecAttachmentsToAdd.push(data)
  },
  PUSH_AF_ATTACHMENTS_TO_DELETE : (state, data) => {
    state.afRecAttachmentsToDelete.push(data)
  },
  EMPTY_AF_ATTACHMENTS_TO_ADD : () => {
    state.afRecAttachmentsToAdd = [];
  },
  EMPTY_AF_ATTACHMENTS_TO_DELETE : () => {
    state.afRecAttachmentsToDelete = [];
  },
  POP_AFTER_REC_ATTACHMENT : (state, payload) => {
    let index = 0;
    if (payload.attachment.id === 0) {
      index = state.afterRecAttachments.findIndex((val, index) => {
        return val.temp_file_name === payload.attachment.temp_file_name;
      });
    } else {
      index = state.afterRecAttachments.findIndex((val, index) => {
        return val.id === payload.attachment.id;
      });
    }
    state.afterRecAttachments.splice(index, 1);

  },
  POP_AF_ATTACHMENT_TO_ADD : (state, payload) => {
    let index = state.afRecAttachmentsToAdd.findIndex((val, index) => {
      return val.temp_file_name === payload.attachment.temp_file_name;
    });
    state.afRecAttachmentsToAdd.splice(index, 1);
  },
  POP_AF_ATTACHMENT_TO_DELETE : (state, payload) => {
    let index = state.afRecAttachmentsToDelete.findIndex((val, index) => {
      return val.attachment.id === payload.attachment.id;
    });
    state.afRecAttachmentsToDelete.splice(index, 1);
  },
  SET_DEFICIENCY:(state,deficiency)=>state.deficiency=Object.assign({},deficiency),
  SET_MOU_KEYS: (state, mouKeys) => state.mouKeys = mouKeys,
}

const actions = {
  async getPscReports({commit}, params) {
    const response = await GlobalService.ajax('get', PSC_INDEX, {params});
    const data = response?.data;

    commit('setReports', data?.data)
    commit('setPagination', data?.meta)
  },
  async createPscReport({commit}, params) {
    return await GlobalService.ajax('post', PSC_INDEX, params);
  },
  async getPscReport({commit}, params = {}) {
    params = {_source: 'PSC Report', ...params};

    const uri = [PSC_INDEX, params.id].join('/');
    const response = await GlobalService.ajax('get', uri, params);

    if (response.status === 200) {
      commit('SET_PSC_REPORT', response.data ?? {});
    }

    return response;
  },

  async updatePscInfo({commit}, params = {}) {
    const return_response = (params && params.hasOwnProperty('__return_response')) ? params.__return_response : false;
    delete params['__return_response'];

    const uri = [PSC_INDEX, params.id].join('/');
    const response = await GlobalService.ajax('put', uri, params);

    if (response.hasOwnProperty('status') && response.status === 200) {
      commit('SET_PSC_REPORT', response?.data ?? {});

      return return_response ? response : (response?.data ?? false);
    }

    return return_response ? response : false;
  },
  async uploadPscAttachments({commit}, params) {
    const id = params instanceof FormData ? params.get('id') : params?.id ?? null
    const uri = [PSC_INDEX, id, 'upload'].join('/');
    const response = await GlobalService.ajax('post', uri, params);
    if (response.hasOwnProperty('status') && response.status === 200) {
      return response?.data ?? false;
    }
    return false;
  },

  async getPscAttachments({commit}, params) {
    commit('SET_PSC_ATTACHMENTS',{attachments:[],type_id:params.type.type_id})
    const uri = [PSC_INDEX, params.id, 'attachments'].join('/');
    const response = await GlobalService.ajax('post', uri, params);
    if (response.hasOwnProperty('status') && response.status === 200) {
      commit('SET_PSC_ATTACHMENTS',{attachments:response?.data ?? [], type_id:params.type.type_id} );
      return response?.data ?? false;
    }
    return false;
  },

  async renderDeficiencyAttachments({dispatch},deficiency){
    [2,3,4,5].forEach(type=>{
      let params={
        id:deficiency.id,
        type:{
          id:null,
          type_id:type
        }
      }
      switch (type) {
        case 2:
        case 3:
          params.type.id = deficiency.deficiency.corrective.id
          break;
        case 4:
        case 5:
          params.type.id = deficiency.deficiency.id
          break;
        default:
          params.type.id = null
          break;
      }

      dispatch('getPscAttachments',params)
    });
  },

  async viewPscAttachment({commit}, params) {
    const uri = [PSC_INDEX, params.id, 'download'].join('/');
    const response = await axios({
      responseType: "blob",
      method: 'post',
      url: uri,
      data: {...params}
    });
    if (response.hasOwnProperty('status') && response.status === 200) {
      return response?.data ?? false;
    }
    return false;
  },


  async downloadPscAttachments({commit}, params) {
    const uri = [PSC_INDEX, params.id, 'download-all'].join('/');
    const response = await axios({
      method: 'post',
      url: uri,
      data: {...params}
    });
    if (response.hasOwnProperty('status') && response.status === 200) {
      return response?.data ?? false;
    }
    return false;
  },

  async deletePscAttachment({commit}, params) {
    const uri = [PSC_INDEX, params.id, 'delete-attachment'].join('/');
    const response = await axios({
      method: 'post',
      url: uri,
      data: {...params}
    });
    if (response.hasOwnProperty('status') && response.status === 200) {
      return response?.data ?? false;
    }
    return false;
  },

  async deletePsc({commit}, params) {
    const uri = [PSC_INDEX, 'delete'].join('/')
    const response = await GlobalService.ajax('post', uri, params)
    if (response.hasOwnProperty('status') && response.status === 200) {
      return true;
    }
    return response;
  },

  async getPscCodes({commit}, params) {

    const response = await GlobalService.ajax('get', PSC_CODES, params)
    if (response.hasOwnProperty('status') && response.status === 200) {
      commit('SET_PSC_CODE_KEYS', response?.data ?? [])
      return response?.data ?? false;
    }
    return false;
  },


  async getPscActionCodes({commit}, params) {

    const response = await GlobalService.ajax('get', PSC_ACTION_CODES, params)
    if (response.hasOwnProperty('status') && response.status === 200) {
      commit('SET_PSC_ACTION_CODE_KEYS', response?.data ?? [])
      return response?.data ?? false;
    }
    return false;
  },

  async getUscgCodes({commit},params) {
    const response = await GlobalService.ajax('get', PSC_USCG_CODES, params)
    if (response.hasOwnProperty('status') && response.status === 200) {
      commit('SET_USCG_CODES', response?.data ?? [])
      return response?.data ?? false;
    }
    return false;
  },

  async downloadPscList({commit},params) {
    const uri = [PSC_INDEX, 'download-list'].join('/');
    const queryString= UrlService.setQueryString(params)
    const response = await axios({
        url:uri + queryString,
        method: 'GET',
        responseType: 'blob'
    });
    if (response.hasOwnProperty('status') && response.status === 200) {
      return response?.data ?? false;
    }
    return false;
  },
}

const getters = {
  pscKeys: (state) => state.pscKeys,
  pscReport: (state) => state.pscReport,
  pscAttachments: (state) => state.pscAttachments,
  befRecAttachmentsToAdd: (state) => state.befRecAttachmentsToAdd,
  befRecAttachmentsToDelete: (state) => state.befRecAttachmentsToDelete,
  afRecAttachmentsToAdd: (state) => state.afRecAttachmentsToAdd,
  afRecAttachmentsToDelete: (state) => state.afRecAttachmentsToDelete,
  pscCodeKeys: (state) => state.pscCodeKeys,
  pscActionCodeKeys: (state) => state.pscActionCodeKeys,
  preparationAttachments:(state)=>state.preparationAttachments,
  preparationAttachmentsToAdd:(state)=>state.preparationAttachmentsToAdd,
  preparationAttachmentsToDelete:(state)=>state.preparationAttachmentsToDelete,
  verificationAttachments:(state)=>state.verificationAttachments,
  verificationAttachmentsToAdd:(state)=>state.verificationAttachmentsToAdd,
  verificationAttachmentsToDelete:(state)=>state.verificationAttachmentsToDelete,
  beforeRecAttachments:(state)=>state.beforeRecAttachments,
  afterRecAttachments:(state)=>state.afterRecAttachments,
  occasionalSurveyAttachments:(state)=>state.occasionalSurveyAttachments,
  toEdit:(state)=>state.toEdit,
  pscHasChanges:(state)=>state.hasChanges,
  deficiency:(state)=>state.deficiency,
  uscgCodes:(state) => state.uscgCodes,
  mouKeys: (state) => state.mouKeys,
}

export default {
  state,
  actions,
  mutations,
  getters
}
