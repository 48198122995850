import {EngineTcTypeService} from "@/services/EngineTcTypeService";
import {GlobalService} from "@/services/GlobalService";

const state={
  engineTcType:[],
}

const mutations={
  SET_ENGINE_TC_TYPE : (state,payload)=>state.engineTcType=payload,
  ADD_ENGINE_TC_TYPE : (state,payload)=>{
    if(state.engineTcType.length > 15) state.engineTcType.pop()

    state.engineTcType.push(payload)

    state.engineTcType.sort((a,b)=> GlobalService.sortFunc(a,b));
  },

  UPDATE_ENGINE_TC_TYPE : (state,payload) => {
    let index= state.engineTcType.findIndex(maker=> maker.id===payload.id);

    state.engineTcType.splice(index,1,payload);

    state.engineTcType.sort((a,b)=> GlobalService.sortFunc(a,b));
  }
}

const actions={
  async getEngineTcTypes({commit},params){
    const response= await EngineTcTypeService.getEngineTcTypes(params)
    const isGet= params?.get ?? false
    if(response && !isGet){
      commit ('setPagination',response)
      commit ('SET_ENGINE_TC_TYPE',response.data)
    }
    else{
      commit ('SET_ENGINE_TC_TYPE',response)
    }
    return response;
  },

  async createEngineTcType({commit},params){
    const response= await EngineTcTypeService.createEngineTcType(params)
    if(!response.errors){
      commit ('ADD_ENGINE_TC_TYPE',response)
    }
    return response;
  },

  async updateEngineTcType({commit},params){
    const response= await EngineTcTypeService.updateEngineTcType(params)
    if(!response.errors){
      commit ('UPDATE_ENGINE_TC_TYPE',response)
    }
    return response;
  },

  async deleteEngineTcType({commit},params){
    return await EngineTcTypeService.deleteEngineTcType(params);
  }
}

const getters={
  engineTcType:(state)=>GlobalService.capitalizeProperties(state.engineTcType)
}


export default {state,actions,mutations,getters}
