import axios from "axios";
import {
  CMS_CER_COMMENT_ATTACHMENT,
  CMS_CER_FILE,
  CMS_COMPANY_EVALUATIONS,
  CMS_COMPANY_EVALUTAION_FILE,
  CMS_CREW_ACTIVITIES,
  CMS_CREW_ASSESSMENT_FILE,
  CMS_CREW_ASSESSMENT_HISTORY,
  CMS_CREW_ASSESSMENTS_REPORT,
  CMS_CREW_BIODATA,
  CMS_CREW_CERS,
  CMS_CREW_CONSENT,
  CMS_CREW_DOCUMENT_FILE,
  CMS_CREW_DOCUMENT_HISTORY,
  CMS_CREW_DOCUMENTS,
  CMS_CREW_FLAG_DOCUMENTS,
  CMS_CREW_HEALTH_DECLARATIONS,
  CMS_CREW_IMAGE,
  CMS_CREW_INFO,
  CMS_CREW_MEDICAL_FILE,
  CMS_CREW_MEDICAL_HOSPITALIZATION_FILE,
  CMS_CREW_MEDICAL_INCIDENT_FILE,
  CMS_CREW_MEDICAL_INJURY_FILE,
  CMS_CREW_MEDICAL_ISSUES,
  CMS_CREW_MEDICALS,
  CMS_CREW_PANDI,
  CMS_CREW_PANDI_CHECKUP_FILE,
  CMS_CREW_PANDI_EXPENSE_FILE,
  CMS_CREW_RT_PCR,
  CMS_CREW_VACCINE,
  CMS_CREW_VACCINE_FILE,
  CMS_HEALTH_DECLARATION_FILE,
  CMS_INCIDENT_BEHAVIOR,
  CMS_INCIDENT_BEHAVIOR_FILE,
  CMS_MEDICAL_KEYS,
  CMS_OUTSIDE_EXPERIENCE,
  CMS_RT_PCR_FILE,
  CMS_SEA_EXPERIENCE
} from "@/services/ConstantService";
import {GlobalService} from "@/services/GlobalService";

export const CrewService={
  async getCrewInfoFromCMS(params){
    return await axios.post(CMS_CREW_INFO,params).then(res=>{
      return res.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CREW INFO FROM CMS SERVICE')
      return false
    })
  },
  async getCrewActivitiesFromCMS(params){
    return await axios.post(CMS_CREW_ACTIVITIES,params).then(res=>{
      return res.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CREW ACTIVITES FROM CMS SERVICE')
      return false
    })
  },

  async getCrewImageFromCms(params){
    return await axios.post(CMS_CREW_IMAGE,params).then(res=>{
      return res.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CREW IMAGE FROM CMS SERVICE')
      return false
    })
  },

  async getCrewConsentFromCms(params){
    return await axios.post(CMS_CREW_CONSENT,params).then(res=>{
      return res.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CREW CONSENT FROM CMS SERVICE')
      return false
    })
  },

  async getCrewBiodataFromCms(params){
    return await axios.post(CMS_CREW_BIODATA,params).then(res=>{
      return res.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CREW BIODATA FROM CMS SERVICE')
      return false
    })
  },

  async getCrewSeaExperienceFromCms(params){
    return await axios.post(CMS_SEA_EXPERIENCE,params).then(res=>{
      return res.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CREW SEA EXPERIENCE FROM CMS SERVICE')
      return false
    })
  },

  async getCrewOutsideExperienceFromCms(params){
    return await axios.post(CMS_OUTSIDE_EXPERIENCE,params).then(res=>{
      return res.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CREW OUTSIDE EXPERIENCE FROM CMS SERVICE')
      return false
    })
  },

  async getCrewIncidentBehaviorFromCms(params){
    return await axios.post(CMS_INCIDENT_BEHAVIOR,params).then(res=>{
      return res.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CREW INCIDENT BEHAVIOR FROM CMS SERVICE')
      return false
    })
  },

  async getCrewIncidentFileFromCms(params){
    return await axios.post(CMS_INCIDENT_BEHAVIOR_FILE,params).then(res=>{
      return res.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CREW INCIDENT FILE FROM CMS SERVICE')
      return false
    })
  },

  async getCrewDocumentFromCms(params){
    return await axios.post(CMS_CREW_DOCUMENTS,params).then(res=>{
      return res.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CREW DOCUMENTS  FROM CMS SERVICE')
      return false
    })
  },

  async getCrewFlagDocuments(params){
    return await axios.post(CMS_CREW_FLAG_DOCUMENTS,params).then(res=>{
      return res.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CREW DOCUMENTS  FROM CMS SERVICE')
      return false
    })
  },

  async getCrewDocumentHistoryFromCms(params){
    return await axios.post(CMS_CREW_DOCUMENT_HISTORY,params).then(res=>{
      return res.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CREW INCIDENT FILE FROM CMS SERVICE')
      return false
    })
  },
  async getCrewDocumentFileFromCms(params){
    return await axios.post(CMS_CREW_DOCUMENT_FILE,params).then(res=>{
      return res.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CREW INCIDENT FILE FROM CMS SERVICE')
      return false
    })
  },

  async getCrewAssessmentsFromCms(params){
    return await axios.post(CMS_CREW_ASSESSMENTS_REPORT,params).then(res=>{
      return res.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CREW ASSESSMENT REPORTS  FROM CMS SERVICE')
      return false
    })
  },

  async getCrewAssessmentFileFromCms(params){
    return await axios.post(CMS_CREW_ASSESSMENT_FILE,params).then(res=>{
      return res.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CREW ASSESSMENT FILE  FROM CMS SERVICE')
      return false
    })
  },
  async getCrewAssessmentHistoryFromCms(params){
    return await axios.post(CMS_CREW_ASSESSMENT_HISTORY,params).then(res=>{
      return res.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CREW ASSESSMENT HISTORY  FROM CMS SERVICE')
      return false
    })
  },

  async getMedicalKeysFromCms(params) {
    return await axios.post(CMS_MEDICAL_KEYS,params).then(res=>{
      return res.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET MEDICAL KEYS  FROM CMS SERVICE')
      return false
    })
  },
  async getCrewMedicalsFromCms(params) {
    return await axios.post(CMS_CREW_MEDICALS,params).then(res=>{
      return res.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CREW MEDICALS FROM CMS SERVICE')
      return false
    })
  },
  async getCrewHealthDeclarationsFromCms(params) {
    return await axios.post(CMS_CREW_HEALTH_DECLARATIONS,params).then(res=>{
      return res.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CREW HEALTH DECLARATIONS FROM CMS SERVICE')
      return false
    })
  },
  async getCrewRtPcrFromCms(params) {
    return await axios.post(CMS_CREW_RT_PCR,params).then(res=>{
      return res.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CREW RTPCR FROM CMS SERVICE')
      return false
    })
  },

  async getCrewVaccineFromCms(params) {
    return await axios.post(CMS_CREW_VACCINE,params).then(res=>{
      return res.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CREW VACCINE FROM CMS SERVICE')
      return false
    })
  },

  async getCrewMedicalFileFromCms(params) {
    return await axios.post(CMS_CREW_MEDICAL_FILE,params).then(res=>{
      return res.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CREW MEDICAL FILE FROM CMS SERVICE')
      return false
    })
  },
  async getCrewVaccineFileFromCMS(params) {
    return await axios.post(CMS_CREW_VACCINE_FILE,params).then(res=>{
      return res.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CREW VACCINE FILE FROM CMS SERVICE')
      return false
    })
  },
  async getRtPcrTestFileFromCms(params) {
    return await axios.post(CMS_RT_PCR_FILE,params).then(res=>{
      return res.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CREW RTPCR FILE FROM CMS SERVICE')
      return false
    })
  },
  async getHealthDeclationFile(params) {
    return await axios.post(CMS_HEALTH_DECLARATION_FILE,params).then(res=>{
      return res.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CREW HEALTH DECLARATION FILE FROM CMS SERVICE')
      return false
    })
  },
  async getCrewCers(params){
    return await axios.post(CMS_CREW_CERS,params).then(res=>{
      return res.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CREW CERS FROM CMS SERVICE')
      return false
    });
  },

  async getCrewCerFile(params) {
    return await axios.post(CMS_CER_FILE,params).then(res=>{
      return res.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CREW CERS FROM CMS SERVICE')
      return false
    });
  },
  async getCrewCommentAttachment(params) {
    return await axios.post(CMS_CER_COMMENT_ATTACHMENT,params).then(res=>{
      return res.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CREW COMMENT FILE FROM CMS SERVICE')
      return false
    });
  },

  async getCompanyEvaluations(params){
    return await axios.post(CMS_COMPANY_EVALUATIONS,params).then(res=>{
      return res.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CREW EVALUATIONS FROM CMS SERVICE')
      return false
    });
  },

  async getCompanyEvaluationsFile(params){
    return await axios.post(CMS_COMPANY_EVALUTAION_FILE,params).then(res=>{
      return res.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CREW EVALUATION FILE FROM CMS SERVICE')
      return false
    });
  },
  async getMedicalIssuesFromCms(params){
    return await axios.post(CMS_CREW_MEDICAL_ISSUES,params).then(res=>{
      return res.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CREW MEDICAL ISSUES FROM CMS SERVICE')
      return false
    });
  },
  async getPandIsFromCms(params){
    return await axios.post(CMS_CREW_PANDI,params).then(res=>{
      return res.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CREW PandIs FROM CMS SERVICE')
      return false
    });
  },
  async getPandICheckupAttachmentFromCms(params){
    return await axios.post(CMS_CREW_PANDI_CHECKUP_FILE,params).then(res=>{
      return res.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CREW P and I CHECKUP FILE FROM CMS SERVICE')
      return false
    });
  },

  async getPandIExpenseAttachmentFromCms(params){
    return await axios.post(CMS_CREW_PANDI_EXPENSE_FILE,params).then(res=>{
      return res.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CREW P and I CHECKUP FILE FROM CMS SERVICE')
      return false
    });
  },

  async getMedicalIncidentFileFromCms(params){
    return await axios.post(CMS_CREW_MEDICAL_INCIDENT_FILE,params).then(res=>{
      return res.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CREW MEDICAL INCIDENT FILE FROM CMS SERVICE')
      return false
    });
  },

  async getMedicalInjuryFileFromCms(params){
    return await axios.post(CMS_CREW_MEDICAL_INJURY_FILE,params).then(res=>{
      return res.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CREW MEDICAL INJURY FILE FROM CMS SERVICE')
      return false
    });
  },

  async getMedicalHospitalizationFileFromCms(params){
    return await axios.post(CMS_CREW_MEDICAL_HOSPITALIZATION_FILE,params).then(res=>{
      return res.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CREW MEDICAL HOSPITALIZATION FILE FROM CMS SERVICE')
      return false
    });
  },


}
