const state = {
  isLoading: true,
  pagination: {
    from: 1,
    to: 15,
    currentPage: 1,
    lastPage: 0,
    perPage: 15,
    total: 0
  },

};


const mutations = {
  SET_IS_LOADING: (state, isLoading) => state.isLoading = isLoading,
  setPagination: (state, data) => {
    state.pagination = {
      from: data?.from,
      to: data?.to,
      currentPage: data?.current_page,
      lastPage: data?.last_page,
      perPage: data?.per_page,
      total: data?.total
    };
  },
};


const actions = {
  setLoading({ commit }, loading) {
    commit('SET_IS_LOADING', loading);
  }
};


const getters = {
  isLoading: state => state.isLoading,
  pagination: (state) => state.pagination,

};


export default {state, actions, mutations, getters};
