import Vue from 'vue'
import VueRouter from 'vue-router'
import {TOKEN_NAME, USER_DETAILS} from '@/services/ConstantService'

// Route Settings
import {RouteSettings} from '@/router/RouteSettings';
import {RoutePsc} from '@/router/RoutePsc';
import {RouteRightship} from "@/router/RouteRightship";
import {RouteInternalAudit} from "@/router/RouteInternalAudit";
import VesselDocuments from "@/components/vessel/documents/VesselDocuments.vue";
import VesselVisitation from "@/components/vessel/documents/partials/VesselVisitation.vue";
import VesselFiles from "@/components/vessel/documents/partials/VesselFiles.vue";
const VesselHistory  = () => import("@/components/vessel/VesselHistory");
const AccidentIncidentHistory  = () => import("@/components/vessel/history/AccidentIncidentHistory");
const PscInspectionHistory  = () => import("@/components/vessel/history/PscInspectionHistory");
const VesselInternalAuditHistory = () => import('@/components/modules/ia/vessel/VesselAuditRecordList');
const CompanyInstruction  = () => import("@/views/CompanyInstruction.vue");
const AirCompanyInstructionList  = () => import("@/components/modules/company-instruction/AirCompanyInstructionList.vue");
const MasterAirCompanyInstruction = () => import("@/components/modules/company-instruction/master/MasterAirCompanyInstruction.vue")
const StaffAirCompanyInstruction = () => import("@/components/modules/company-instruction/staff/StaffAirCompanyInstruction.vue")
const StaffPscCompanyInstruction = () => import("@/components/modules/company-instruction/staff/StaffPscCompanyInstruction.vue")

const StaffGeneralCompanyInstruction = () => import("@/components/modules/company-instruction/staff/StaffGeneralCompanyInstruction.vue")
const Login = () => import('../views/Login.vue');
const Base = () => import('../views/Base');

const Dashboard = () => import('../views/Dashboard');
const NumberOfManagement = () => import('../components/dashboard/NumberOfManagement');
const AiDashboard = () => import('../components/dashboard/AiDashboard');
const PscDashboard = () => import('../components/dashboard/PscDashboard');
const PscDeficiencySummary = () => import('../components/dashboard/psc/PscDeficiencySummary');
const PscInspectionByCountry = () => import('../components/dashboard/psc/PscInspectionByCountry');
const PscMonthlyCommittee = () => import('../components/dashboard/psc/PscMonthlyCommittee');
const PscMonthlySummary = () => import('../components/dashboard/psc/PscMonthlySummary');
const PscYearlyOverview = () => import('../components/dashboard/psc/PscYearlyOverview');

const PscDashboardGeneral = () => import('../components/dashboard/psc/PscDashboardGeneral');
const Masterlist = () => import('../views/Masterlist');
const Module = () => import('../views/Module');
const ReportIndex = () => import('../components/modules/ReportIndex');
const AccidentIncidentReport = () => import('../components/modules/accident-incident/AccidentIncidentReport');
const General = () => import('../components/modules/accident-incident/General');
const Event = () => import('../components/modules/accident-incident/Event');
const Loss = () => import('../components/modules/accident-incident/Loss');
const Cause = () => import('../components/modules/accident-incident/Cause');
const CorrectiveAction = () => import('../components/modules/accident-incident/CorrectiveAction');
const PreventiveAction = () => import('../components/modules/accident-incident/PreventiveAction');
const Feedback = () => import('../components/modules/accident-incident/Feedback');
const ShipParticular = () => import('../components/modules/accident-incident/ShipParticular');
const Vessel = () => import('../views/Vessel');
const VesselIndex = () => import('../components/vessel/VesselIndex');
const VesselAdd = () => import('../components/vessel/VesselAdd');
const VesselOverview = () => import('../components/vessel/VesselOverview');
const VesselCrewLists = () => import('../components/vessel/VesselCrewLists');

// Company

const Company = () => import('../views/Company');
const CompanyIndex = ()  => import('../components/company/CompanyIndex');

const ShipsPI = () => import('../views/ShipsPI');
const VesselParticular = () => import('../components/vessel/VesselParticular');
const Attachment = () => import('../components/modules/accident-incident/Attachment')
const AirCompanyInstruction = () => import('../components/modules/accident-incident/AirCompanyInstruction.vue')
const CrewInfo = () => import('../components/crew/profile/CrewInfo');
const Crew = () => import('../views/Crew')
const CrewProfile = () => import('../components/crew/profile/CrewProfile');
const CrewPersonal = () => import('../components/crew/profile/CrewPersonal')
const CrewEducation = () => import('../components/crew/profile/CrewEducation')
const CrewFamily = () => import('../components/crew/profile/CrewFamily')
const CrewAddress = () => import('../components/crew/profile/CrewAddress')
const CrewActivity = () => import('../components/crew/CrewActivity')
const Experience = () => import('../components/crew/experience/Experience')
const SeaExperience = () => import('../components/crew/experience/SeaExperience')
const OutsideExperience = () => import('../components/crew/experience/OutsideExperience')
const CrewBehavior = () => import('../components/crew/CrewBehavior');
const CrewDocument = () => import('../components/crew/document/Document');
const International = () => import('../components/crew/document/International')
const Certificates = () => import('../components/crew/document/Certificates')
const OtherTraining = () => import('../components/crew/document/OtherTraining')
const Licences = () => import('../components/crew/document/Licences')
const FlagDocument = () => import('../components/crew/document/FlagDocument')
const AssessmentReport = () => import('../components/crew/document/AssessmentReport')
const Main = () => import('../components/crew/document/medical/Main')
const MedicalDocuments = () => import('../components/crew/document/medical/Medical')
const HealthChecklist = () => import('../components/crew/document/medical/Checklist')
const Pcr = () => import('../components/crew/document/medical/Pcr')
const Vaccine = () => import('../components/crew/document/medical/Vaccine')
const Cer = () => import('../components/crew/CrewCer')
const Issue = () => import('../components/crew/issues/Issue')
const Medicals = () => import('../components/crew/issues/Medicals')
const Pandi = () => import('../components/crew/issues/Pandi')
const StaffCompanyInstruction = () => import('../components/modules/company-instruction/staff/StaffCompanyInstruction')
const MasterCompanyInstruction = () => import('../components/modules/company-instruction/master/MasterCompanyInstruction')
const StaffCompanyInstructionResponse = ()=> import("@/components/modules/company-instruction/master/StaffCompanyInstructionResponse.vue");
const VesselCompanyInstructionResponse = ()=> import("@/components/modules/company-instruction/master/VesselCompanyInstructionResponse.vue");
const VesselSisterShip = ()=> import("@/components/vessel/VesselSisterShip.vue");
const PscVesselPerformance = () => import("@/components/dashboard/psc/PscVesselPerfomance.vue")
const PscInspectionByMou = () => import("@/components/dashboard/psc/PscInspectionByMou.vue");

Vue.use(VueRouter)

const login = {
  path: '/',
  name: 'Login',
  component: Login
}
const maintenance = {
  path: '/maintenance',
  name: 'MaintenanceMode',
  component: () => import('../components/MaintenanceMode'),
  meta: {
    requiresAuth: false
  },
  params: {
    maintenance: true
  }

}
const routes = [
  login,
  maintenance,
  {
    path: '/',
    name: 'Base',
    component: Base,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'dashboard/',
        name: 'Dashboard',
        component: Dashboard,
        redirect: 'dashboard/ai/number_of_report',
        children: [
          {
            path: 'vessel_info/:report_type',
            name: 'NumberOfManagement',
            component: NumberOfManagement
          },
          {
            path: 'ai/:chart_module',
            name: 'AiDashboard',
            component: AiDashboard
          },
          {
            path: 'psc/',
            name: 'PscDashboard',
            component: PscDashboard,
            redirect: 'psc/yearly',
            children: [
              {
                path: 'yearly',
                name: 'PscYearlyOverview',
                component: PscYearlyOverview,
              },
              {
                path: 'country',
                name: 'PscInspectionByCountry',
                component: PscInspectionByCountry,
              },
              {
                path: 'mou',
                name: 'PscInspectionByMOU',
                component: PscInspectionByMou,
              },
              {
                path: 'monthly',
                name: 'PscMonthlySummary',
                component: PscMonthlySummary,
              },
              {
                path: 'monthly-committee',
                name: 'PscMonthlyCommittee',
                component: PscMonthlyCommittee,
              },
              {
                path: 'deficiency',
                name: 'PscDeficiencySummary',
                component: PscDeficiencySummary,
              },
              {
                path: 'vessel-performance',
                name: 'PscVesselPerformance',
                component: PscVesselPerformance,
              },
              {
                path: 'general',
                name: 'PscDashboardGeneral',
                component: PscDashboardGeneral,
              },
            ]
          }
        ]
        // component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard')
      },
      {
        path: 'masterlist/',
        name: 'Masterlist',
        component: Masterlist
        // component: () => import(/* webpackChunkName: "masterlist" */ '../views/Masterlist')
      },
      {
        path: 'module/',
        name: 'Module',
        redirect: '/module/index',
        component: Module,
        // component: () => import(/* webpackChunkName: "module" */ '../views/Module'),
        children: [
          {
            path: 'index/',
            name: 'ReportIndex',
            component: ReportIndex
            // component: () => import(/* webpackChunkName: "index-module" */ '../components/modules/ReportIndex')
          },
          {
            path: ':id/',
            name: 'AccidentIncidentReport',
            component: AccidentIncidentReport,
            // component: () => import(/* webpackChunkName: "accident-incident" */ '../components/modules/accident-incident/AccidentIncidentReport'),
            redirect: ':id/general',
            children: [
              {
                path: 'general/',
                name: 'General',
                component: General
                // component: () => import(/* webpackChunkName: "ai-general" */ '../components/modules/accident-incident/General')
              },
              {
                path: 'event/',
                name: 'Event',
                component: Event
                // component: () => import(/* webpackChunkName: "ai-event" */ '../components/modules/accident-incident/Event')
              },
              {
                path: 'loss/',
                name: 'Loss',
                component: Loss
                // component: () => import(/* webpackChunkName: "ai-loss" */ '../components/modules/accident-incident/Loss')
              },
              {
                path: 'cause/',
                name: 'Cause',
                component: Cause
                // component: () => import(/* webpackChunkName: "ai-cause" */ '../components/modules/accident-incident/Cause')
              },
              {
                path: 'attachment/',
                name: 'Attachment',
                component: Attachment
                // component: () => import(/* webpackChunkName: "ai-cause" */ '../components/modules/accident-incident/Cause')
              },
              {
                path: 'corrective-action/',
                name: 'CorrectiveAction',
                component: CorrectiveAction
                // component: () => import(/* webpackChunkName: "ai-corrective-action" */ '../components/modules/accident-incident/CorrectiveAction')
              },
              {
                path: 'preventive-action/',
                name: 'PreventiveAction',
                component: PreventiveAction
                // component: () => import(/* webpackChunkName: "ai-preventive-action" */ '../components/modules/accident-incident/PreventiveAction')
              },
              {
                path: 'feedback/',
                name: 'Feedback',
                component: Feedback
                // component: () => import(/* webpackChunkName: "ai-feedback" */ '../components/modules/accident-incident/Feedback')
              },
              {
                path: 'ship-particular/:vesselId',
                name: 'ShipParticular',
                component: ShipParticular,
              },
              {
                path: 'company-instruction/',
                name: 'AirCompanyInstruction',
                component: AirCompanyInstruction
                // component: () => import(/* webpackChunkName: "ai-feedback" */ '../components/modules/accident-incident/Feedback')
              }
            ]
          }
        ]
      },
      RoutePsc,
      RouteRightship,
      RouteInternalAudit,
      {
        path: 'vessels/',
        name: 'Vessel',
        redirect: '/vessels/index',
        component: Vessel,
        // component: () => import(/* webpackChunkName: "vessel" */ '../views/Vessel'),
        children: [
          {
            path: 'index/',
            name: 'VesselIndex',
            component: VesselIndex
            // component: () => import(/* webpackChunkName: "index-module" */ '../components/vessel/VesselIndex')
          },
          {
            path: 'create/',
            name: 'VesselAdd',
            component: VesselAdd
            // component: () => import(/* webpackChunkName: "index-module" */ '../components/vessel/VesselAdd')
          },
          {
            path: ':id',
            name: 'VesselOverview',
            component: VesselOverview,
            // component: () => import(/* webpackChunkName: "accident-incident" */ '../components/vessel/VesselOverview'),
            children: [
              {path: 'particular', name: 'VesselParticular', component: VesselParticular},
              {path: 'sister-ship', name: 'VesselSisterShip', component: VesselSisterShip},
              {path: 'crews', name: 'VesselCrewLists', component: VesselCrewLists},
              {
                path: 'documents',
                name: 'VesselDocuments',
                component: VesselDocuments,
                redirect: 'documents/vessel-files',
                children: [
                  {
                    path: 'vessel-visitation',
                    name: 'VesselVisitation',
                    component: VesselVisitation
                  },
                  {
                    path: 'vessel-files',
                    name: 'VesselFiles',
                    component: VesselFiles
                  }
                ]
              },
              {
                path: 'history',
                name: 'VesselHistory',
                component: VesselHistory,
                redirect: 'history/accident-incident',
                children: [
                  {
                    path: 'accident-incident',
                    name: 'VesselAccidentIncidentHistory',
                    component: AccidentIncidentHistory
                  },
                  {
                    path: 'psc-inspection',
                    name: 'VesselPscInspectionHistory',
                    component: PscInspectionHistory
                  },
                  {
                    path: 'vessel-internal-audit',
                    name: 'VesselInternalAuditHistory',
                    component: VesselInternalAuditHistory
                  },
                ],
              },
            ]
          }
        ]
      },
      {
        path : 'companies/',
        name: 'Company',
        redirect: '/companies/index',
        component : Company,
        children: [
          {
            path: 'index/',
            name: 'CompanyIndex',
            component: CompanyIndex
            // component: () => import(/* webpackChunkName: "index-module" */ '../components/vessel/VesselIndex')
          },
        ]
      },
      {
        path: 'company-instruction/',
        name: 'CompanyInstruction',
        component: CompanyInstruction,
        redirect: 'company-instruction/staff/air',
        children: [
          {
            path:'staff/air',
            name:'StaffCiAir',
            component:StaffCompanyInstruction,
            meta: { key: 'staffAir' }
          },
          {
            path:'staff/psc',
            name:'StaffCiPsc',
            component:StaffCompanyInstruction,
            meta: { key: 'staffPsc' }
          },
          {
            path:'staff/rs',
            name:'StaffCiRs',
            component:StaffCompanyInstruction
          },
          {
            path:'staff/vessel-audit',
            name:'StaffCiOe',
            component:StaffCompanyInstruction,
            meta: { key: 'staffOe' }
          },
          {
            path:'staff/vessel/response',
            name:'StaffVesselResponse',
            component:StaffCompanyInstructionResponse
          },
          {
            path:'master/air',
            name:'MasterCiAir',
            component:MasterCompanyInstruction
          },
          {
            path:'master/vessel/response',
            name:'MasterVesselResponse',
            component:VesselCompanyInstructionResponse
          },
          {
            path:'master/psc',
            name:'MasterCiPsc',
            component:MasterCompanyInstruction
          },
          {
            path:'master/rs',
            name:'MasterCiRs',
            component:MasterCompanyInstruction
          },
          {
            path:'staff/vessel/response/general',
            name:'MasterVesselResponse',
            component: StaffGeneralCompanyInstruction
          },
          {
            path:'master/vessel-audit',
            name:'MasterCiOe',
            component:MasterCompanyInstruction
          },
        ]
      },
      {
        path: 'ships-pi/',
        name: 'ShipsPI',
        component: ShipsPI
      },
      RouteSettings,
      {
        name: 'Crew',
        path: 'crew/',
        component: Crew,
        children: [
          {
            name: 'CrewInfo',
            path: ':id/',
            component: CrewInfo,
            redirect: ':id/activities',
            children: [
              {
                name: 'CrewProfile',
                path: 'profile/',
                component: CrewProfile,
                redirect: 'profile/personal',
                children: [
                  {
                    name: 'CrewPersonal',
                    path: 'personal/',
                    component: CrewPersonal,
                  },
                  {
                    name: 'CrewEducation',
                    path: 'education/',
                    component: CrewEducation,
                  },
                  {
                    name: 'CrewFamily',
                    path: 'family/',
                    component: CrewFamily,
                  },
                  {
                    name: 'CrewAddress',
                    path: 'address/',
                    component: CrewAddress,
                  },

                ]
              },
              {
                name: 'CrewActivity',
                path: 'activities/',
                component: CrewActivity,
              },
              {
                name: 'Experience',
                path: 'experience/',
                component: Experience,
                redirect: "experience/sea_experience",
                children: [
                  {
                    name: 'SeaExperience',
                    path: 'sea_experience',
                    component: SeaExperience
                  },
                  {
                    name: 'OutsideExperience',
                    path: 'outside_experience',
                    component: OutsideExperience
                  }
                ],
              },
              {
                name: 'CrewBehavior',
                path: 'behavior/',
                component: CrewBehavior,
              },
              {
                name: 'CrewDocument',
                path: 'document/',
                component: CrewDocument,
                redirect: 'document/international',
                children: [
                  {
                    name: 'CrewInternational',
                    path: 'international/',
                    component: International,
                  },
                  {
                    name: 'CrewCertificates',
                    path: 'certificates/',
                    component: Certificates,
                  },
                  {
                    name: 'CrewTraining',
                    path: 'other-training/',
                    component: OtherTraining,
                  },
                  {
                    name: 'CrewLicences',
                    path: 'licences/',
                    component: Licences,
                  },
                  {
                    name: 'CrewFlagDocument',
                    path: 'flag-documents/',
                    component: FlagDocument,
                  },
                  {
                    name: 'CrewAssessmentReport',
                    path: 'assessment-report/',
                    component: AssessmentReport,
                  },
                  {
                    name: 'CrewMedical',
                    path: 'medicals/',
                    component: Main,
                    redirect: 'medicals/documents',
                    children: [
                      {
                        name: 'CrewMedicalDocument',
                        path: 'documents/',
                        component: MedicalDocuments
                      },
                      {
                        name: 'CrewHealthChecklist',
                        path: 'health-checklist/',
                        component: HealthChecklist
                      },
                      {
                        name: 'CrewPcr',
                        path: 'rt-pcr',
                        component: Pcr,
                      },
                      {
                        name: 'CrewVaccine',
                        path: 'vaccine',
                        component: Vaccine,
                      },
                    ]
                  },
                ]
              },
              {
                name: 'CrewCer',
                path: 'cer',
                component: Cer
              },
              {
                name: 'Issues',
                path: 'issues',
                component: Issue,
                redirect: 'issues/medical',
                children: [
                  {
                    name: 'Medicals',
                    path: 'medical/',
                    component: Medicals,
                  },
                  {
                    name: 'Pandi',
                    path: 'pandi/',
                    component: Pandi,
                  },
                ]
              }
            ]
          }
        ]
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(function (to, from, next) {

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem(TOKEN_NAME) != null) {
      next();
    } else {
      next('/');
    }
  } else if (to.matched.some(record => !record.meta.requiresAuth)) {
    if (localStorage.getItem(TOKEN_NAME) != null && localStorage.getItem(USER_DETAILS) != null) {
      const userInfo = JSON.parse(localStorage.getItem(USER_DETAILS));
      var _next = userInfo.vessel_id ? { name: 'MasterCiAir',query:{type:'air',t:new Date().getTime()} } : { name: 'Dashboard' };
      next(_next);
    } else {
      next();
    }
  }
})

export default router
