<template>
  <div>
    <h4 class="font-weight-bold">SISTER SHIPS</h4>
    <button class="mb-2 btn btn-success btn-sm" data-target="#shipGroupModal" data-toggle="modal">
      <font-awesome-icon icon="plus" class="force-white-all"/>
      ADD GROUP
    </button>
    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-12 col-xl-3" v-for="sisterGroup in sisterGroups">
        <div class="card mb-2 p-2">
          <div>
            <div class="row align-items-center">
              <div class="col-6">
                <h6 class="card-title p-2 font-weight-bold mb-0">{{ sisterGroup.name ? sisterGroup.name : '' }}</h6>
              </div>
              <div class="col-6 text-right">
                <button class="btn-xs btn btn-warning mr-2" title="EDIT GROUP"
                        data-target="#editShipGroupModal"
                        data-toggle="modal"
                        @click="editSisterShipGroup(sisterGroup)">
                  <font-awesome-icon icon="pen" class="force-white-all"/>
                </button>
                <button class="btn-xs btn btn-danger" title="DELETE GROUP" @click="deleteGroup(sisterGroup.id)">
                  <font-awesome-icon icon="trash" class="force-white-all"/>
                </button>
              </div>
            </div>

          </div>
          <div class="card-body p-1">
            <p class="mb-0">Eldest Sister Ship:</p>
            <div class="mb-2">
              <table>
                <tr v-for="eldest in sisterGroup.vessels.filter(vessel=>vessel.is_eldest)">
                  <td style="font-weight: bold">{{ eldest.name }}</td>
                </tr>
              </table>
            </div>
            <p class="mb-0">Related Ships:</p>
            <table>
              <tr v-for="siblings in sisterGroup.vessels.filter(vessel=>!vessel.is_eldest)">
                <td style="font-weight: bold">{{ siblings.name }}</td>
              </tr>
            </table>
          </div>

        </div>
      </div>
    </div>
    <div class="modal fade" id="shipGroupModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title font-weight-bold" id="exampleModalLabel">Add Sister Ship Group</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="addNewVesselGroup">
              <div class="form-group">
                <label for="group" class="col-form-label">Group:</label>
                <input type="text" name="group" v-model="group" id="group" class="form-control" >
                <div v-if="errors.name">
                  <span class="text-danger font-weight-bold">{{errors.name[0]}}</span>
                </div>
              </div>
              <div class="text-right">
                <button type="submit" class="btn btn-success btn-sm">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="editShipGroupModal" tabindex="-1" aria-labelledby="editShipGroupModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title font-weight-bold" id="editShipGroupModalLabel">Edit Ship Group</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="inputEmail4" class="font-weight-bold">Group Name:</label>
                <input type="text" name="group" placeholder="Enter Group Name" v-model="editSisterShip.group" class="form-control form-control-sm">
              </div>
              <div class="form-group col-md-12">
                <label for="inputEmail4" class="font-weight-bold">ELDEST SISTER SHIP:</label>
                <select name="" id="" class="form-control form-control-sm" v-model="editSisterShip.eldest_ship_id" @change="modifyRelatedShips">
                  <option :value="null">--- SELECT VESSEL ---</option>
                  <option :value="vessel.id" v-for="vessel in vesselKeys.filter(vessel=>!selectedVessels.includes(vessel.id))">
                    {{vessel.name.toUpperCase()}}
                  </option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <p class="font-weight-bold">RELATED SHIPS:</p>
              </div>
              <div class="col-3" v-for="vessel in vesselKeys">
                <template v-if="editSisterShip.eldest_ship_id === vessel.id || selectedVessels.includes(vessel.id)">
                  <font-awesome-icon icon="ban" v-if="selectedVessels.includes(vessel.id)" size="lg" class="force-danger-all" title="SELECTED FROM THE OTHER GROUP"></font-awesome-icon>
                  <font-awesome-icon icon="check-double" v-else size="lg" class="force-success-all" title="SELECTED AS ELDEST SHIP IN THIS GROUP"></font-awesome-icon>
                </template>
                <template v-else>
                  <input type="checkbox" class="eldest-sister"
                         v-model="editSisterShip.relatedShips"
                         :value="vessel.id">
                </template>
                    {{vessel.name.toUpperCase()}}
              </div>
              <div class="col-12 text-right" >
                <button class="btn btn-sm btn-success" @click.prevent="updateVesselGroup"><font-awesome-icon icon="save"  class="force-white-all"/> SAVE</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {AlertService} from "@/services/AlertService";

export default {
  name: "SisterShip",
  data(){
    return {
      group:'',
      errors:'',
      editSisterShip:{
        id:null,
        eldest_ship_id:null,
        group:'',
        relatedShips:{},
      },
      selectedVessels:[],
    }
  },
  computed: {
    ...mapGetters([
      'sisterGroups',
      'vesselKeys'
    ]),
    // defaultGroupName(){
    //   return 'GROUP ' + (this.sisterGroups.length + 1)
    // }
  },
  created() {
    this.getSisterGroups();
    this.getVesselKeys();
  },
  methods: {
    ...mapActions(['getSisterGroups','addSisterGroup','deleteSisterGroup','getVesselKeys','updateSisterGroup']),
    async addNewVesselGroup(){
      if(await AlertService.confirmCreateAlert('Do you want to Add this Group to the List?')){
        // this.group ? this.group : this.defaultGroupName
        const params= {
          name : this.group
        }
        const response = await this.addSisterGroup(params);
        if(!response.errors) {
          AlertService.successAlert('A New Group has been Added Successfully!','ADD NEW GROUP');
          $('#shipGroupModal').modal('hide');
        }
        else{
          this.errors=response.errors;
        }
      }
    },
    async deleteGroup(group){
      if(await AlertService.confirmDeleteAlert('DELETION OF GROUP','Do you want to Delete this Group and its Members?','Yes, Proceed!')){
        const response=await this.deleteSisterGroup({id:group});
        if(response.success){
          AlertService.successAlert('Group has been Deleted Successfully!','DELETE GROUP')
        }
      }
    },
    async editSisterShipGroup(sisterShipGroup){
        const id=[];
        const container=this.sisterGroups.filter(grp=>grp.id!==sisterShipGroup.id).map(group=>{
          group.vessels.map(vessels=>{
            id.push(vessels.id)
          })
        });
        this.selectedVessels=id;
        const eldest=sisterShipGroup.vessels.filter(vessel=>vessel.is_eldest);
        this.editSisterShip.id=sisterShipGroup.id
        this.editSisterShip.group=sisterShipGroup.name
        this.editSisterShip.eldest_ship_id=eldest.length > 0  ? eldest[0].id : null;
        this.editSisterShip.relatedShips=sisterShipGroup.vessels.filter(vessel=>!vessel.is_eldest).map(vessel=>{
          return vessel.id
        });
    },

    async updateVesselGroup(){
      if(await AlertService.confirmUpdateAlert('Do you want to Update this Group? ')){
        const response=this.updateSisterGroup(this.editSisterShip);
        if(response){
          AlertService.successAlert('Vessel Group has been Updated Successfully!','Update Vessel Group');
          $('#editShipGroupModal').modal('hide');
        }
      }
    },
    modifyRelatedShips(){
      this.editSisterShip.relatedShips= this.editSisterShip.relatedShips.filter(id=>id!==this.editSisterShip.eldest_ship_id);
    }
  }
}
</script>

<style scoped>
.eldest-sister[type="checkbox"]{
  background-color: red !important;
}
  .eldest-sister[type="checkbox"]:disabled{
    background-color: red !important;
    border-color: red !important;
  }
</style>
