import {KeyService} from "@/services/KeyService";
import {RelatedPartyService} from "@/services/RelatedPartyService";

const state = {
  relatedParties: [],
  relatedPartyVessels: [],
  relatedPartyLists : [],
}

const mutations = {
  setRelatedParties: (state, relatedParties) => state.relatedParties = relatedParties,
  setRelatedPartyVessels: (state, relatedPartyVessels) => state.relatedPartyVessels = relatedPartyVessels,
  setRelatedPartiesList: (state, relatedPartyLists) => state.relatedPartyLists = relatedPartyLists
}

const actions = {
  async getRelatedParties({commit}){
    const relatedParties = await KeyService.getRelatedParties()
    if (relatedParties) commit('setRelatedParties',relatedParties)
  },
  async getRelatedPartyVessels({commit}, params){
    const vesselIds = await RelatedPartyService.getRelatedPartyVesselIds(params)
    if (vesselIds) commit('setRelatedPartyVessels', vesselIds)
  },
  async getRelatedPartiesList({commit}){
    const relatedPartiesList = await RelatedPartyService.getRelatedPartyLists()
    if (relatedPartiesList) commit('setRelatedPartiesList', relatedPartiesList)
  }
}

const getters = {
  relatedParties: state => state.relatedParties,
  relatedPartyVessels: state => state.relatedPartyVessels,
  relatedPartyLists : state => state.relatedPartyLists
}

export default {
  state,
  mutations,
  actions,
  getters
}
