import {KeyService} from "@/services/KeyService";

const state={
  medicalTypeKeys:[],
}
const getters={
  medicalTypeKeys: (state)=>state.medicalTypeKeys
}

const actions={
  async getMedicalTypeKeys({commit}){
    const response=await KeyService.getMedicalTypeKeys();
    if(response){
      commit('SET_MEDICAL_TYPE_KEYS',response)
    }
    return false;
  },
}

const mutations={
  SET_MEDICAL_TYPE_KEYS:(state,params)=>{
    state.medicalTypeKeys=params;
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
