export const UrlService = {
  setQueryString (parameters) {
    const queryString = []
    if (parameters) {
      Object.keys(parameters).forEach((key) => {
        if (parameters[key] || parameters[key] === 0) queryString.push(`${key}=${parameters[key]}`)
      })

      return queryString.length ? '?' + queryString.join('&') : ''
    }
    return ''
  }
}

export default { UrlService }
