import { KeyService } from '../../services/KeyService'

const state = {
  eventOneKeys: [],
  eventTwoKeys: [],
  eventThreeKeys: [],
  eventFourKeys: [],
  eventFiveKeys: [],
  eventImpactKeys: []
}

const mutations = {
  // eslint-disable-next-line no-return-assign
  setEventOneKeys: (state, eventOneKeys) => state.eventOneKeys = eventOneKeys,
  // eslint-disable-next-line no-return-assign
  setEventTwoKeys: (state, eventTwoKeys) => state.eventTwoKeys = eventTwoKeys,
  // eslint-disable-next-line no-return-assign
  setEventThreeKeys: (state, eventThreeKeys) => state.eventThreeKeys = eventThreeKeys,
  // eslint-disable-next-line no-return-assign
  setEventFourKeys: (state, eventFourKeys) => state.eventFourKeys = eventFourKeys,
  // eslint-disable-next-line no-return-assign
  setEventFiveKeys: (state, eventFiveKeys) => state.eventFiveKeys = eventFiveKeys,
  // eslint-disable-next-line no-return-assign
  setEventImpactKeys: (state, eventImpactKeys) => state.eventImpactKeys = eventImpactKeys
}

const actions = {
  async getEventOneKeys ({ commit }) {
    const eventOneKeys = await KeyService.getEventOneKeys()
    if (eventOneKeys) commit('setEventOneKeys', eventOneKeys)
  },
  async getEventTwoKeys ({ commit }, params) {
    const eventTwoKeys = await KeyService.getEventTwoKeys(params)
    // console.log(eventTwoKeys)
    if (eventTwoKeys) commit('setEventTwoKeys', eventTwoKeys)
  },
  async getEventThreeKeys ({ commit }, params) {
    const eventThreeKeys = await KeyService.getEventThreeKeys(params)
    // console.log(eventThreeKeys)
    if (eventThreeKeys) commit('setEventThreeKeys', eventThreeKeys)
  },
  async getEventFourKeys ({ commit }, params) {
    const eventFourKeys = await KeyService.getEventFourKeys(params)
    // console.log(eventFourKeys)
    if (eventFourKeys) commit('setEventFourKeys', eventFourKeys)
  },
  async getEventFiveKeys ({ commit }, params) {
    const eventFiveKeys = await KeyService.getEventFiveKeys(params)
    // console.log(eventFiveKeys)
    if (eventFiveKeys) commit('setEventFiveKeys', eventFiveKeys)
  },
  async getEventImpactKeys({ commit }) {
    const eventImpactKeys = await KeyService.getEventClassImpactKeys()
    // console.log(eventImpactKeys)
    if (eventImpactKeys) commit('setEventImpactKeys', eventImpactKeys)
  }
}

const getters = {
  eventOneKeys: state => state.eventOneKeys,
  eventTwoKeys: state => state.eventTwoKeys,
  eventThreeKeys: state => state.eventThreeKeys,
  eventFourKeys: state => state.eventFourKeys,
  eventFiveKeys: state => state.eventFiveKeys,
  eventImpactKeys: state => state.eventImpactKeys
}

export default {
  state,
  mutations,
  actions,
  getters
}
