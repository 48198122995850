import store from '@/store';
import {AlertService} from "@/services/AlertService";

export default {
  data() {
    return {
      company_id       : null,
      audit_record_id : null,
      audit_type      : null,
      ncr_id          : null,
      oe_id           : null
    }
  },
  methods: {
    async initialize(params) {
      this.company_id = params.company_id ?? null;
      this.company_audit_record_id = params.id ?? null;
      this.audit_type = params.type ?? null;
      this.ncr_id = params.ncr_id ?? null;
      this.oe_id = params.oe_id ?? null;

      if (this.company_id !== null) {
        await store.dispatch('getCompanyDetail', params.company_id)
      }

      if (this.company_audit_record_id !== null) {
        await store.dispatch('getCompanyAuditRecordById', params.id);
      }
    },
    routeUnderAudit(routeName) {
      const routeNames = [
        'CompanyInternalAuditReport',
        'CompanyNonConformity',
        'CompanyIaCorrectiveActionReport',
        'CompanyIAShipParticular'
      ];

      return routeNames.includes(routeName);
    },
    async initializeNcr(routeName) {
      if ((this.ncr_id !== null && store.getters.selectedCompanyAuditRecord[this.audit_type] !== null) || this.routeUnderAudit(routeName) === true) {
          await store.dispatch('getCompanyRootCausesList', this.audit_type);
          await store.dispatch('getCompanyNonConformities', {
            id   : store.getters.selectedCompanyAuditRecord[this.audit_type].id,
            type : this.audit_type
          });
      }
    },
    async validateCompanyById() {
      return !(this.company_id !== null && !(Object.keys(store.getters.companyDetail).length > 0));
    },
    async validateAuditReportById() {
      return !(this.audit_record_id !== null && store.getters.selectedCompanyAuditRecord[this.audit_type] === null);
    },
    async validateSelectedAuditType() {
      const auditTypes = ['sms'];
      return !(this.audit_type !== null && (auditTypes.includes(this.audit_type) === false || store.getters.selectedCompanyAuditRecord[this.audit_type] === null));
    },
    async validateNonConformityById() {
      const invalidValueToRequestNcr = [undefined, 'index'];
      if (this.ncr_id !== null && invalidValueToRequestNcr.includes(this.ncr_id) === false) {
        const selectedNcrIndex = store.getters.companyNonConformities[this.audit_type].findIndex(ncr => ncr.id === parseInt(this.ncr_id));
        if (selectedNcrIndex === -1) {
          return false;
        }
      }
      return true;
    },
    async validateObjectiveEvidenceById() {
      const invalidOeParams = [undefined, 'index'];
      if (this.oe_id !== null && invalidOeParams.includes(this.oe_id) === false) {
        const selectedNcrIndex = store.getters.companyNonConformities[this.audit_type].findIndex(ncr => ncr.id === parseInt(this.ncr_id));
        const selectedOeIndex = store.getters.companyNonConformities[this.audit_type][selectedNcrIndex].objective_evidences.findIndex(oe => oe.id === parseInt(this.oe_id));
        if (selectedOeIndex === -1) {
          return false;
        }
      }
      return true;
    },
    async redirectToCompanyList(next) {
      await AlertService.errorAlert('The Company Audit Record Link you are trying to access is invalid!', 'ACCESSING COMPANY AUDIT REPORT');
      await next({name: 'CompanyList'});
    }
  },
}
