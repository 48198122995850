'use strict'



const Psc = () => import('@/views/Psc')
const PscIndex = () => import('@/components/modules/psc/PscIndex')
const PscMain = () => import('@/components/modules/psc/PscMain')
const PscGeneral = () => import('@/components/modules/psc/PscGeneral')
const PscInspection = () => import('@/components/modules/psc/PscInspection')
const PscDeficiency = () => import('@/components/modules/psc/PscDeficiency')
const PscOccasionalSurvey = () => import('@/components/modules/psc/PscOccasionalSurvey')
const PscQuestionnaire = () => import('@/components/modules/psc/PscQuestionnaire')
const ShipParticular = () => import('@/components/modules/accident-incident/ShipParticular');
const PscDeficiencyDetail = () => import('@/components/modules/psc/deficiency/PscDeficiencyDetail');
const PscDeficiencyCause = () => import('@/components/modules/psc/deficiency/PscDeficiencyCause');
const PscDeficiencyCorrectiveAction = () => import('@/components/modules/psc/deficiency/PscDeficiencyCorrectiveAction');
const PscDeficiencyPreventativeMeasure = () => import('@/components/modules/psc/deficiency/PscDeficiencyPreventativeMeasure');

const PscCompanyInstruction = () => import('@/components/modules/psc/PscCompanyInstruction')

const PscDeficiencyCompanyInstruction=()=> import("@/components/modules/psc/deficiency/PscDeficiencyCompanyInstruction");
const PscMonthlyCommittee = () => import('../components/dashboard/psc/PscMonthlyCommittee.vue')
const PscInspectionHistory = () => import('../components/modules/psc/PscInspectionHistory.vue')


export const RoutePsc = {
  path: 'psc/',
  name: 'Psc',
  redirect: 'psc/index',
  component: Psc,
  children: [
    {
      path: 'index/',
      name: 'PscIndex',
      component: PscIndex,
    },
    {
      // duplicate name 'PscMonthlyCommittee' in router/index.js
      name:'iPscMonthlyCommittee',
      path:'monthly-committee/',
      component:PscMonthlyCommittee
    },
    {
      path: ':id/',
      name: 'PscMain',
      component: PscMain,
      redirect: ':id/general',
      children: [
        {
          path: 'general/',
          name: 'PscGeneral',
          component: PscGeneral,
        },
        {
          path: 'inspection/',
          name: 'PscInspection',
          component: PscInspection,
        },
        {
          path: 'questionnaire/',
          name: 'PscQuestionnaire',
          component: PscQuestionnaire,
        },
        {
          'path':'company-instruction/',
          'name':'PscCompanyInstruction',
          'component':PscCompanyInstruction,
        },
        {
          path: 'deficiency/',
          name: 'PscDeficiency',
          component: PscDeficiency,
          children:[
            {
              'path':'detail/',
              'name':'PscDeficiencyDetail',
              'component':PscDeficiencyDetail,
            },
            {
              'path':'cause/',
              'name':'PscDeficiencyCause',
              'component':PscDeficiencyCause,
            },
            {
              'path':'corrective-action/',
              'name':'PscDeficiencyCorrectiveAction',
              'component':PscDeficiencyCorrectiveAction,
            },
            {
              'path':'preventative-measure/',
              'name':'PscDeficiencyPreventativeMeasure',
              'component':PscDeficiencyPreventativeMeasure,
            },
            {
              'path':'company-instruction/',
              'name':'PscDeficiencyCompanyInstruction',
              'component':PscDeficiencyCompanyInstruction,
            },
          ],
        },
        {
          path : 'occasional-survey',
          name : 'PscOccasionalSurvey',
          component : PscOccasionalSurvey
        },
        {
          path: 'ship-particular/:vesselId',
          name: 'PscShipParticular',
          component: ShipParticular,
        },
        {
          path: 'psc-inspection-history/:vesselId',
          name: 'PscInspectionHistory',
          component: PscInspectionHistory,
        },
      ]
    },
  ]
};
