import {KeyService} from "@/services/KeyService";

const state={
  ailmentReasonKeys:[],
}
const getters={
  ailmentReasonKeys: (state)=>state.ailmentReasonKeys
}

const actions={
  async getAilmentReasonKeys({commit}){
    const response=await KeyService.getAilmentReasonKeys();
    if(response){
      commit('SET_AILMENT_REASON_KEYS',response)
    }
    return false;
  },
}

const mutations={
  SET_AILMENT_REASON_KEYS:(state,params)=>{
    state.ailmentReasonKeys=params;
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
