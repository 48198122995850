import {SisterShipService} from "@/services/SisterShipService";
import sisterShip from "@/components/settings/SisterShip";

const state={
  sisterGroups:[],
  sisterVessels: []
}

const mutations={
  SET_SISTER_GROUPS:(state,groups)=>{
    state.sisterGroups=groups
  },
  ADD_SISTER_GROUP:(state,group)=>{
    state.sisterGroups.push(group)
  },
  DELETE_SISTER_GROUP:(state,groupId)=>{
    state.sisterGroups.splice(state.sisterGroups.findIndex(grp=>grp.id===groupId),1);
  },
  UPDATE_SISTER_GROUP:(state,group)=>{
    state.sisterGroups.splice(state.sisterGroups.findIndex(grp=>grp.id===group.id),1,group);
  },
  SET_SISTER_VESSELS:(state, vessels)=>{
    state.sisterVessels = vessels
  },
}
const actions ={
  async getSisterGroups({commit}){
    const response= await SisterShipService.getSisterGroups();
    if(response){
      commit('SET_SISTER_GROUPS',response ?? null);
    }
  },
  async addSisterGroup({commit},params){
    const response= await SisterShipService.addSisterGroup(params);
    if(!response.errors){
      commit('ADD_SISTER_GROUP',response ?? null);
    }
    return response;
  },

  async deleteSisterGroup({commit},params){
    const response=await SisterShipService.deleteSisterGroup(params);
    if(response.success){
      commit('DELETE_SISTER_GROUP',params.id ?? null);
    }
    return response;
  },

  async updateSisterGroup({commit},params){
    const response=await SisterShipService.updateSisterGroup(params);
    if(response){
      commit('UPDATE_SISTER_GROUP',response);
    }
    return response;
  },

  async getSisterVessels({commit},params){
    const response= await SisterShipService.getSisterVesselInfo(params);
    if(response){
      commit('SET_SISTER_VESSELS',response ?? null);
    }
  },


}

const getters={
  sisterGroups:(state)=>state.sisterGroups,
  sisterVessels:(state)=> state.sisterVessels
}


export default {
  actions,
  getters,
  mutations,
  state
}
