import axios from "axios";
import {GlobalService} from "@/services/GlobalService";
import {AIR_CHARTS, AIR_REPORT_STATUS_COUNTS} from "@/services/ConstantService";
import {UrlService} from "./UrlService";

export const DashboardService= {
  async getAirReportStatusCounts(params) {
    let queryString=UrlService.setQueryString(params)
    return await axios.get(AIR_REPORT_STATUS_COUNTS + queryString).then(res => {
      return res.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET AIR REPORT STATUS COUNT SERVICE')
      return false
    })
  },
  async getAccidentCharts(params){
    let queryString=UrlService.setQueryString(params);
    return await axios.get(AIR_CHARTS + queryString).then(res => {
      return res.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET AIR CHART SERVICE')
      return false
    })
  }
}
