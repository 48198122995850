import {mapActions, mapGetters} from "vuex";
import ObjectMixin from "./ObjectMixin";
import {FileService} from "../services/FileService";
import {AlertService} from "../services/AlertService";

export default {
  mixins: [ObjectMixin],
  data() {
    return {
      params: {
        id: null,
        type: {
          id: null,
          type_id: 1
        },
      }
    }
  },
  computed: {
    ...mapGetters(['pscReport', 'rsReport'])
  },
  methods: {
    ...mapActions([
      'getPscAttachments', 'viewPscAttachment','deletePscAttachment','downloadPscAttachments', 'getPscReport',
      'getRsAttachments', 'viewRsAttachment','deleteRsAttachment','downloadRsAttachments',
    ]),

    /*** START PSC REPORT **/
    async getAttachments(type, referenceObj, key) {
      this.params.id = parseInt(this.$route.params.id);
      this.params.type.id = this.getByKey(referenceObj, key)
      this.params.type.type_id = type
      const response = await this.getPscAttachments(this.params);

      await this.getPscReport({id: this.params.id});

      if (response) return response;
      return [];
    },
    async viewDownloadFile(attachment, type_id,action='view',ref_id=null) {
      swal.fire({
        title: 'Please wait',
      });
      this.params.id = parseInt(this.$route?.params?.id ?? ref_id ?? null );
      this.params.type.id = attachment.id
      this.params.type.type_id = type_id
      swal.showLoading();
      const response = await this.viewPscAttachment(this.params)
      if (response) {
        if ((attachment.extension === 'pdf' || attachment.mime_type.includes("image")) && action == 'view') {
          await FileService.viewDocument(response, attachment, null)
        } else {
          await FileService.downloadDocument(response, attachment, 'name');
        }
        swal.close();
      }
    },
    async deleteFilePsc(attachment, type) {
      if (await AlertService.confirmDeleteAlert(null, 'Are you sure you want to Delete this Attachment?', 'Yes, Delete Attachment!')) {
        swal.fire({
          title: 'Deleting Attachment Please Wait...',
        });
        swal.showLoading()
        this.params.id = parseInt(this.$route.params.id);
        this.params.type.id = attachment.id
        this.params.type.type_id = type
        const response = await this.deletePscAttachment(this.params);
        if (response) {
          return true;
        }
        return false;
      }
    },
    async downloadAll(type,referenceObj,key){
      swal.fire({
        title: 'Please wait...',
      });
      swal.showLoading()
      this.params.id = this.$route.params.id
      this.params.type.id = this.getByKey(referenceObj,key)
      this.params.type.type_id=type
      const response = await this.downloadPscAttachments(this.params);
      if(response){
        let fileBlob=FileService.base64FileToBlob(response.file,response.mime_type);
        let url = window.URL.createObjectURL(fileBlob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', response.file_name);
        document.body.appendChild(link);
        link.click();
      }
      swal.close();
    },
    /*** END PSC REPORT **/

    /*** START RS REPORT **/
    async downloadAllRs(type,referenceObj,key){
      swal.fire({
        title: 'Please wait...',
      });
      swal.showLoading()
      this.params.id = this.$route.params.id
      this.params.type.id = this.getByKey(referenceObj,key)
      this.params.type.type_id=type
      const response = await this.downloadRsAttachments(this.params);
      if(response){
        let fileBlob=FileService.base64FileToBlob(response.file,response.mime_type);
        let url = window.URL.createObjectURL(fileBlob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', response.file_name);
        document.body.appendChild(link);
        link.click();
      }
      swal.close();
    },
    async deleteFileRs(attachment, type) {
      if (await AlertService.confirmDeleteAlert(null, 'Are you sure you want to delete this attachment?', 'Yes, delete attachment')) {
        swal.fire({
          title: 'Deleting attachment please wait...',
        });
        swal.showLoading();
        this.params.id = parseInt(this.$route.params.id);
        this.params.type.id = attachment.id;
        this.params.type.type_id = type;
        const response = await this.deleteRsAttachment(this.params);
        return (response);
      }
    },
    async viewRsDownloadFile(attachment, type_id,action='view',ref_id=null) {
      swal.fire({
        title: 'Please wait',
      });
      this.params.id = parseInt(this.$route?.params?.id ?? ref_id ?? null );
      this.params.type.id = attachment.id
      this.params.type.type_id = type_id
      swal.showLoading();
      const response = await this.viewRsAttachment(this.params)
      if (response) {
        if ((attachment.extension === 'pdf' || attachment.mime_type.includes("image")) && action == 'view') {
          await FileService.viewDocument(response, attachment, null)
        } else {
          await FileService.downloadDocument(response, attachment, 'name');
        }
        swal.close();
      }
    },
    async getRsAttachment(type, referenceObj, key) {
      this.params.id = parseInt(this.$route.params.id);
      this.params.type.id = this.getByKey(referenceObj, key)
      this.params.type.type_id = type
      const response = await this.getRsAttachments(this.params);
      if (response) return response;
      return [];
    },
    /*** END RS REPORT **/
  },
}
