import { KeyService } from '@/services/KeyService'

/** state **/
const state = {
  countryKeys: []
}

/** mutations **/
const mutations = {
  // eslint-disable-next-line no-return-assign
  setCountryKeys: (state, countryKeys) => state.countryKeys = countryKeys
}

/** actions **/
const actions = {
  async getCountryListKeys ({ commit }) {
    const countryKeys = await KeyService.getCountryKeys()
    if (countryKeys) commit('setCountryKeys', countryKeys)
  }
}

/** getters **/
const getters = {
  countryKeys: state => state.countryKeys
}

/** export **/
export default { state, actions, mutations, getters }
