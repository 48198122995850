import {GlobalService} from "../../services/GlobalService";
import {RS_ACTION_CODES, RS_CODES, RS_INDEX, RSNC, RSNC_QUESTION_KEYS} from "../../services/ConstantService";
import axios from "axios";
import * as querystring from "querystring";
import {UrlService} from "@/services/UrlService";

const state = {
  rsKeys: [],
  rsncQuestionKeys:[],
  rsNonConformities:[],
  rsReport: {},
  rsAttachments: [],
  rsncBeforeRecAttachments: [],
  rsncInstructionAttachments: [],
  rsncRectifiedPhotoAttachments: [],
  rsncRectifiedAttachments: [],
  nonConformity:{},
  editNonConformity:false,
}

const mutations = {
  SET_RS_KEYS: (state, rsKeys) => state.rsKeys = rsKeys,
  SET_RS_REPORT: (state, rsReport) => state.rsReport = rsReport,
  SET_RS_ATTACHMENTS: (state, response) => {
    switch (response.type_id) {
      case 2:
        state.rsncBeforeRecAttachments = response?.attachments ?? []
        break;
      case 3:
        state.rsncInstructionAttachments = response?.attachments ?? []
        break;
      case 4:
        state.rsncRectifiedPhotoAttachments = response?.attachments ?? []
        break;
      case 5:
        state.rsncRectifiedAttachments = response?.attachments ?? []
        break;
      default:
        state.rsAttachments = response?.attachments ?? []
        break;
    }
  },

  SET_RS_QUESTION_KEYS:(state,rsncQuestionKeys)=>state.rsncQuestionKeys=rsncQuestionKeys,
  SET_NON_CONFORMITIES:(state,rsNonConformities)=>state.rsNonConformities=rsNonConformities,
  SET_NON_CONFORMITY:(state,nonConformity)=>{
    const index=state.rsNonConformities.findIndex(item=>item.id === nonConformity.id)
    if (index >= 0) state.rsNonConformities.splice(index,1,nonConformity)
    state.nonConformity=Object.assign({},nonConformity)
  },
  SET_EDIT_NON_CONFORMITY:(state,editNonConformity)=>state.editNonConformity = editNonConformity
}

const actions = {
  async getRsReports({commit}, params) {
    const response = await GlobalService.ajax('get', RS_INDEX, {params});
    const data = response?.data;

    commit('setReports', data?.data)
    commit('setPagination', data?.meta)
  },

  async createRsReport({commit}, params) {
    return await GlobalService.ajax('post', RS_INDEX, params);
  },

  async getRsReport({commit}, params = {}) {
    params = {_source: 'Rightship Report', ...params};

    const uri = [RS_INDEX, params.id].join('/');
    const response = await GlobalService.ajax('get', uri, params);

    if (response.status === 200) {
      commit('SET_RS_REPORT', response.data ?? {});
    }

    return response;
  },

  async updateRsInfo({commit}, params = {}) {
    const return_response = (params && params.hasOwnProperty('__return_response')) ? params.__return_response : false;
    delete params['__return_response'];

    const uri = [RS_INDEX, params.id].join('/');
    const response = await GlobalService.ajax('put', uri, params);

    if (response.hasOwnProperty('status') && response.status === 200) {
      commit('SET_RS_REPORT', response?.data ?? {});

      return return_response ? response : (response?.data ?? false);
    }

    return return_response ? response : false;
  },

  async uploadRsAttachments({commit}, params) {
    const id = params instanceof FormData ? params.get('id') : params?.id ?? null
    const uri = [RS_INDEX, id, 'upload'].join('/');
    const response = await GlobalService.ajax('post', uri, params);
    if (response.hasOwnProperty('status') && response.status === 200) {
      return response?.data ?? false;
    }

    return false;
  },

  async getRsAttachments({commit}, params) {
    commit('SET_RS_ATTACHMENTS', {attachments: [], type_id: params.type.type_id})
    const uri = [RS_INDEX, params.id, 'attachments'].join('/');
    const response = await GlobalService.ajax('post', uri, params);
    if (response.hasOwnProperty('status') && response.status === 200) {
      commit('SET_RS_ATTACHMENTS', {attachments: response?.data ?? [], type_id: params.type.type_id});
      return response?.data ?? false;
    }

    return false;
  },

  async viewRsAttachment({commit}, params) {
    const uri = [RS_INDEX, params.id, 'download'].join('/');
    const response = await axios({
      responseType: "blob",
      method: 'post',
      url: uri,
      data: {...params}
    });
    if (response.hasOwnProperty('status') && response.status === 200) {
      return response?.data ?? false;
    }

    return false;
  },

  async downloadRsAttachments({commit}, params) {
    const uri = [RS_INDEX, params.id, 'download-all'].join('/');
    const response = await axios({
      method: 'post',
      url: uri,
      data: {...params}
    });

    if (response.hasOwnProperty('status') && response.status === 200) {
      return response?.data ?? false;
    }

    return false;
  },

  async deleteRsAttachment({commit}, params) {
    const uri = [RS_INDEX, params.id, 'delete-attachment'].join('/');
    const response = await axios({
      method: 'post',
      url: uri,
      data: {...params}
    });

    if (response.hasOwnProperty('status') && response.status === 200) {
      return response?.data ?? false;
    }

    return false;
  },

  async deleteRs({commit}, params) {
    const uri = [RS_INDEX, 'delete'].join('/');
    const response = await GlobalService.ajax('post', uri, params)
    if (response.hasOwnProperty('status') && response.status === 200) {
      return response?.data ?? false;
    }

    return false;
  },

  async getRsncQuestionKeys({commit},params){
    const response = await GlobalService.ajax('get', RSNC_QUESTION_KEYS, {params})
    if (response.hasOwnProperty('status') && response.status === 200) {
      commit('SET_RS_QUESTION_KEYS', response.data ?? {});
      return response?.data ?? false;
    }

    return false;
  },

  async getRsNonConformities({commit},params){
    const queryString=UrlService.setQueryString(params);
    const response = await GlobalService.ajax('get', RSNC+queryString)
    if (response.hasOwnProperty('status') && response.status === 200) {
      commit('SET_NON_CONFORMITIES', response?.data?.data ?? {});
      commit('setPagination',response?.data ?? {})
      return response?.data ?? false;
    }

    return false;
  },

  async saveRsNonConformity({commit},params){
    const response = await GlobalService.ajax('post', RSNC,params)
    if (response.hasOwnProperty('status') && response.status === 200) {
      return response?.data ?? false;
    }

    return false;
  },

  async updateRsNonConformity({commit},params){
    const uri = [RSNC, params.id,'update'].join('/');
    const response = await GlobalService.ajax('post', uri,params)
    if (response.hasOwnProperty('status') && response.status === 200) {
      commit('SET_NON_CONFORMITY',response?.data ?? {})
      localStorage.setItem('currentNonConformity',JSON.stringify(response?.data ?? {}))
      return response?.data ?? false;
    }

    return false;
  },

  async renderNonConformityAttachments({dispatch},nonConformity){
    [2,3,4,5].forEach(type=>{
      let params={
        id:nonConformity.id,
        type:{
          id:nonConformity.non_conformity_id,
          type_id:type
        }
      }
      dispatch('getRsAttachments',params)
    });

  },

  async deleteNonConformity({commit}, params) {
    const uri = [RSNC, params.id].join('/');
    const response = await GlobalService.ajax('delete', uri, {})
    if (response.hasOwnProperty('status') && response.status === 200) {
      return response?.data ?? false;
    }

    return false;
  },


}

const getters = {
  rsKeys: (state) => state.rsKeys,
  rsReport: (state) => state.rsReport,
  rsAttachments: (state) => state.rsAttachments,
  rsncBeforeRecAttachments: (state)=>state.rsncBeforeRecAttachments,
  rsncInstructionAttachments: (state)=>state.rsncInstructionAttachments,
  rsncRectifiedPhotoAttachments: (state)=>state.rsncRectifiedPhotoAttachments,
  rsncRectifiedAttachments:  (state)=>state.rsncRectifiedAttachments,
  rsncQuestionKeys:(state)=>state.rsncQuestionKeys,
  rsNonConformities:(state)=>state.rsNonConformities,
  nonConformity:(state)=>state.nonConformity,
  editNonConformity:(state)=>state.editNonConformity,
}

export default {
  state,
  actions,
  mutations,
  getters
}
