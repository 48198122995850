import axios from 'axios';
import {GlobalService} from "@/services/GlobalService";

import {
  GET_MANNING_KEYS,
} from "./ConstantService";


export const ManningService = {
  async getManningKeys() {
    return await axios.get(GET_MANNING_KEYS)
      .then(response => {
        // console.log(response.data.manningKeys);
        // console.log('dssd');
        return response.data.manningKeys ? response.data.manningKeys : false;
      })
      .catch(error => {
        GlobalService.handleError(error, 'GET MANNING KEYS SERVICE');
        return false;
      });
  },

};

