import { KeyService } from '../../services/KeyService'
import {CrewService} from "@/services/CrewService";
import seaExperience from "@/components/crew/experience/SeaExperience";

const state = {
  crewKeys: {},
  manningFromOnboard:0,
  manningCrewCount:{},
  crewInfo:{},
  crewActivities:{},
  seaExperience:{},
  outsideExperience:{},
  incidentBehavior: {},
  crewDocuments:{},
  companyEvaluations:{},
  medicalKeys:{},
}

const mutations = {
  // eslint-disable-next-line no-return-assign
  setCrewKeys: (state, crewKeys) => {
    if(crewKeys){
      state.crewKeys=Object.assign({},crewKeys)
    }
  },
  // setManningFromOnboard:(state,manning)=>state.manningFromOnboard=Object.keys(manning).map(manning=>{
  //   return manning.toUpperCase();
  // }),
  setManningFromOnboard:(state,manning)=>state.manningFromOnboard=manning,

  setManningCrewCount:(state,crewCount)=>{
    state.manningCrewCount=Object.keys(crewCount).map(manning=>({
      name: manning.toUpperCase(),
      count: crewCount[manning]
    }));
  },

  setCrewInfo:(state,crew)=>{
    state.crewInfo=Object.assign({},crew)
  },
  setCrewActivities:(state,crewActivities)=>{
    state.crewActivities=Object.assign({},crewActivities)
  },

  setSeaExperience:(state,seaExperience)=> {
    state.seaExperience=Object.assign({},seaExperience);
  },

  setOutsideExperience:(state,outsideExperience) =>{
    state.outsideExperience=Object.assign({},outsideExperience)
  },

  setIncidentBehavior:(state,incidentBehavior)=>{
    state.incidentBehavior=Object.assign({},incidentBehavior);
  },

  setCrewDocument:(state,crewDocuments)=>{
    state.crewDocuments=crewDocuments
  },

  setMedicalKeys:(state,medicalKeys)=>{
    state.medicalKeys=medicalKeys
  },

  setCompanyEvaluations:(state,companyEvaluations)=>{
    state.companyEvaluations=companyEvaluations
  },

}

const actions = {
  async getCrewKeys ({ commit }, params) {
    commit("setCrewKeys",{})
    commit('setManningFromOnboard', 0)
    commit('setManningCrewCount', {})
    const crewKeys = await KeyService.getCrewKeys(params)
    if (!crewKeys.errors) {
      commit('setCrewKeys', crewKeys.crews)
      commit('setManningFromOnboard',crewKeys.crewCount)
      commit('setManningCrewCount',crewKeys.manning)
    }
    return crewKeys;
  },
  async getCrewInfo({commit},params){
    commit('setCrewInfo',{});
    const response=await CrewService.getCrewInfoFromCMS(params);
    if(response){
      commit('setCrewInfo',response);
      return true;
    }
    return false;
  },
  async getCrewActivities({commit},params){
    commit('setCrewActivities',{});
    const response=await CrewService.getCrewActivitiesFromCMS(params);
    if(response){
      commit('setCrewActivities',response);
      return true;
    }
    return false;
  },

  async getSeaExperience({commit},params){
    commit('setSeaExperience',{});
    const response=await CrewService.getCrewSeaExperienceFromCms(params);
    if(response){
      commit('setSeaExperience',response);
      return true;
    }
    return false;
  },

  async getOutsideExperience({commit},params){
    commit('setOutsideExperience',{});
    const response=await CrewService.getCrewOutsideExperienceFromCms(params);
    if(response){
      commit('setOutsideExperience',response);
      return true;
    }
    return false;
  },

  async getIncindentBehavior({commit},params){
    commit('setIncidentBehavior',{});
    const response=await CrewService.getCrewIncidentBehaviorFromCms(params);
    if(response){
      commit('setIncidentBehavior',response);
      return true;
    }
    return false;
  },
  async getCrewDocuments({commit},params){
    commit('setCrewDocument',{});
    const response=await CrewService.getCrewDocumentFromCms(params);
    if(response){
      commit('setCrewDocument',response);
      return true;
    }
    return false;
  },

  async getFlagDocuments({commit},params){
    commit('setCrewDocument',{});
    const response=await CrewService.getCrewFlagDocuments(params);
    if(response){
      commit('setCrewDocument',response);
      return true;
    }
    return false;
  },

  async getAssessmentReport({commit},params){
    commit('setCrewDocument',{});
    const response=await CrewService.getCrewAssessmentsFromCms(params);
    if(response){
      commit('setCrewDocument',response);
      return true;
    }
    return false;
  },

  async getMedicalKeys({commit},params){
    commit('setMedicalKeys',{});
    const response=await CrewService.getMedicalKeysFromCms(params);
    if(response){
      commit('setMedicalKeys',response);
      return true;
    }
    return false;
  },

  async getCrewMedicals({commit},params){
    commit('setCrewDocument',{});
    const response=await CrewService.getCrewMedicalsFromCms(params);
    if(response){
      commit('setCrewDocument',response);
      return true;
    }
    return false;
  },
  async getCrewHealthDeclarations({commit},params){
    commit('setCrewDocument',{});
    const response=await CrewService.getCrewHealthDeclarationsFromCms(params);
    if(response){
      commit('setCrewDocument',response);
      return true;
    }
    return false;
  },
  async getCrewRtPcr({commit},params){
    commit('setCrewDocument',{});
    const response=await CrewService.getCrewRtPcrFromCms(params);
    if(response){
      commit('setCrewDocument',response);
      return true;
    }
    return false;
  },
  async getCrewVaccine({commit},params){
    commit('setCrewDocument',{});
    const response=await CrewService.getCrewVaccineFromCms(params);
    if(response){
      commit('setCrewDocument',response);
      return true;
    }
    return false;
  },

  async getCrewCers({commit},params){
    commit('setCrewDocument',{});
    const response=await CrewService.getCrewCers(params);
    if(response){
      commit('setCrewDocument',response);
      return true;
    }
    return false;
  },
  async getCompanyEvaluations({commit},params){
    commit('setCompanyEvaluations',{});
    const response=await CrewService.getCompanyEvaluations(params);
    if(response){
      commit('setCompanyEvaluations',response);
      return true;
    }
    return false;
  },
  async getMedicals({commit},params){
    commit('setCrewDocument',{});
    const response=await CrewService.getMedicalIssuesFromCms(params);
    if(response){
      commit('setCrewDocument',response);
      return true;
    }
    return false;
  },

  async getPandIs({commit},params){
    commit('setCrewDocument',{});
    const response=await CrewService.getPandIsFromCms(params);
    if(response){
      commit('setCrewDocument',response);
      return true;
    }
    return false;
  },
}

const getters = {
  crewKeys: state => state.crewKeys,
  manningFromOnboard: state=>state.manningFromOnboard,
  manningCrewCount: state=>state.manningCrewCount,
  crewInfo: state=>state.crewInfo,
  crewActivities: state=>state.crewActivities,
  seaExperience: state=>state.seaExperience,
  outsideExperience: state=>state.outsideExperience,
  incidentBehavior: state=>state.incidentBehavior,
  crewDocuments: state=>state.crewDocuments,
  medicalKeys:state=>state.medicalKeys,
  companyEvaluations:state=>state.companyEvaluations,
}

export default {
  state,
  mutations,
  actions,
  getters
}
