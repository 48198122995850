import axios from "axios";
import {GlobalService} from "@/services/GlobalService";
import {API_CURRENCY} from '@/services/ConstantService'

export const CurrencyService = {
  async getCurrencyKeys(params = null) {
    var queryString='';
    Object.keys(params).forEach((key,index)=>{
       if(index === 0) queryString+='?';
        queryString += `${key}=${params[key]}&`;
    });
    return await axios.get(API_CURRENCY + queryString)
      .then(response => (response.data ?? false))
      .catch(error => {
        GlobalService.handleError(error, 'getAllReport SERVICE')
        return false
      })
  },

  async create(currency) {
    return await currency.post(API_CURRENCY)
  },

  async update(currency) {
    return await currency.patch(`${API_CURRENCY}/${currency.id}`)
  },

  async delete(id) {
    return await axios.delete(`${API_CURRENCY}/${id}`)
  },

}

export default {CurrencyService}
