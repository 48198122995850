

export const FileService={
  base64FileToBlobUrl(base64File) {
    let blob = this.base64FileToBlob(base64File);
    return URL.createObjectURL(blob);
  },

  base64FileToBlob(base64File,mimeType=null,isImage=false) {
    base64File = base64File.replace('data:application/pdf;base64,', '');
    base64File = base64File.replace('data:image/png;base64,', '');
    base64File = base64File.replace('data:image/jpeg;base64,', '');
    const byteCharacters = atob(base64File);
    const byteArrays = [];
    let sliceSize = 512;
    let contentType = mimeType ?? 'application/pdf';
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, {type: contentType});
  },
  /* eslint-disable */
  viewDocument(blobFile,fileInfo) {
    let url = window.URL.createObjectURL(new Blob([blobFile],{type:fileInfo.mime_type}));
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
  },

  downloadDocument(blobFile,fileInfo,key='attachment_name') {
    let url = window.URL.createObjectURL(new Blob([blobFile],{type:fileInfo.mime_type}));
    const link = document.createElement('a');
    var fileName= fileInfo[key] + `${fileInfo['extension'] ? '.'+fileInfo['extension'] : ""}`
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  },

  getFileUrl(file){
    return this.base64FileToBlobUrl(file);
  },

  generateImageURl(base64File,extension){
    base64File = base64File.replace('data:application/pdf;base64,', '');
    base64File = base64File.replace('data:image/png;base64,', '');
    base64File = base64File.replace('data:image/jpeg;base64,', '');
    base64File = base64File.replace('data:image/jpg;base64,', '');
    base64File = base64File.replace('data:image/gif;base64,', '');
    const byteCharacters = atob(base64File);
    const byteArrays = [];
    let sliceSize = 512;
    let contentType = `image/${extension}`;
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, {type: contentType});
  },

  openImage(base64File,extension){
    const blob=this.generateImageURl(base64File,extension)
    window.open(URL.createObjectURL(blob),'_blank');
  },
  fileDownload(response, fileName) {
    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
    var fileLink = document.createElement('a');
    fileLink.href = fileURL;
    fileLink.setAttribute('download', fileName);
    document.body.appendChild(fileLink);
    fileLink.click();
    fileLink.remove();
  },

}
