// workin
import { AlertService } from '../services/AlertService'

export const vueAppMixin = {

  data () {
    return {

    }
  },
  methods: {
    isActiveRoute (routeName, exceptions = []) {
      const routes = this.$router.history.current.matched
      let active = false
      routes.forEach(route => {
        if (route.name === routeName) {
          active = true
        }
      })
      routes.forEach(route => {
        if (exceptions.includes(route.name)) {
          active = false
        }
      })

      return active
    },

    goBack () {
      if(this.$router.currentRoute.name==='VesselParticular'){
        this.$router.push({name:'VesselIndex'})
      }
      else {
        this.$router.go(-1)
      }
      // console.log('go back')
    },

    async confirmCreateAlert (message) {
      return await AlertService.confirmCreateAlert(message)
    },

    async confirmUpdate (message) {
      return await AlertService.confirmUpdateAlert(message)
    },
    async confirmDeleteAlert (message) {
      return await AlertService.confirmDeleteAlert(message)
    },

    successAlert (message, actionType) {
      AlertService.successAlert(message, actionType)
    },

    errorAlert (message, actionType) {
      AlertService.errorAlert(message, actionType)
    }

  },

  computed: {
    currentRouteName () {
      // eslint-disable-next-line no-unused-expressions
      this.$route.name
    }
  }
}
