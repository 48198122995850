import {KeyService} from "@/services/KeyService";

const state = {
  requestKeys: [],
  requestKeysOverwrite: false,
}

const actions = {
  async getRequestKeys({commit}) {
    // const response = await KeyService.getAilmentKeys();
    // if (response) {
    //   commit('SET_AILMENT_KEYS', response)
    // }
    // return false;
  },
  newRequestKeys({commit, dispatch}, ...keys) {
    commit('SET_REQUEST_KEYS', []);
    dispatch("addRequestKeys", ...keys);
  },
  addRequestKeys({commit}, ...keys) {
    let _keys = [];

    keys.forEach(key => {
      if (Array.isArray(key)) {
        key.forEach(_key => {
          _keys.push(_key)
        })
      } else {
        _keys.push(key)
      }
    });

    commit('SET_REQUEST_KEYS_OVERWRITE', false);
    commit('SET_REQUEST_KEYS', _keys);
  },
  setRequestKeys({commit}, ...keys) {

    commit('SET_REQUEST_KEYS_OVERWRITE', true);
    commit('SET_REQUEST_KEYS', ...keys);
  },
  async generateRequestKeys({commit, state}, params = {}) {
    let __keys = state.requestKeys;
    let __params = {};

    if (params.hasOwnProperty('__params') && Object.keys(params.__params).length) {
      await Object.keys(params.__params).forEach(key => {
        if (__keys.includes(key)) {
          __params[key] = params.__params[key];
        }
      });

      delete params.__params;
    }

    const response = await KeyService.getRequestKeys({...params, ...{__keys, __params}});

    if (response.status === 200 && Object.keys(response.data).length) {
      Object.keys(response.data).forEach(key => {
        // console.log('setting data to: ' + key);
        commit(key, response.data[key]);
      });
    }
  }
}

const mutations = {
  SET_REQUEST_KEYS: (state, params) => {
    if (!state.requestKeysOverwrite) {
      let newRequestKeys = [...state.requestKeys, ...params];
      state.requestKeys = newRequestKeys.filter(function (item, pos) {
        return newRequestKeys.indexOf(item) === pos;
      });
    } else {
      state.requestKeys = [...params];
    }
  },
  SET_REQUEST_KEYS_OVERWRITE: (state, val) => {
    state.requestKeysOverwrite = !!val;
  }
}


const getters = {
  requestKeys: (state) => state.requestKeys
}

export default {
  state,
  getters,
  actions,
  mutations
}
