import {CurrencyService} from "@/services/CurrencyService";
import {KeyService} from "@/services/KeyService";

const state = {
  currencyKeys: [],
};


const mutations = {
  SET_CURRENCIES: (state, payload) => state.currencyKeys = payload,
};


const actions = {
  // async getCurrencyKeys({commit}) {
  //   // commit('SET_CURRENCIES', []);
  //   let currencies = await KeyService.getCurrencyKeys()
  //   // console.log('currencies')
  //   if (currencies) commit('SET_CURRENCIES', currencies);
  // }

};


const getters = {
  currencyKeys: state => state.currencyKeys,
};


/** export **/
export default {state, actions, mutations, getters};
