'use strict'

import {SERVER_API_URL} from './ServerConstant';
export const API_URL = SERVER_API_URL;

// eslint-disable-next-line prefer-const
export const
  TOKEN_NAME = 'kidsToken',
  USER_DETAILS = 'kidsUser',
  PREFIX = 'kids/',
  LOGIN = PREFIX + 'login',
  LOGOUT = PREFIX + 'logout',

  IMPERSONATOR_TOKEN_NAME = 'ikidsToken',
  IMPERSONATOR_USER_DETAILS = 'ikidsUser',
  IMPERSONATOR_LOGOUT = 'ikidsLogout',
  IMPERSONATOR_USERS = PREFIX + 'impersonate/users',
  IMPERSONATOR_USER = PREFIX + 'impersonate',

  GET_KEYS = PREFIX + 'getKeys',
  KEYS_VESSEL = PREFIX + 'getVesselKeys',
  KEYS_COUNTRY = PREFIX + 'getCountryKeys',
  KEYS_TIMEZONE = PREFIX + 'getTimezoneKeys',
  KEYS_REPORT_STATUS = PREFIX + 'getReportStatusKeys',
  KEYS_MNGMT_OFFICE = PREFIX + 'getMngmtOfficeKeys',
  KEYS_MNGMT_OFFICE_VESSELS = PREFIX + 'getMngmtOfficeVesselsKeys',
  KEYS_CREW = PREFIX + 'getCrewKeys',
  KEYS_USER = PREFIX + 'getUserKeys',
  KEYS_USER_RANKS = PREFIX + 'getUserRanksKeys',
  KEYS_EVENT_ONE = PREFIX + 'getEventOneKeys',
  KEYS_EVENT_TWO = PREFIX + 'getEventTwoKeys',
  KEYS_EVENT_THREE = PREFIX + 'getEventThreeKeys',
  KEYS_EVENT_FOUR = PREFIX + 'getEventFourKeys',
  KEYS_EVENT_FIVE = PREFIX + 'getEventFiveKeys',
  KEYS_MAIN_CAUSE = PREFIX + 'getMainCauseKeys',
  KEYS_SUB_CAUSE = PREFIX + 'getSubCauseKeys',
  KEYS_MNGMT_COMPANY = PREFIX + 'getMngmtCompanyKeys',
  KEYS_FLEET = PREFIX + 'getFleetKeys',
  KEYS_VESSEL_TYPES = PREFIX + 'getVesselTypeKeys',
  KEYS_EVENT_IMPACT = PREFIX + 'getEventClassImpactKeys',

  KEYS_HERROR_PERSONALS = PREFIX + 'getHErrorPersonalKeys',
  KEYS_HERROR_MANNINGS = PREFIX + 'getHErrorManningKeys',
  KEYS_HERROR_OTHERS = PREFIX + 'getHErrorOtherKeys',

  GET_CURRENCIES = PREFIX + 'getCurrencies',
  API_CURRENCY = PREFIX + 'currency',


  ACCIDENT_INCIDENT = PREFIX + 'accidentIncidentReport',
  AIR_EVENT=PREFIX + 'accidentIncidentEvent',
  AIR_LOSS=PREFIX + 'accidentIncidentLoss',
  AIR_CAUSE= PREFIX + 'accidentIncidentCause',
  AIR_PREVENTIVE= PREFIX + 'accidentIncidentPreventive',
  AIR_CORRECTIVE= PREFIX+ 'accidentIncidentCorrective',
  CMS_ONBOARD_CREW = PREFIX + 'getOnboardCrew',
  CMS_CREW_INFO=PREFIX + 'getCrewInfo',
  CMS_CREW_ACTIVITIES=PREFIX + 'getCrewActivities',
  CMS_CREW_IMAGE= PREFIX + 'getCrewImage',
  CMS_CREW_CONSENT= PREFIX + 'getCrewConsent',
  CMS_CREW_BIODATA= PREFIX + 'getCrewBiodata',
  CMS_SEA_EXPERIENCE=PREFIX + 'getCrewExperience',
  CMS_OUTSIDE_EXPERIENCE=PREFIX + 'getCrewExternalExperience',
  CMS_INCIDENT_BEHAVIOR= PREFIX + 'getCrewIncidentBehavior',
  CMS_INCIDENT_BEHAVIOR_FILE=PREFIX + 'getCrewIncidentBehaviorFile',
  CMS_CREW_DOCUMENTS=PREFIX + 'getCrewDocuments',
  CMS_CREW_FLAG_DOCUMENTS=PREFIX + 'getCrewDocumentsByFlag',
  CMS_CREW_ASSESSMENTS_REPORT=PREFIX + 'getAssessments',
  CMS_CREW_ASSESSMENT_HISTORY=PREFIX + 'getAssessmentHistory',
  CMS_CREW_ASSESSMENT_FILE = PREFIX + 'getAssessmentFile',
  CMS_CREW_DOCUMENT_HISTORY=PREFIX + 'getCrewDocumentHistory',
  CMS_CREW_DOCUMENT_FILE=PREFIX + 'getCrewDocumentFile',
  CMS_MEDICAL_KEYS= PREFIX + 'getMedicalKeys',
  CMS_CREW_MEDICALS=PREFIX + 'getCrewMedicals',
  CMS_CREW_HEALTH_DECLARATIONS=PREFIX + 'getHealthDeclarations',
  CMS_CREW_RT_PCR=PREFIX + 'getCrewRtPcr',
  CMS_CREW_VACCINE=PREFIX + 'getCovidVaccines',
  CMS_CREW_MEDICAL_FILE=PREFIX + 'getCrewMedicalFile',
  CMS_CREW_VACCINE_FILE=PREFIX + 'getCovidVaccineFile',
  CMS_RT_PCR_FILE=PREFIX + 'getRtpcrTestFile',
  CMS_HEALTH_DECLARATION_FILE=PREFIX + 'getHealthDeclarationFile',
  CMS_CREW_CERS=PREFIX + 'getCrewEvaluationReports',
  CMS_CER_FILE=PREFIX + 'getCrewEvaluationFile',
  CMS_CER_COMMENT_ATTACHMENT=PREFIX + 'getCrewCommentAttachment',
  CMS_COMPANY_EVALUATIONS=PREFIX + 'getCompanyEvaluations',
  CMS_COMPANY_EVALUTAION_FILE=PREFIX + 'getCompanyEvaluationFile',
  CMS_CREW_MEDICAL_ISSUES=PREFIX + 'getMedicalIssues',
  CMS_CREW_PANDI=PREFIX + 'getPandIs',
  CMS_CREW_PANDI_CHECKUP_FILE=PREFIX + 'getPandICheckupAttachment',
  CMS_CREW_PANDI_EXPENSE_FILE=PREFIX + 'getPandiExpenseAttachment',
  CMS_CREW_MEDICAL_INCIDENT_FILE=PREFIX + 'getMedicalIncidentFile',
  CMS_CREW_MEDICAL_INJURY_FILE=PREFIX + 'getMedicalInjuryFile',
  CMS_CREW_MEDICAL_HOSPITALIZATION_FILE=PREFIX + 'getMedicalHospitalizationFile',


  AIR_LOSS_DELETE_PERSONEL= PREFIX + 'removePersonnel',
  REPORT = PREFIX + 'report',
  ACCIDENT_INCIDENT_ATTACHMENTS=PREFIX + 'accidentIncidentAttachment',

  //ACCIDENT_INCIDENT = PREFIX + 'accidentIncident',
  // ACCIDENT_INCIDENT_GENERAL = PREFIX + 'fillGeneralDetails',
  // ACCIDENT_INCIDENT_EVENT = PREFIX + 'fillEventDetails',
  // ACCIDENT_INCIDENT_CAUSE = PREFIX + 'fillCauseDetails',
  // ACCIDENT_INCIDENT_CORRECT_ACTION = PREFIX + 'fillCorrectActionDetails',
  // ACCIDENT_INCIDENT_PREVENT_ACTION = PREFIX + 'fillPreventActionDetails',
  // ACCIDENT_INCIDENT_FEEDBACK = PREFIX + 'fillFeedbackDetails',
  // ACCIDENT_INCIDENT_LOSS = PREFIX + 'fillLossDetails',
  // ACCIDENT_INCIDENT_VIEW_ATTACHMENT= PREFIX + 'getAccidentIncidentAttachment',
  // ACCIDENT_INCIDENT_VIEW_ATTACHMENTS= PREFIX + 'getAccidentIncidentAttachments',
  // DELETE_INCIDENT_ACCIDENT_ATTACHMENT= PREFIX + 'deleteAccidentIncidentAttachment',
  // DOWNLOAD_ACCIDENT_INCIDENT_ATTACHMENT= PREFIX + 'downloadAllAttachments',
  VESSEL_LIST = PREFIX + 'getVesselList',
  VESSEL_DETAIL = PREFIX + 'getVesselDetail',
  VESSEL_INFO = PREFIX + 'getVesselInfo',
  ADD_VESSEL = PREFIX + 'addVessel',
  UPDATE_VESSEL = PREFIX + 'updateVessel',
  DELETE_VESSEL = PREFIX + 'deleteVessel',
  DELETE_REPORT = PREFIX + 'deleteReport',
  // ADD_ACCIDENT_INCIDENT_ATTACHMENT=PREFIX + 'addAccidentIncidentAttachments',
  ADD_CA_FILE = PREFIX + 'addCAFiles',
  GET_CA_FILES = PREFIX + 'getCAFiles',
  DOWNLOAD_CA_FILES = PREFIX + 'downloadCAFile',
  DELETE_CA_FILE = PREFIX + 'deleteCAFile',
  DOWNLOAD_CA_FILES_ALL = PREFIX + 'downloadCAFileAll',
  GET_MANNING_KEYS = PREFIX + 'getManningKeys',

  ALL_USERS = PREFIX + 'users',
  CREATE_USER = PREFIX + 'users',
  UPDATE_USER = PREFIX + 'users',
  USER_VESSEL = PREFIX + 'user/{user_id}/vessels',
  UPDATE_USER_ROLE_PERMISSIONS = PREFIX + 'updateRolePermission',
  PERMISSION_KEYS = PREFIX + 'getRolePermissionKeys',
  STATIC_PERMISSION_KEYS = PREFIX + 'getStaticPermissionKeys',

  COMPANY_INSTRUCTION = PREFIX +  'comp-in',
  COMPANY_INSTRUCTION_CANCEL = PREFIX +  'cancelInstruction',
  SENT_TO_VESSEL = PREFIX +  'sendToVessel',
  VESSEL_INSTRUCTION_LIST = PREFIX + 'vesselCompInstruction',
  VESSEL_INSTRUCTION_RESPONSE = PREFIX + 'airCiVesselResponse',
  VESSEL_INSTRUCTION_FILES = PREFIX + 'getCiVesselFiles',
  VESSEL_INSTRUCTION_ADD_FILES = PREFIX + 'addCiVesselFiles',
  VESSEL_INSTRUCTION_VIEW_FILE= PREFIX + 'getCiFile',
  VESSEL_INSTRUCTION_DELETE_FILE= PREFIX + 'deleteCIAttachment',
  DOWNLOAD_CA_REPORT = AIR_CORRECTIVE + '/downloadReport',
  UPDATE_REPORT_STATUS= PREFIX + 'updateReportStatus',
  DOWNLOAD_AI_REPORT= PREFIX + 'accident-incident/{report_id}/download',
  DOWNLOAD_AI_REPORT_TEMPLATE= PREFIX + 'accident-incident/download/template/{vessel_id}',
  UPLOAD_AI_REPORT=PREFIX + 'accident-incident/import',
  GENERATE_AIR_VESSEL_HISTORY=PREFIX + 'vessel/history',

  GET_SISTER_SHIP_GROUPS=PREFIX + 'getVesselGroups',
  ADD_SISTER_SHIP_GROUP=PREFIX + 'addVesselGroup',
  DELETE_SISTER_SHIP_GROUP=PREFIX + 'deleteVesselGroup',
  UPDATE_SISTER_GROUP=PREFIX + 'updateVesselGroup',
  GET_SISTER_VESSEL_INFO=PREFIX + 'getSisterVesselInfo',
  RELATED_PARTY = PREFIX + 'relatedParty',
  RELATED_PARTY_VESSEL_IDS = PREFIX + 'relatedPartyVesselIds',
  RELATED_PARTIES = PREFIX + 'relatedParties',
  CI_SAVE_SUBMIT_VESSEL = PREFIX + 'saveAndSubmitVessel',
  CI_TYPE_KEYS = PREFIX + 'getCompanyInstructionKeys',
  KEY_MEDICAL_SEVERITY = PREFIX + 'getMedicalSeverityKeys',
  KEY_MEDICAL_TREATMENT = PREFIX + 'getMedicalTreatmentKeys',
  KEY_AILMENT_PART = PREFIX + 'getAilmentPartKeys',
  KEY_AILMENT = PREFIX + 'getAilmentKeys',
  KEY_MEDICAL_TYPE = PREFIX + 'getMedicalTypeKeys',
  KEY_AILMENT_REASON = PREFIX + 'getAilmentReasonKeys',

  ADD_LOSS_PERSONNEL = PREFIX + 'addPersonnel',
  PERSONNEL_ATTACHMENTS = PREFIX + 'getPersonnelAttachments',
  DELETE_PERSONNEL_ATTACHMENT = PREFIX + 'deletePersonnelAttachment',
  GET_PERSONNEL_ATTACHMENT= PREFIX + 'downloadPersonnelAttachment',
  AIR_REPORT_STATUS_COUNTS= PREFIX + 'getDashboardCounts',
  AIR_CHARTS= PREFIX + 'getAccidentCharts',
  FLEET_CONTACT_LIST= PREFIX + 'downloadContactList',
  FLEET_CONTACT_LIST_DATE= PREFIX + 'getContactListDate',
  GET_FLEET_CONTACT_LISTS= PREFIX + 'getFleetContactLists',
  DELETE_FLEET_CONTACT_LIST_BY_NAME= PREFIX + 'deleteFleetContactListByName',
  UPLOAD_FLEET_CONTACT_LIST= PREFIX + 'uploadFleetContactList',

  CHANGE_PASSWORD= PREFIX + 'changePassword',
  RESET_PASSWORD= PREFIX + 'resetPassword',
  ENGINE_MAKER=PREFIX + 'engine-maker',
  ENGINE_TYPE=PREFIX + 'engine-type',
  ENGINE_DG_MAKER=PREFIX + 'engine-dg-maker',
  ENGINE_DG_TYPE=PREFIX + 'engine-dg-type',
  ENGINE_TC_MAKER=PREFIX + 'engine-tc-maker',
  ENGINE_TC_TYPE=PREFIX + 'engine-tc-type',
  ENGINE_AUX_MAKER=PREFIX + 'engine-aux-maker',
  ENGINE_AUX_TYPE = PREFIX + 'engine-aux-type',
  GET_ACCIDENT_INCIDENT_BY_EVENT= PREFIX + 'getAccidentIncidentByEvent',
  PSC_INDEX = PREFIX + 'psc',
  PSC_CODES = PREFIX + 'psc-codes',
  PSC_ACTION_CODES = PREFIX + 'psc-action-codes',
  PSC_USCG_CODES = PREFIX + 'psc-uscg-codes',
  JOB_STATUSES = PREFIX + 'job-status',
  ACKNOWLEDGEMENT_STATUSES = PREFIX + 'acknowledgement-status',
  CI_INDEX = PREFIX + 'ci',
  CI_VESSEL_INDEX = PREFIX + 'ci-vessel',
  CI_COMMENT_INDEX = PREFIX + 'ci-comment',
  DOWNLOAD_PSC_REPORT= PREFIX + 'psc/{report_id}/export/report',
  DOWNLOAD_PSC_REPORT_TEMPLATE= PREFIX + 'psc/{vessel_id}/export/template',
  UPLOAD_PSC_REPORT_TEMPLATE= PREFIX + 'psc/import',
  PSC_REPORT_INDEX= PSC_INDEX + '/reports',
  GENERATE_PSC_VESSEL_HISTORY=PSC_INDEX + '/vessel/history',
  MATCH_ROUTE_NAMES = {
    pscid: 'PscMain',
  },

  RS_INDEX = PREFIX + 'rs',
  RS_CODES = PREFIX + 'rs-codes',
  RS_ACTION_CODES = PREFIX + 'rs-action-codes',
  DOWNLOAD_RS_REPORT= PREFIX + 'rs/{report_id}/export/report',
  DOWNLOAD_RS_REPORT_TEMPLATE= PREFIX + 'rs/{vessel_id}/export/template',
  UPLOAD_RS_REPORT_TEMPLATE= PREFIX + 'rs/import',
  RS_REPORT_INDEX= RS_INDEX + '/reports',
  GENERATE_RS_VESSEL_HISTORY=RS_INDEX + '/vessel/history',

  RSNC = PREFIX + 'rsnc',
  RSNC_QUESTION_KEYS= PREFIX + 'rsnc-question',

  // AUDITOR MODULE CONSTANTS
  USER_FOR_AUDITOR = PREFIX + 'auditors/users/{user_id}',
  AUDITS = PREFIX + 'audits',
  AUDITS_SETTINGS = PREFIX + 'audits/settings',
  VESSEL_AUDITS = PREFIX + 'vessels/{vessel_id}/audits',
  AUDITOR_INDEX = PREFIX + 'audits/auditors',


  AUDITOR_CERTIFICATE = PREFIX + 'audits/certificates',
  AUDITOR_DEPARTMENTS = PREFIX + 'auditors/departments',
  AUDITOR_POSITIONS = PREFIX + 'auditors/positions',

  DELETE_AUDIT_FILE = PREFIX + 'audits/{audit_id}/{type}',

  // IA REPORTS CONSTANTS
  IA_REPORTS_BY_TYPE = PREFIX + '{type}/{id}/ia-reports',
  UPDATE_IA_REPORT_TYPE_BY_ID = PREFIX + 'ia-reports/{audit_report}',
  DELETE_ATTENDANCE = PREFIX + 'ia-reports/{audit_report}/attendance',
  DELETE_CHECKLIST = PREFIX + 'ia-reports/{audit_report}/checklist',
  DOWNLOAD_DOCUMENT = PREFIX + 'vessel-audits/{audit_id}/{audit_type}/downloads',

  // NON CONFORMITIES CONSTANTS

  ROOT_CAUSES_LIST = PREFIX + 'audits/{type}/root-causes',
  NON_CONFORMITIES = PREFIX + '{type}/{id}/non-conformities',
  NON_CONFORMITIES_ORDER = PREFIX + '{type}/{id}/non-conformities/order',
  UPDATE_NON_CONFORMITIES = PREFIX + 'non-conformities/{non_conformity}',
  DELETE_NON_CONFORMITY = PREFIX + 'non-conformities/{non_conformity}',
  NON_CONFORMITIES_OE = PREFIX + 'non-conformities/{non_conformity}/objective-evidences',

  // NCR OBJECTIVE EVIDENCE CONSTANTS
  LATEST_OBJECTIVE_EVIDENCES = PREFIX + 'objective-evidences',
  OE_CATEGORIES_LIST = PREFIX + 'objective-evidences/categories',
  UPDATE_OBJECTIVE_EVIDENCE = PREFIX + 'objective-evidences/{objective_evidence}',
  DELETE_OBJECTIVE_EVIDENCE = PREFIX + 'objective-evidences/{objective_evidence}',

  OE_BEFORE_ATTACHMENTS = PREFIX + 'objective-evidences/{objective_evidence}/before',
  OE_AFTER_ATTACHMENTS = PREFIX + 'objective-evidences/{objective_evidence}/after',



  // VPI CONSTANTS
  VPI = PREFIX + 'vpi',
  UPDATE_ICBT_SCORE = PREFIX + 'vessels/{vessel}/icbt-score',

  // AUDIT DUES

  AUDIT_DUES = PREFIX + 'audit-dues',
  AUDIT_DUE_COUNTS = PREFIX + 'audits-due/counts',
  AUDIT_DUE_PLAN = PREFIX + 'vessels/{vessel}/audit-due-plan',

  //COMPANY AUDIT CONSTANTS
  COMPANIES = PREFIX + 'companies',
  COMPANY = PREFIX + 'companies/{company_id}',

  COMPANY_AUDITS = PREFIX + 'company/audits',
  COMPANY_AUDITS_BY_ID = PREFIX + 'companies/{company_id}/audits',

  COMPANY_LATEST_OBJECTIVE_EVIDENCES = PREFIX + 'company/objective-evidences',
  COMPANY_AUDIT_DUES = PREFIX + 'company/audit-dues',
  COMPANY_AUDIT_DUE_COUNTS = PREFIX + 'company/audits-due/counts',

// IA REPORTS CONSTANTS
  COMPANY_IA_REPORTS_BY_TYPE = PREFIX + 'company/{type}/{id}/ia-reports',
  COMPANY_UPDATE_IA_REPORT_TYPE_BY_ID = PREFIX + 'company/ia-reports/{audit_report}',
  COMPANY_DOWNLOAD_DOCUMENT = PREFIX + 'company-audits/{audit_id}/{audit_type}/downloads',


  // NON CONFORMITIES CONSTANTS

  COMPANY_NON_CONFORMITIES = PREFIX + 'company/{type}/{id}/non-conformities',
  COMPANY_UPDATE_NON_CONFORMITIES = PREFIX + 'company/non-conformities/{non_conformity}',
  COMPANY_DELETE_NON_CONFORMITY = PREFIX + 'company/non-conformities/{non_conformity}',
  COMPANY_NON_CONFORMITIES_OE = PREFIX + 'company/non-conformities/{non_conformity}/objective-evidences',

  // NCR OBJECTIVE EVIDENCE CONSTANTS
  COMPANY_UPDATE_OBJECTIVE_EVIDENCE = PREFIX + 'company/objective-evidences/{objective_evidence}',
  COMPANY_DELETE_OBJECTIVE_EVIDENCE = PREFIX + 'company/objective-evidences/{objective_evidence}',

  COMPANY_OE_BEFORE_ATTACHMENTS = PREFIX + 'company/objective-evidences/{objective_evidence}/before',
  COMPANY_OE_AFTER_ATTACHMENTS = PREFIX + 'company/objective-evidences/{objective_evidence}/after',



  // FILTER MENU DATA REQUEST

  FILTER_MENU = PREFIX + 'filter-menu'
;
