import {GlobalService} from "@/services/GlobalService";
import {
  ACCIDENT_INCIDENT, AUDIT,
  AUDIT_DUE_COUNTS, AUDIT_DUE_PLAN,
  AUDIT_DUES,
  AUDIT_DUES_COUNT,
  AUDITOR_CERTIFICATE,
  AUDITOR_INDEX,
  AUDITS,
  AUDITS_SETTINGS,
  COMPANY_DELETE_OBJECTIVE_EVIDENCE, DELETE_ATTENDANCE, DELETE_AUDIT_FILE, DELETE_CHECKLIST,
  DELETE_NON_CONFORMITY,
  DELETE_OBJECTIVE_EVIDENCE,
  DOWNLOAD_DOCUMENT,
  IA_REPORTS_BY_TYPE,
  LATEST_OBJECTIVE_EVIDENCES,
  NON_CONFORMITIES,
  NON_CONFORMITIES_OE, NON_CONFORMITIES_ORDER,
  OE_AFTER_ATTACHMENTS,
  OE_BEFORE_ATTACHMENTS,
  OE_CATEGORIES_LIST,
  PSC_INDEX,
  ROOT_CAUSES_LIST,
  UPDATE_IA_REPORT_TYPE_BY_ID, UPDATE_ICBT_SCORE,
  UPDATE_NON_CONFORMITIES,
  UPDATE_OBJECTIVE_EVIDENCE,
  VESSEL_AUDITS,
  VESSEL_DETAIL
} from "@/services/ConstantService";
import axios from "axios";

import da from "vue2-datepicker/locale/es/da";
import {AlertService} from "@/services/AlertService";

const state = {
  // start of audit records data section
  vesselAuditRecord: [],
  selectedAuditRecord : {
    sms : null,
    isps : null,
    cyber : null,
  },
  // end of audit records data section

  // start of internal audit report data section
  internal_audit_reports : {
    sms : null,
    isps : null,
    cyber : null,
  },

  director_security : {
    sms : [],
    isps : [],
    cyber : [],
  },

  // end of internal audit report data section

  // start of non-conformities data section
  nonConformities : {
    sms : null,
    isps : null,
    cyber : null,
  },

  nonConformityType : {
    sms : null,
    isps : null,
    cyber : null,
  },
  observationNoteType : {
    sms : null,
    isps : null,
    cyber : null,
  },




  root_causes_list : {
    sms : [],
    isps : [],
    cyber : [],
  },
  // end of non-conformities data section

  // start of objective evidence data section
  oECategories : [],
  latestOeLists : [],

  vessel_audit_dues : [],

  // end of objective evidence data section

  // start of audit due data section
  vessel_audit_dues_count : {
    sms : {
      overdue : 0,
      '30days' : 0,
      '90days' : 0,
    },
    isps : {
      overdue : 0,
      '30days' : 0,
      '90days' : 0,
    },
    cyber : {
      overdue : 0,
      '30days' : 0,
      '90days' : 0,
    },
  },
  reportTypeStatusKeys : [],
}

const actions = {
  // START OF VESSEL AUDIT RECORDS SECTION
  async getVesselAuditRecordList({ commit }, params) {
    const uri = VESSEL_AUDITS.replace('{vessel_id}', params.vessel_id) + '?page=' + params.page;
    const response = await GlobalService.ajax('post', uri, {params});
    const data = response?.data;
    commit('SET_AUDIT_RECORD', data?.data);
    commit('setPagination', data?.meta)
  },
  async getVesselAuditRecordById({ commit }, auditRecordId) {
    const uri = AUDITS + `/${auditRecordId}`
    return await axios['get'](uri).then(response => {
      const data = response?.data;
      commit('SET_SELECTED_AUDIT_RECORD', data?.data);
      return response.data ?? false
    }).catch(error => {
      return false;
    });
  },
  async deleteAuditRecord({ commit }, params) {
    return await axios.delete(AUDITS +`/${params.audit_type}/${params.audit_type_id}`,{data:params}).then(response => {
      if (response.hasOwnProperty('status') && response.status === 200) {
        return true;
      }
      return response;
    }).catch(error => {
      if(error.response.status === 500){
        GlobalService.handleError(error, 'DELETE REPORT SERVICE')
      }
      return error.response.data
    })
  },
  async createAuditRecord({ commit }, params) {
    const response = await GlobalService.ajax('post', AUDITS, params);
    if (response.hasOwnProperty('status') && response.status === 201) {
      return {
        result : true,
        data : response.data
      };
    }
    return {
      result : false,
      message : response.message,
      errors : response.errors
    };
  },
  async updateAuditSettings({ commit }, params){
    const response = await GlobalService.ajax('post', AUDITS_SETTINGS, params);

    if (response.hasOwnProperty('status') && response.status === 200) {
      return {
        result : true,
        data : response.data
      };
    }
    return {
      result : false,
      message : response.message
    };
  },
  async updateAuditRecord({ commit }, params) {
    const uri = [AUDITS, params.id].join('/') + '?_method=PUT';

    const response = await GlobalService.ajax('post', uri, params.form_data);

    if (response.hasOwnProperty('status') && response.status === 200) {
      return {
        result : true,
        data : response.data
      };
    }
    return {
      result : false,
      message : response.message
    };
  },

  async deleteAuditFile({ commit }, params) {
    let uri = DELETE_AUDIT_FILE.replace('{audit_id}', params.id).replace('{type}', params.type);

    return await axios.delete(uri, {data:params}).then(response => {
      if (response.hasOwnProperty('status') && response.status === 200) {
        return true;
      }
      return response;
    }).catch(error => {
      if(error.response.status === 500){
        GlobalService.handleError(error, 'DELETE FILE SERVICE')
      }
      return error.response.data
    })
  },


  // END OF VESSEL AUDIT RECORDS SECTION

  // START OF INTERNAL AUDIT SECTION
  async getInternalAudit({ commit }, params) {
    const uri = IA_REPORTS_BY_TYPE.replace('{type}', params.type).replace('{id}', params.id);
    const response = await GlobalService.ajax('get', uri);

    if (response.hasOwnProperty('status') && response.status === 200) {
      const data = response?.data;
      commit('SET_INTERNAL_AUDIT', {
        type : params.type,
        value : data?.data
      });
    }
  },
  async updateInternalAuditReport({ commit }, params) {
    const uri = UPDATE_IA_REPORT_TYPE_BY_ID.replace('{audit_report}', params.id) + '?_method=PUT';
    const response = await GlobalService.ajax('post', uri, params.form);
    if (response.hasOwnProperty('status') && response.status === 200) {
      return {
        result : true,
        data : response.data
      };
    }
    return {
      result : false,
      message : response.message
    };

  },

  async deleteInternalAuditFile({ commit }, params) {
    let uri = DELETE_ATTENDANCE.replace('{audit_report}', params.id);

    if (params.type === 'checklist') {
      uri = DELETE_CHECKLIST.replace('{audit_report}', params.id);
    }
    const newParam = {
      ids : params.ids
    };

    return await axios.delete(uri, {data:newParam}).then(response => {
      if (response.hasOwnProperty('status') && response.status === 200) {
        return true;
      }
      return response;
    }).catch(error => {
      if(error.response.status === 500){
        GlobalService.handleError(error, 'DELETE FILE SERVICE')
      }
      return error.response.data
    })
  },

  async getDirectorSecurity({ commit }) {
    const response = {
      data : {
        data : {
          sms : [
            {
              id : 1,
              name : 'Sohachi Takimoto',
              management_office : 'KOBE'
            },
            {
              id : 2,
              name : 'Kosuke Matsui',
              management_office : 'STGT'
            },
            {
              id : 3,
              name : 'Tadashi Takahashi',
              management_office : 'ROHQ'
            }
          ],
          isps : [
            {
              id : 1,
              name : 'Kohei Senju',
              management_office : 'KOBE'
            },
            {
              id : 2,
              name : 'Iliya Draganov',
              management_office : 'STGT'
            },
            {
              id : 3,
              name : 'Richard Guevarra',
              management_office : 'ROHQ'
            },
            {
              id : 4,
              name : 'Capt. Noel D. Dalida',
              management_office : 'ROHQ'
            }
          ],
          cyber : [
            {
              id : 1,
              name : 'Kohei Senju',
              management_office : 'KOBE'
            },
            {
              id : 2,
              name : 'Iliya Draganov',
              management_office : 'STGT'
            },
            {
              id : 3,
              name : 'Richard Guevarra',
              management_office : 'ROHQ'
            },
          ],
        }
      },
      status : 200
    }

    if (response.hasOwnProperty('status') && response.status === 200) {
      const data = response?.data;
      commit('SET_DIRECTOR_SECURITY', {
        type : 'sms',
        value : data?.data.sms
      });
      commit('SET_DIRECTOR_SECURITY', {
        type : 'isps',
        value : data?.data.isps
      });
      commit('SET_DIRECTOR_SECURITY', {
        type : 'cyber',
        value : data?.data.cyber
      });
    }
  },

  async downloadAuditDocument({ commit }, params) {
    return await axios({
      url : DOWNLOAD_DOCUMENT.replace('{audit_id}', params.audit_id).replace('{audit_type}', params.audit_type) + '?file=' + params.file,
      method: 'GET',
      responseType: 'blob'
    }).then(response => {
      return response;
    }).catch(error => {
      return {
        result : false,
        message : error.message,
      };
    })
  },

  // END OF INTERNAL AUDIT SECTION

  // START OF NON-CONFORMITY/OBSERVATION SECTION

  async getRootCausesList({ commit }, type) {
    const uri = ROOT_CAUSES_LIST.replace('{type}', type);
    const response = await GlobalService.ajax('get', uri);

    if (response.hasOwnProperty('status') && response.status === 200) {
      const data = response?.data;

      commit('SET_ROOT_CAUSES_LIST', {
        type : type,
        value : data
      });
    }
  },
  async getNonConformities({ commit }, params) {
    const uri = NON_CONFORMITIES.replace('{type}', params.type).replace('{id}', params.id);
    const response = await GlobalService.ajax('get', uri, {params});

    if (response.hasOwnProperty('status') && response.status === 200) {
      const data = response?.data;

      let nonConformityType = data?.data.filter(item => item.type === 'ncr');
      let observationNoteType = data?.data.filter(item => item.type === 'obs');


      // nonConformityType = await imageMixin.methods.convertNcrObsImagesToBase64(nonConformityType);
      // observationNoteType = await imageMixin.methods.convertNcrObsImagesToBase64(observationNoteType);

      commit('SET_NON_CONFORMITIES', {
        type : params.type,
        value : data?.data
      });

      commit('SET_NONCONFORMITY_TYPE_DATA', {
        type : params.type,
        value : nonConformityType
      });

      commit('SET_OBSERVATION_TYPE_DATA', {
        type : params.type,
        value : observationNoteType
      });
    }
  },
  async createNonConformities({ commit }, params) {
    const uri = NON_CONFORMITIES.replace('{type}', params.type).replace('{id}', params.id);
    delete params.id;
    delete params.type;
    const response = await GlobalService.ajax('post', uri, params);

    if (response.hasOwnProperty('status') && response.status === 200) {
      return {
        result : true,
        data : response.data
      };
    }
    return {
      result : false,
      message : response.message,
      errors : response.errors
    };
  },
  async updateNonConformities({ commit }, params) {
    const uri = UPDATE_NON_CONFORMITIES.replace('{non_conformity}', params.id) + '?_method=PUT';
    const response = await GlobalService.ajax('post', uri, params);

    if (response.hasOwnProperty('status') && response.status === 200) {
      return {
        result : true,
        data : response.data
      };
    }
    return {
      result : false,
      message : response.message
    };
  },

  async deleteNonconformity({ commit }, params) {
    return await axios.delete(DELETE_NON_CONFORMITY.replace('{non_conformity}', params.id), {data:params}).then(response => {
      if (response.hasOwnProperty('status') && response.status === 200) {
        return true;
      }
      return response;
    }).catch(error => {
      if(error.response.status === 500){
        GlobalService.handleError(error, 'DELETE REPORT SERVICE')
      }
      return error.response.data
    })
  },

  async getOECategoriesList({ commit }) {
    const response = await GlobalService.ajax('get', OE_CATEGORIES_LIST);

    if (response.hasOwnProperty('status') && response.status === 200) {
      const data = response?.data;
      commit('SET_OE_CATEGORIES', data);
    }
  },

  async getLatestObjectiveEvidences({ commit }, year) {
    const uri = LATEST_OBJECTIVE_EVIDENCES + '?filter[year]=' + year;
    const response = await GlobalService.ajax('get', uri);
    if (response.hasOwnProperty('status') && response.status === 200) {
      const data = response?.data;
      commit('SET_LATEST_OE_LISTS', data);
    }
  },

  async createObjectiveEvidence({ commit }, params) {
    const uri = NON_CONFORMITIES_OE.replace('{non_conformity}', params.id);
    delete params.id;
    delete params.type;

    const response = await GlobalService.ajax('post', uri, params);

    if (response.hasOwnProperty('status') && response.status === 201) {
      return {
        result : true,
        data : response.data
      };
    }
    return {
      result : false,
      message : response.message,
      errors : response.errors
    };
  },

  async updateObjectiveEvidence({ commit }, params) {
    const uri = UPDATE_OBJECTIVE_EVIDENCE.replace('{objective_evidence}', params.id) + '?_method=PUT';
    const response = await GlobalService.ajax('post', uri, params);

    if (response.hasOwnProperty('status') && response.status === 200) {
      return {
        result : true,
        data : response.data
      };
    }
    return {
      result : false,
      message : response.message
    };
  },

  async deleteObjectiveEvidence({ commit }, params) {
    return await axios.delete(DELETE_OBJECTIVE_EVIDENCE.replace('{objective_evidence}', params.id), {data:params}).then(response => {
      if (response.hasOwnProperty('status') && response.status === 200) {
        return true;
      }
      return response;
    }).catch(error => {
      if(error.response.status === 500){
        GlobalService.handleError(error, 'DELETE REPORT SERVICE')
      }
      return error.response.data
    })
  },

  async updateOEImageAttachment({ commit }, params) {
    let uri = OE_BEFORE_ATTACHMENTS.replace('{objective_evidence}', params.id);

    if (params.type === 'after') {
      uri = OE_AFTER_ATTACHMENTS.replace('{objective_evidence}', params.id);
    }

    const response = await GlobalService.ajax('post', uri, params.data);
    if (response.hasOwnProperty('status') && response.status === 200) {
      return {
        result : true,
        data : response.data
      };
    }
    return {
      result : false,
      message : response.message
    };
  },

  async deleteOeAttachments({ commit }, params) {
    let uri = OE_BEFORE_ATTACHMENTS.replace('{objective_evidence}', params.oe_id);

    if (params.type === 'after') {
      uri = OE_AFTER_ATTACHMENTS.replace('{objective_evidence}', params.oe_id);
    }

    return await axios.delete(uri, {data:params}).then(response => {
      if (response.hasOwnProperty('status') && response.status === 200) {
        return true;
      }
      return response;
    }).catch(error => {
      if(error.response.status === 500){
        GlobalService.handleError(error, 'DELETE REPORT SERVICE')
      }
      return error.response.data
    })
  },

  async updateOrderNumberFromList({ commit }, params) {

    let uri = NON_CONFORMITIES_ORDER.replace('{type}', params.type).replace('{id}', params.id);
    const response = await GlobalService.ajax('post', uri, params);

    if (response.hasOwnProperty('status') && response.status === 200) {
      return {
        result : true,
        data : response.data
      };
    }
    return {
      result : false,
      message : response.message
    };
  },

  // END OF NON-CONFORMITY/OBSERVATION SECTION

  // START OF AUDIT DUES SECTION
  async getAuditDues({ commit }, params) {
    const for_audit_plan = params.for_audit_plan;
    const response = await GlobalService.ajax('get', AUDIT_DUES, {params});
    if (response.hasOwnProperty('status') && response.status === 200) {
      const data = response?.data;
      if (for_audit_plan === true) {
        return data?.data;
      }
      commit('SET_AUDIT_DUES', data?.data);
      if (params.vessel_id === undefined) {
        commit('setPagination', data)
      }
    }
  },

  async getAuditDueCounts({ commit }) {
    const response = await GlobalService.ajax('get', AUDIT_DUE_COUNTS);
    if (response.hasOwnProperty('status') && response.status === 200) {
      const data = response?.data;
      commit('SET_AUDIT_DUES_COUNT', data?.data);
    }
  },

  async updateAuditDuePlanPlanByVesselId({ commit }, params) {
    const uri = AUDIT_DUE_PLAN.replace('{vessel}', params.vessel_id);
    const response = await GlobalService.ajax('patch', uri, params);
    if (response.hasOwnProperty('status') && response.status === 200) {
      return {
        result : true,
        data : response.data
      };
    }
    return {
      result : false,
      message : response.message
    };
  }
}

const mutations = {
  SET_AUDIT_RECORD : (state, vesselAuditRecord) => {
    state.vesselAuditRecord = vesselAuditRecord
  },
  SET_SELECTED_AUDIT_RECORD : (state, auditRecordData) => {
    state.selectedAuditRecord = auditRecordData;
  },
  SET_INTERNAL_AUDIT : (state, internalAuditData) => {
    state.internal_audit_reports[internalAuditData.type] = internalAuditData.value;
  },
  SET_NON_CONFORMITIES : (state, nonConformities) => {
    state.nonConformities[nonConformities.type] = nonConformities.value;
  },
  SET_ROOT_CAUSES_LIST : (state, root_causes) => {
    state.root_causes_list[root_causes.type] = root_causes.value;
  },
  SET_OE_CATEGORIES : (state, oECategories) => {
    state.oECategories = oECategories;
  },
  SET_LATEST_OE_LISTS : (state, latestOeLists) => {
    state.latestOeLists = latestOeLists;
  },
  SET_DIRECTOR_SECURITY : (state, data) => {
    state.director_security[data.type] = data.value;
  },
  SET_AUDIT_DUES : (state, vessel_audit_dues) => {
    state.vessel_audit_dues = vessel_audit_dues;
  },
  SET_AUDIT_DUES_COUNT : (state, vessel_audit_dues_count) => {
    state.vessel_audit_dues_count = vessel_audit_dues_count;
  },
  SET_REPORT_TYPE_STATUS_KEYS : (state, reportTypeStatusKeys) => {
    state.reportTypeStatusKeys = reportTypeStatusKeys;
  },
  SET_NONCONFORMITY_TYPE_DATA: (state, nonConformityType) => {
    state.nonConformityType[nonConformityType.type] = nonConformityType.value;
  },
  SET_OBSERVATION_TYPE_DATA: (state, observationNoteType) => {
    state.observationNoteType[observationNoteType.type] = observationNoteType.value;
  }
}

const getters = {
  vesselAuditRecord : state => state.vesselAuditRecord,
  selectedAuditRecord : state => state.selectedAuditRecord,
  internal_audit_reports : state => state.internal_audit_reports,
  nonConformities : state => state.nonConformities,
  nonConformityType : state => state.nonConformityType,
  observationNoteType : state => state.observationNoteType,
  root_causes_list : state => state.root_causes_list,
  latestOeLists : state => state.latestOeLists,
  oECategories : state => state.oECategories,
  director_security : state => state.director_security,
  vessel_audit_dues : state => state.vessel_audit_dues,
  vessel_audit_dues_count : state => state.vessel_audit_dues_count,
  reportTypeStatusKeys : state => state.reportTypeStatusKeys,
}

export default {
  state,
  actions,
  mutations,
  getters
}
