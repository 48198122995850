import {KeyService} from "@/services/KeyService";

const state={
  medicalTreatmentKeys:[],
}
const getters={
  medicalTreatmentKeys: (state)=>state.medicalTreatmentKeys
}

const actions={
  async getMedicalTreamentKeys({commit}){
    const response=await KeyService.getMedicalTreatmentKeys();
    if(response){
      commit('SET_MEDICAL_TREATMENT_KEYS',response)
    }
    return false;
  },
}

const mutations={
  SET_MEDICAL_TREATMENT_KEYS:(state,params)=>{
    state.medicalTreatmentKeys=params;
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
