import {EngineMakerService} from "@/services/EngineMakerService";
import {GlobalService} from "@/services/GlobalService";

const state={
  engineMaker:[],
}

const mutations={
  SET_ENGINE_MAKER : (state,payload)=>state.engineMaker=payload,
  ADD_ENGINE_MAKER : (state,payload)=>{
    if(state.engineMaker.length > 15) state.engineMaker.pop()

    state.engineMaker.push(payload)

    state.engineMaker.sort((a,b)=> GlobalService.sortFunc(a,b));
  },

  UPDATE_ENGINE_MAKER : (state,payload) => {
    let index= state.engineMaker.findIndex(maker=> maker.id===payload.id);

    state.engineMaker.splice(index,1,payload);

    state.engineMaker.sort((a,b)=> GlobalService.sortFunc(a,b));
  }
}

const actions={
  async getEngineMakers({commit},params){
    const response= await EngineMakerService.getEngineMakers(params)
    const isGet= params?.get ?? false
    if(response && !isGet){
      commit('setPagination',response)
      commit ('SET_ENGINE_MAKER',response.data)
    }
    else {
      commit ('SET_ENGINE_MAKER',response)
    }
    return response;
  },

  async createEngineMaker({commit},params){
    const response= await EngineMakerService.createEngineMaker(params)
    if(!response.errors){
      commit ('ADD_ENGINE_MAKER',response)
    }
    return response;
  },

  async updateEngineMaker({commit},params){
    const response= await EngineMakerService.updateEngineMaker(params)
    if(!response.errors){
      commit ('UPDATE_ENGINE_MAKER',response)
    }
    return response;
  },

  async deleteEngineMaker({commit},params){
    return await EngineMakerService.deleteEngineMaker(params);
  }
}

const getters={
  engineMaker:(state)=>GlobalService.capitalizeProperties(state.engineMaker)
}


export default {state,actions,mutations,getters}
