<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade"
      :class="{ show: isVisible, 'd-block': isVisible }"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      v-if="isVisible"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel"><slot name="title">Modal Title</slot></h5>
            <button type="button"  v-if="closeButton" class="close" @click="closeModal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <slot name="body">...</slot>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
              <button type="button" class="btn btn-primary">Save changes</button>
            </slot>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal backdrop -->
    <div v-if="isVisible" class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    closeButton: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  methods: {
    closeModal() {
      this.$emit('close'); // Notify parent to hide the modal
    }
  }
};
</script>

<style scoped>
.modal.show {
  display: block;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}
/* Ensure modal stays visible while open */
.d-block {
  display: block !important;
}
</style>
