<script>
import {vueAppMixin} from "@/mixins/vueAppMixin";
import FileTree from "@/components/vessel/documents/components/FileTree.vue";
import ImageSlider from "@/components/common/ImageSlider.vue";
import ImageSliderMixin from "@/mixins/ImageSliderMixin";
export default {
  name: 'FileTree',
  mixins: [vueAppMixin,ImageSliderMixin],
  components: {ImageSlider, FileTree},
  props: {
    files: {
      type: Array,
      required: true,
    },
  },
  methods: {
    toggleFolder(file) {
      if (file && file.type === 'folder') {
        file.open = !file.open;
      } else {
        this.$emit('fileClicked', file);
      }

    },
    handleSelect(file) {
      // Emit the selected file or folder to parent component
      this.$emit('select', file);
    },
  },
};
</script>

<template>
  <div class="file-tree">
    <ul>
      <li v-for="(file, index) in files" :key="index">
        <div class="tree-node">
          <!-- Checkbox for selecting files/folders -->
          <input
            type="checkbox"
            v-model="file.selected"
            @change="handleSelect(file)"
            class="select-box"
            :disabled="file.disabled"
          />

          <span @click="toggleFolder(file)" style="display: flex; align-items: center;">
            <template v-if="file.type === 'folder'">
              <svg width="24px" height="24px" viewBox="0 0 1024 1024" class="icon"  version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M853.333333 256H469.333333l-85.333333-85.333333H170.666667c-46.933333 0-85.333333 38.4-85.333334 85.333333v170.666667h853.333334v-85.333334c0-46.933333-38.4-85.333333-85.333334-85.333333z" fill="#FFA000" /><path d="M853.333333 256H170.666667c-46.933333 0-85.333333 38.4-85.333334 85.333333v426.666667c0 46.933333 38.4 85.333333 85.333334 85.333333h682.666666c46.933333 0 85.333333-38.4 85.333334-85.333333V341.333333c0-46.933333-38.4-85.333333-85.333334-85.333333z" fill="#FFCA28" /></svg>
            </template>
            <template v-else>
              <svg width="24px" height="24px" viewBox="0 0 1024 1024" class="icon"  version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M853.333333 960H170.666667V64h469.333333l213.333333 213.333333z" fill="#90CAF9" /><path d="M821.333333 298.666667H618.666667V96z" fill="#E1F5FE" /><path d="M448 490.666667l-149.333333 213.333333h298.666666z" fill="#1565C0" /><path d="M597.333333 563.2L490.666667 704h213.333333z" fill="#1976D2" /><path d="M672 522.666667m-32 0a32 32 0 1 0 64 0 32 32 0 1 0-64 0Z" fill="#1976D2" /></svg>
            </template>

              <!-- Display the file or folder name -->
            <template v-if="file.type === 'folder' && file.children.length === 0">
              <span class="file-name no-select text-gray" style="color: #878787">
                {{ file.name }}
              </span>
            </template>
            <template v-else>
              <span class="file-name no-select">
                {{ file.name }}
              </span>
            </template>
          </span>
        </div>

        <!-- Recursively render the component for folders -->
        <file-tree v-if="file.type === 'folder' && file.open" :files="file.children" @select="handleSelect" @fileClicked="toggleFolder"/>

      </li>
    </ul>


<!--    <image-slider-->
<!--      :selected-image-index="selectedImageIndex"-->
<!--      :images="sliderImages"-->
<!--      :visible="showSlider"-->
<!--      @hideSlider="resetSlider()"-->
<!--    >-->
<!--    </image-slider>-->
  </div>
</template>

<style scoped>
/* Basic tree structure */
.file-tree ul {
  list-style-type: none;
  padding-left: 0px;
  position: relative;
}

/* Add tree lines */
.file-tree ul::before {
  content: '';
  border-left: 3px solid #737373;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 5px;
  width: 0;
}

/* Each node in the tree */
.file-tree li {
  position: relative;
  padding-left: 20px;
  margin-left: 5px;
}

.file-tree li::before {
  content: '';
  border-top: 3px solid #737373;
  position: absolute;
  top: 15px;
  left: 0;
  width: 20px;
}

.tree-node {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.icon {
  width: 20px;
  text-align: center;
  margin-right: 5px;
}

.file-name {
  margin-left: 5px;
  font-size: 20px;
}

/* Folder and file icon styles */
.icon-folder {
  background: url('/public/img/icons/folder-svgrepo-com.svg') no-repeat center center;
  width: 16px;
  height: 16px;
}

.icon-folder-open {
  background: url('/public/img/icons/folder-svgrepo-com.svg') no-repeat center center;
  width: 16px;
  height: 16px;
}

.icon-file {
  background: url('/public/img/icons/folder-svgrepo-com.svg') no-repeat center center;
  width: 16px;
  height: 16px;
}

/* Style for the checkbox */
.select-box {
  margin-right: 5px;
}
</style>
