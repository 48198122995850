import {ManningService} from "@/services/ManningService";
import {GlobalService} from "@/services/GlobalService";
import {DataService} from "@/services/DataService";


const state = {
  mannings: [],
  manningKeys: [],
};


const mutations = {
  setMannings: (state, mannings) => state.mannings = mannings,
  setManningKeys: (state, manningKeys) => state.manningKeys = manningKeys,
};


const actions = {
  async getMannings({ commit }) {
    let mannings = await ManningService.getMannings();
    if(mannings) commit('setMannings', mannings);
  },

  async getManningKeys({ commit }) {
    let manningKeys = await ManningService.getManningKeys();
    if(manningKeys) commit('setManningKeys', manningKeys);
  },
};


const getters = {
  mannings: state => DataService.capitalizeProperties(state.mannings),
  manningKeys: state => DataService.capitalizeProperties(state.manningKeys),
};


/** export **/
export default {state, actions, mutations, getters};
