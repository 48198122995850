import { ReportService } from '@/services/ReportService'
import {KeyService} from "@/services/KeyService";

const state = {
  aiCompanyInstructions: [],
  allAiCompanyInstructions: [],
  aiVesselCompInstructions: [],
  airCiTypeKeys: []
}

const mutations = {
  setAiCompanyInstructions: (state, aiCompanyInstructions) => state.aiCompanyInstructions = aiCompanyInstructions,
  setAllAiCompanyInstructions: (state, allAiCompanyInstructions) => state.allAiCompanyInstructions = allAiCompanyInstructions,
  setAiVesselCompInstructions: (state, aiVesselCompInstructions) => state.aiVesselCompInstructions = aiVesselCompInstructions,
  setAirCiTypeKeys: (state, airCiTypeKeys) => state.airCiTypeKeys = airCiTypeKeys
}

const actions = {
  async getAllAiCompanyInstructions({commit}, params) {
    const allCompanyInstruction = await ReportService.getAllAICompanyInstruction(params)
    if (allCompanyInstruction) {
      commit('setAllAiCompanyInstructions', allCompanyInstruction.data)
      commit('setPagination',allCompanyInstruction)
    }
  },
  async getAiCompanyInstructions({commit}, reportId) {
    const companyInstruction = await ReportService.getAICompanyInstruction(reportId)
    if (companyInstruction) {
      commit('setAiCompanyInstructions', companyInstruction.data)
      commit('setPagination',companyInstruction)
    }
  },

  async getAiVesselCompInstructions({commit}, params) {
    const vesselInstructions = await ReportService.getVesselCompInstructions(params)
    if (vesselInstructions) {
      commit('setAiVesselCompInstructions', vesselInstructions.data)
      commit('setPagination',vesselInstructions)
    }
  },

  async getAirCiTypeKeys({commit}){
    const ciTypeKeys = await KeyService.getCiTypeKeys()
    if (ciTypeKeys) commit('setAirCiTypeKeys', ciTypeKeys)
  },
}

const getters = {
  allAiCompanyInstructions:state => state.allAiCompanyInstructions,
  aiCompanyInstructions:state => state.aiCompanyInstructions,
  aiVesselCompInstructions:state => state.aiVesselCompInstructions,
  airCiTypeKeys:state => state.airCiTypeKeys
}

export default {
  state,
  mutations,
  actions,
  getters
}
