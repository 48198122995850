'use strict'

const Rightship = () => import('@/views/Rightship')
const RsIndex = () => import('@/components/modules/rightship/RsIndex')
const RsMain = () => import('@/components/modules/rightship/RsMain')
const RsGeneral = () => import('@/components/modules/rightship/RsGeneral')
const RsInspection = () => import('@/components/modules/rightship/RsInspection')
const RsNonConformity = () => import('@/components/modules/rightship/RsNonConformity')
const NonConformityDetail = () => import('@/components/modules/rightship/non-conformity/NonConformityDetail')
const NonConformityCorrectiveAction = () => import('@/components/modules/rightship/non-conformity/NonConformityCorrectiveAction')
const NonConformityPreventativeMeasure = () => import('@/components/modules/rightship/non-conformity/NonConformityPreventativeMeasure')
const NonConformityCompanyInstruction = () => import('@/components/modules/rightship/non-conformity/NonConformityCompanyInstruction')
const ShipParticular = () => import('@/components/modules/accident-incident/ShipParticular');


export const RouteRightship = {
    path: 'rs/',
    name: 'Rightship',
    redirect: 'rs/index',
    component: Rightship,
    children: [
        {
            path: 'index/',
            name: 'RsIndex',
            component: RsIndex,
        },
        {
            path: ':id/',
            name: 'RsMain',
            component: RsMain,
            redirect: ':id/general',
            children: [
                {
                    path: 'general/',
                    name: 'RsGeneral',
                    component: RsGeneral
                },
                {
                    path: 'inspection/',
                    name: 'RsInspection',
                    component: RsInspection
                },
                {
                    path: 'non-conformity/',
                    name: 'RsNonConformity',
                    component: RsNonConformity,
                    children: [
                        {
                            path: 'detail/',
                            name: 'NonConformityDetail',
                            component: NonConformityDetail
                        },
                        {
                            path: 'corrective-action/',
                            name: 'NonConformityCorrectiveAction',
                            component: NonConformityCorrectiveAction
                        },
                        {
                            path: 'preventative-measure/',
                            name: 'NonConformityPreventativeMeasure',
                            component: NonConformityPreventativeMeasure
                        },
                        {
                            path: 'company-instruction/',
                            name: 'NonConformityCompanyInstruction',
                            component: NonConformityCompanyInstruction
                        },
                    ]
                },

                {
                    path: 'ship-particular/:vesselId',
                    name: 'RsShipParticular',
                    component: ShipParticular,
                },
            ]
        }
    ]
}
