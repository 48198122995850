import {KeyService} from "@/services/KeyService";
import {GlobalService} from "../../services/GlobalService";

const state = {
  PortKeys: [],
}

const mutations = {
  SET_PORT_KEYS: (state, params) => {
    state.PortKeys = params;
  }
}

const actions = {
  // async getPortKeys({commit}) {
  //   const response = await KeyService.getPortKeys();
  //   if (response) {
  //     commit('SET_PORT_KEYS', response)
  //   }
  //   return false;
  // }
}

const getters = {
  portKeys: (state) => state.PortKeys
}

export default {
  state,
  actions,
  mutations,
  getters
}
