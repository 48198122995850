export default {
  data() {
    return {
      showSlider : false,
      selectedImageIndex : 0,
      sliderImages : [],
    }
  },
  methods: {
    convertToSliderData(images, keyRules) {
      images.forEach(image => {
        for (let imageKey in image) {
          if (keyRules.path === imageKey) {
            image.path = image[imageKey];
          }
          if (keyRules.caption === imageKey) {
            image.caption = image[imageKey];
          }
        }
      });
      return images;
    },
    viewImage(index, images, keyRules) {
      if (keyRules === undefined) {
        this.sliderImages = images;
      } else {
        this.sliderImages = this.convertToSliderData(images, keyRules);
      }
      this.selectedImageIndex = index;
      this.showSlider = true;
    },
    resetSlider() {
      this.showSlider = false;
      this.sliderImages = [];
      this.selectedImageIndex = 0;
    }
  },
}
