import { KeyService } from '../../services/KeyService'

const state = {
  mngmntOfficeKeys: [],
  mngmntOfficeVesselsKeys : []
}

const mutations = {
  // eslint-disable-next-line no-return-assign
  setMngmtOfficeKeys: (state, mngmntOfficeKeys) => state.mngmntOfficeKeys = mngmntOfficeKeys,
  setMngmtOfficeVesselsKeys: (state, mngmntOfficeVesselsKeys) => state.mngmntOfficeVesselsKeys = mngmntOfficeVesselsKeys,
  // eslint-disable-next-line no-return-assign
  SET_MNGMT_OFFICE_KEYS: (state, mngmntOfficeKeys) => state.mngmntOfficeKeys = mngmntOfficeKeys
}

const actions = {
  async getMngmtOfficeKeys ({ commit },params) {
    const mngmntOfficeKeys = await KeyService.getMngmtOfficeKeys(params)
    if (mngmntOfficeKeys) commit('setMngmtOfficeKeys', mngmntOfficeKeys)
  },
  async getManagementVessels({commit}) {
    const mngmntOfficeVesselsKeys = await KeyService.getMngmtOfficeVesselsKeys()

    // test data only
    // const mngmntOfficeVesselsKeys = [
    //   {
    //     id : 1,
    //     name : 'Kobe',
    //     vessels : [
    //       {
    //         id : 1,
    //         name : 'AEGEAN HIGHWAY'
    //       }
    //     ]
    //   },
    //   {
    //     id : 2,
    //     name : 'Kobe 2',
    //     vessels : [
    //       {
    //         id : 2,
    //         name : 'ADRIATIC HIGHWAY'
    //       }
    //     ]
    //   }
    // ]

    if (mngmntOfficeVesselsKeys) commit('setMngmtOfficeVesselsKeys', mngmntOfficeVesselsKeys)
  },
}

const getters = {
  mngmntOfficeKeys: state => state.mngmntOfficeKeys,
  mngmntOfficeVesselsKeys: state => state.mngmntOfficeVesselsKeys,
}

export default {
  state,
  mutations,
  actions,
  getters
}
