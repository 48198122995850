import {GlobalService} from "@/services/GlobalService";
import {
  ACCIDENT_INCIDENT,
  AUDITOR_CERTIFICATE,
  AUDITOR_DEPARTMENTS,
  AUDITOR_INDEX, AUDITOR_POSITIONS,
  PSC_INDEX, USER_FOR_AUDITOR
} from "@/services/ConstantService";
import axios from "axios";
import da from "vue2-datepicker/locale/es/da";

const state = {
  auditorList : [],
  leadAuditors : [],
  nonLeadAuditors : [],
  auditorData : {
    first_name: '',
    last_name: '',
    middle_name: null,
    suffix: null,
    contact_number: '',
    email: '',
    management_office_id: null,
    is_internal_of_external_auditor: false,
    is_lead: false,
    profile_photo: '',
    fullname: '',
    management_office: {
      code: '',
      name: '',
      description: '',
      active: '',
      management_company_id: null,
    },
    lead: [],
    members: [],
    certificate: null
  },

  audit_team_leaders : [],
  auditor_departments : [],
  auditor_positions : [],
}

const actions = {
  async getUserForAuditor({ commit }, userId) {
    const response = await GlobalService.ajax('get', USER_FOR_AUDITOR.replace('{user_id}',userId));
    return response?.data;
  },
  async getAuditorList({ commit }, params) {
    const response = await GlobalService.ajax('get', AUDITOR_INDEX, {params});
    const data = response?.data;
    if (params['filter[is_lead]'] !== undefined) {
      if (params['filter[is_lead]'] === 1) {
        commit('SET_LEAD_AUDITORS', data?.data);
      } else {
        commit('SET_NON_LEAD_AUDITORS', data?.data);
      }
    } else {
      commit('SET_AUDITORS_LIST', data?.data);
      commit('setPagination', data?.meta)
    }
  },


  async getAuditorData({ commit }, params) {
    const response = await GlobalService.ajax('get', AUDITOR_INDEX +`/${params.auditor_id}`);
    const data = response?.data;
    commit('SET_AUDITOR_DATA', data?.data);
  },

  async deleteAuditor({ commit }, params) {
    return await axios.delete(AUDITOR_INDEX +`/${params.auditor_id}`,{data:params}).then(response => {
      if (response.hasOwnProperty('status') && response.status === 200) {
        return true;
      }
      return response;
    }).catch(error => {
      if(error.response.status === 500){
        GlobalService.handleError(error, 'DELETE REPORT SERVICE')
      }
      return error.response.data
    })
  },

  async createAuditor({ commit }, params) {
    const response = await GlobalService.ajax('post', AUDITOR_INDEX, params);
    if (response.hasOwnProperty('status') && response.status === 201) {
      return {
        result : true,
        data : response.data
      };
    }
    return {
      result : false,
      message : response.message
    };
  },

  async updateAuditor({ commit }, params) {
    const uri = [AUDITOR_INDEX, params.id].join('/') + '?_method=PATCH';

    const response = await GlobalService.ajax('post', uri, params.formData);

    if (response.hasOwnProperty('status') && response.status === 200) {
      return {
        result : true,
        data : response.data
      };
    }
    return {
      result : false,
      message : response.message
    };
  },
  async deleteAuditorCertificate({ commit }, certificateId) {
    const uri = [AUDITOR_CERTIFICATE, certificateId].join('/');
    const response = await GlobalService.ajax('delete', uri);

    if (response.hasOwnProperty('status') && response.status === 200) {
      return {
        result : true,
        data : response.data
      };
    }
    return {
      result : false,
      message : response.message
    };
  },
  async getAuditTeamLeaders({ commit }){
    const auditTeamLeaders = [
      {
        id : 1,
        name : 'Dimitar Krachev'
      },
      {
        id : 2,
        name : 'Marlon San Buenaventura'
      },
      {
        id : 3,
        name : 'Iliya Draganov'
      },
      {
        id : 4,
        name : 'Capt. Richard N. Guevarra'
      },
    ];

    commit('SET_AUDIT_TEAM_LEADERS', auditTeamLeaders);
  },
  async getAuditorDepartments({ commit }) {
    const response = await GlobalService.ajax('get', AUDITOR_DEPARTMENTS);
    const data = response?.data;
    commit('SET_AUDITOR_DEPARTMENTS', data?.data);
  },

  async getAuditorPositions({ commit }) {
    const response = await GlobalService.ajax('get', AUDITOR_POSITIONS);
    const data = response?.data;
    commit('SET_AUDITOR_POSITIONS', data?.data);
  },

}

const mutations = {
  SET_AUDITORS_LIST : (state, auditorList) => state.auditorList = auditorList,
  SET_AUDITOR_DATA : (state, auditorData) => state.auditorData = auditorData,
  SET_LEAD_AUDITORS : (state, leadAuditors) => state.leadAuditors = leadAuditors,
  SET_NON_LEAD_AUDITORS : (state, nonLeadAuditors) => state.nonLeadAuditors = nonLeadAuditors,
  SET_AUDIT_TEAM_LEADERS : (state, audit_team_leaders) => state.audit_team_leaders = audit_team_leaders,
  SET_AUDITOR_DEPARTMENTS : (state, auditor_departments) => state.auditor_departments = auditor_departments,
  SET_AUDITOR_POSITIONS : (state, auditor_positions) => state.auditor_positions = auditor_positions,
}

const getters = {
  auditorList : state => state.auditorList,
  auditorData : state => state.auditorData,
  leadAuditors : state => state.leadAuditors,
  nonLeadAuditors : state => state.nonLeadAuditors,
  audit_team_leaders : state => state.audit_team_leaders,
  auditor_departments : state => state.auditor_departments,
  auditor_positions : state => state.auditor_positions,
}

export default {
  state,
  actions,
  mutations,
  getters
}
