import axios from "axios";
import {RELATED_PARTIES, RELATED_PARTY, RELATED_PARTY_VESSEL_IDS} from "@/services/ConstantService";
import {GlobalService} from "@/services/GlobalService";

export const RelatedPartyService = {
  async getRelatedPartyVesselIds (params) {
    return await axios.post(RELATED_PARTY_VESSEL_IDS, params).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'getRelatedPartyVesselIds SERVICE')
      return false
    })
  },

  async getRelatedPartyLists () {
    return await axios.get(RELATED_PARTIES).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET RELATED PARTY SERVICE')
      return false
    })
  },

  async addRelatedParty(params) {
    return await axios.post(RELATED_PARTY,params).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'ADD RELATED PARTY SERVICE')
      return error.response.data
    })
  },
  async updateRelatedParty(params) {
    return await axios.put(RELATED_PARTY + `/${params.id}`,params).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'UPDATE RELATED PARTY SERVICE')
      return error.response.data
    })
  },

  async deleteRelatedParty(params) {
    return await axios.delete(RELATED_PARTY + `/${params.id}`).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'UPDATE RELATED PARTY SERVICE')
      return error.response.data
    })
  }
}

export default { RelatedPartyService }
