<template>
      <div id="attachment-card" class="card py-1" :class="{'e-bg-green':otherAttachment.isNew}"
           :title="otherAttachment[attachmentName]">

        <div class="dropdown dropright mb-2">
          <div
            data-toggle="dropdown"
            id="attachmentDropdown"
            class="text-center"
            aria-haspopup="true" style="cursor:pointer"
          >
            <div class="card-img-top text-center">

              <img :src="otherAttachment.thumbnail" alt="Attachment Thumbnail" style="width: 50px">

            </div>
            <small id="attachment-name">{{otherAttachment[attachmentName]}}</small>
          </div>
          <div aria-labelledby="attachmentDropdown" class="dropdown-menu">
            <button class="dropdown-item text-secondary font-weight-bolder"
                    type="button"
                    title="View / Download Attachment"
                    @click="$emit('viewOther',otherAttachment)"
            >
              <font-awesome-icon class="ml-2 mr-1 text-primary" icon="eye"/>
              View / Download Attachment
            </button>

            <template v-if="!noDelete">
              <button class="dropdown-item  text-danger font-weight-bolder"
                      @click="$emit('deleteOther',otherAttachment)"
                      title="Delete Attachment"
                      type="button"
              >
                <font-awesome-icon class="ml-2 mr-1 force-danger-all" icon="trash"/>
                Delete Attachment
              </button>
            </template>
          </div>
        </div>
      </div>
</template>

<script>
export default {
  name: "OtherComp",
  data() {
    return {
      otherAttachment: ''
    }
  },

  props: {
    attachment: {
      type: Object,
      default: null,
    },
    attachmentName:{
      type:String,
      default: 'attachment_name'
    },

    noDelete :{
      type:Boolean,
      default:false,
    }
  },
  created() {
    this.otherAttachment = Object.assign({}, this.attachment);
  }
}
</script>
<style scoped>
#attachment-card:hover > .dropdown small {
  color: white;
}

#attachment-card:hover {
  background: rgba(255, 0, 0, 0.7);
  transition: 0.5s;
}

#attachment-name{
  width: 100%;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#attachment-card.e-bg-green  small {
  color: white;
}
</style>
