import {EngineAuxTypeService} from "@/services/EngineAuxTypeService";
import {GlobalService} from "@/services/GlobalService";

const state={
  engineAuxType:[],
}

const mutations={
  SET_ENGINE_AUX_TYPE : (state,payload)=>state.engineAuxType=payload,
  ADD_ENGINE_AUX_TYPE : (state,payload)=>{
    if(state.engineAuxType.length > 15) state.engineAuxType.pop()

    state.engineAuxType.push(payload)

    state.engineAuxType.sort((a,b)=> GlobalService.sortFunc(a,b));
  },

  UPDATE_ENGINE_AUX_TYPE : (state,payload) => {
    let index= state.engineAuxType.findIndex(maker=> maker.id===payload.id);

    state.engineAuxType.splice(index,1,payload);

    state.engineAuxType.sort((a,b)=> GlobalService.sortFunc(a,b));
  }
}

const actions={
  async getEngineAuxTypes({commit},params){
    const response= await EngineAuxTypeService.getEngineAuxTypes(params)
    const isGet= params?.get ?? false
    if(response && !isGet){
      commit('setPagination',response)
      commit ('SET_ENGINE_AUX_TYPE',response.data)
    }
    else {
      commit ('SET_ENGINE_AUX_TYPE',response)
    }
    return response;
  },

  async createEngineAuxType({commit},params){
    const response= await EngineAuxTypeService.createEngineAuxType(params)
    if(!response.errors){
      commit ('ADD_ENGINE_AUX_TYPE',response)
    }
    return response;
  },

  async updateEngineAuxType({commit},params){
    const response= await EngineAuxTypeService.updateEngineAuxType(params)
    if(!response.errors){
      commit ('UPDATE_ENGINE_AUX_TYPE',response)
    }
    return response;
  },

  async deleteEngineAuxType({commit},params){
    return await EngineAuxTypeService.deleteEngineAuxType(params);
  }
}

const getters={
  engineAuxType:(state)=>GlobalService.capitalizeProperties(state.engineAuxType)
}


export default {state,actions,mutations,getters}
