import {GlobalService} from "@/services/GlobalService";
import {EngineDgTypeService} from "@/services/EngineDgTypeService";

const state={
  engineDgType:[],
}

const mutations={
  SET_ENGINE_DG_TYPE : (state,payload)=>state.engineDgType=payload,
  ADD_ENGINE_DG_TYPE : (state,payload)=>{
    if(state.engineDgType.length > 15) state.engineDgType.pop()

    state.engineDgType.push(payload)

    state.engineDgType.sort((a,b)=> GlobalService.sortFunc(a,b));
  },

  UPDATE_ENGINE_DG_TYPE : (state,payload) => {
    let index= state.engineDgType.findIndex(maker=> maker.id===payload.id);

    state.engineDgType.splice(index,1,payload);

    state.engineDgType.sort((a,b)=> GlobalService.sortFunc(a,b));
  }
}

const actions={
  async getEngineDgTypes({commit},params){
    const response= await EngineDgTypeService.getEngineDgTypes(params)
    const isGet= params?.get ?? false
    if(response && !isGet){
      commit ('setPagination',response)
      commit ('SET_ENGINE_DG_TYPE',response.data)
    }
    else {
      commit ('SET_ENGINE_DG_TYPE',response)
    }
    return response;
  },

  async createEngineDgType({commit},params){
    const response= await EngineDgTypeService.createEngineDgType(params)
    if(!response.errors){
      commit ('ADD_ENGINE_DG_TYPE',response)
    }
    return response;
  },

  async updateEngineDgType({commit},params){
    const response= await EngineDgTypeService.updateEngineDgType(params)
    if(!response.errors){
      commit ('UPDATE_ENGINE_DG_TYPE',response)
    }
    return response;
  },

  async deleteEngineDgType({commit},params){
    return await EngineDgTypeService.deleteEngineDgType(params);
  }
}

const getters={
  engineDgType:(state)=>GlobalService.capitalizeProperties(state.engineDgType)
}


export default {state,actions,mutations,getters}
