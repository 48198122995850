import { ReportService } from '../../services/ReportService'
import attachment from "@/components/modules/accident-incident/Attachment";
import {KeyService} from "@/services/KeyService";
import {GlobalService} from "@/services/GlobalService";
import {CurrencyService} from "@/services/CurrencyService";
import personnel from "@/components/modules/accident-incident/personnel/Personnel";
import {AlertService} from "@/services/AlertService";

const state = {
  // report: {},
  reports: [],
  accidentIncidentReport: {},
  currencyKeys: [],
  images: [],
  documents:[],
  reportStatusKeys:[],
  accidentIncidentPerEvent:[],
}

const mutations = {
  // eslint-disable-next-line no-return-assign
  setCurrencies: (state, payload) => state.currencyKeys = payload,
  // eslint-disable-next-line no-return-assign
  setReport: (state, report) => state.report = report,
  // eslint-disable-next-line no-return-assign
  setReports: (state, reports) => state.reports = reports,
  // eslint-disable-next-line no-return-assign
  setAccidentIncidentReport: (state, accidentIncidentReport) => state.accidentIncidentReport = accidentIncidentReport,

  resetPersonnels: (state) => {
    state.accidentIncidentReport.loss.personnel= state.accidentIncidentReport.loss.personnel.length ?
      state.accidentIncidentReport.loss.personnel.filter(personnel=> !!personnel.id) : [];
  },

  setImages:(state,images)=>{
    state.images=images;
  },

  setDocuments:(state,documents)=>{
    state.documents=documents;
  },
  addAccidentIncidentAttachments:(state,attachments)=>{
    attachments.forEach((attachment,index)=>{
      const type= attachment.isImage ? 'images':'documents';
      if(index===0){
        state[type]=state[type].map(file=>{
          file.isNew=false
          return file;
        });
      }
      state[type].push(attachment);
    })
  },
  deleteAccidentIncidentAttachment:(state,attachment)=>{
    if(attachment.isImage){
      state.images=state.images.filter(file=>file.id!==attachment.id)
    }
    else {
      state.documents=state.documents.filter(file=>file.id!==attachment.id)
    }
  },

  removeReport: (state,reportId)=> {
    let index=state.reports.findIndex(report=>report.id===reportId);
    if(index >= 0){
      state.reports.splice(index,1);
    }
  },

  setReportStatus: (state,reportStatus) =>{
    const sortingArray=[1,2,4,3,5];
    state.reportStatusKeys=reportStatus.sort(function (a,b){
      return sortingArray.indexOf(a.id) - sortingArray.indexOf(b.id);
    })
  },

  setAccidentIncidentEvent:(state,event)=>{
     state.accidentIncidentReport.event=Object.assign({},event)
  },
  setAccidentIncidentLoss:(state,loss)=>{
    state.accidentIncidentReport.loss=Object.assign({},loss)
  },
  // setAccidentIncidentLossPersonnel:(state,loss)=>{
  //   state.accidentIncidentReport.loss.personnel=loss
  // },
  setAccidentIncidentCause:(state,cause)=>{
    state.accidentIncidentReport.cause=Object.assign({},cause)
  },
  setAccidentIncidentPreventive:(state,preventive)=>{
    state.accidentIncidentReport.preventive=Object.assign({},preventive)
  },
  setAccidentIncidentCorrective:(state,corrective) => {
    state.accidentIncidentReport.corrective=Object.assign({},corrective)
  },
  removePersonnel:(state,personnelId)=>{
    state.accidentIncidentReport.loss.personnel= state.accidentIncidentReport.loss.personnel.filter(personnel=> personnel.id !== personnelId)
  },
  setAccidentIncidentReportStatus:(state,reportStatusId)=>state.accidentIncidentReport.report_status_id=reportStatusId,
  setAccidentIncidentPerEvent:(state,event)=>state.accidentIncidentPerEvent=event
}

const actions = {
  async getReports ({ commit }, params) {
    const allReport = await ReportService.getAllReport(params)
    if (allReport) {
      commit('setReports', allReport?.data)
      commit('setPagination',allReport?.meta)
    }
  },
  async getAccidentIncidentReport ({ commit }, params) {
    const accidentIncident = await ReportService.getAccidentIncidentReport(params)
    if (!accidentIncident.errors) {
      commit('setAccidentIncidentReport', accidentIncident)
    }
    return accidentIncident;
  },

  async updateAccidentIncidentReport({commit},params){
    const response = await ReportService.updateAccidentIncidentReport(params);

    if (response.status > 299) {

      const errors = response.data.hasOwnProperty('errors') ? response.data.errors : response.data;
      let error_message = 'Error in updating<br>';

      Object.keys(errors).forEach(error=>{
        console.log(error)
        error_message += '<small><br><b>*' + error + ':</b> ' + errors[error].join(', ') + '</small>';
      })

      AlertService.errorAlert(error_message, 'Updating Accident Incident Report')

      return false;
    }

    commit('setAccidentIncidentReport', response.data)
    return response.data;
  },

  async updateAccidentIncidentLoss({commit},params){
    const accidentIncidentLoss= await ReportService.updateAccidentIncidentLoss(params);
    if(accidentIncidentLoss) {
      commit('setAccidentIncidentLoss',accidentIncidentLoss)
      return true;
    }
    return false;
  },
  async getAccidentIncidentEvent({commit},params){
    const accidentAccidentEvent = await ReportService.getAccidentIncidentEvent(params);
    if(accidentAccidentEvent){
      commit('setAccidentIncidentEvent',accidentAccidentEvent)
    }
    return false;
  },

  async updateAccidentIncidentEvent({commit},params){
    const accidentAccidentEvent = await ReportService.updateAccidentIncidentEvent(params);
    if(accidentAccidentEvent){
      commit('setAccidentIncidentEvent',accidentAccidentEvent)
      return true;
    }
    return false;
  },

  async updateAccidentIncidentCause({commit},params){
    const accidentAccidentCause = await ReportService.updateAccidentIncidentCause(params);
    if(accidentAccidentCause){
      commit('setAccidentIncidentCause',accidentAccidentCause)
      return true;
    }
    return false;
  },

  async updateAccidentIncidentPreventive({commit},params){
    const accidentAccidentPreventive = await ReportService.updateAccidentIncidentPreventive(params);
    if(accidentAccidentPreventive){
      commit('setAccidentIncidentPreventive',accidentAccidentPreventive)
      return true;
    }
    return false;
  },

  async updateAccidentIncidentCorrective({commit},params){
    const accidentAccidentCorrective = await ReportService.updateAccidentIncidentCorrective(params);
    if(accidentAccidentCorrective){
      commit('setAccidentIncidentCorrective',accidentAccidentCorrective)
      return true;
    }
    return false;
  },

  async deletePersonnel({commit},params){
    const deletedPersonnelResponse = await ReportService.deleteLossPersonnel(params)
    if(deletedPersonnelResponse?.success){
      return true;
    }
    return false;
  },


  async getCurrencyKeys ({ commit }) {
    const currencies =  await ReportService.getCurrencyKeys();
    if (currencies)  {
      commit('setCurrencies', currencies)
    }
    return false;
  },

  async getCurrenciesList({commit},params){
    const currencies =await CurrencyService.getCurrencyKeys(params)
    if (currencies) {
      commit('setCurrencies', currencies.data)
      commit('setPagination',currencies)
    }

  },

  async getReportStatus({commit}, report_status_id = null){
    const reportStatus=await KeyService.getReportStatusKeys(report_status_id)
    if(reportStatus) commit('setReportStatus',reportStatus)
  },
  async addAttachments({commit},params){
    const attachments = await ReportService.addAttachments(params)
    if (attachments[0].id){
        commit('addAccidentIncidentAttachments',attachments);
        return true;
    }
  },
  async deleteAttachment({commit,state},attachment){
    const response = await ReportService.deleteAttachment({attachmentId:attachment.id})
    if (response)  return response;
  },
  async getAccidentIncidentReportAttachments({commit},params){
    // @TODO: ERROR LOG - [vuex] unknown mutation type: setAccidentIncidentAttachments
    commit('setAccidentIncidentAttachments', [])
    commit(params.isImage ? 'setImages':'setDocuments',[]);
    const attachments = await ReportService.getAccidentIncidentAttachments(params)
    if(attachments.length){
      commit(attachments[0].isImage ? 'setImages':'setDocuments',attachments)
    }
    else {
      commit(params.isImage ? 'setImages':'setDocuments',attachments)
    }
  },
  async deleteReport( {commit} , params){
    const response =await ReportService.deleteReport(params);
    if(response.success){
      commit('removeReport',params.reportId)
      return true
    }
    return response
  },

  async addLossPersonnel({commit},params){
    const personnel= await ReportService.addLossPersonnel(params);
    if(!personnel.errors) {
      commit('setAccidentIncidentLoss',personnel)
      return true;
    }
    return personnel;
  },

  async getAccidentIncidentLoss({commit},params){
    const loss= await ReportService.getAccidentIncidentLoss(params);
    if(loss) {
      commit('setAccidentIncidentLoss',loss)
      return loss;
    }
    return false;
  },
  async getAccidentIncidentByEvent({commit},params){
    const accidentIncidents= await ReportService.getAccidentIncidentByEvent(params);
    if(accidentIncidents) {
      commit('setAccidentIncidentPerEvent', accidentIncidents?.data)
      commit('setPagination',accidentIncidents?.meta)
    }
    return false;
  }
}

const getters = {
  report: state => state.report,
  reports: state => state.reports,
  currencies: state => state.currencyKeys,
  accidentIncidentReport: state => state.accidentIncidentReport,
  // attachmentsLists:state=>state.attachmentsLists,
  images:state=>state.images,
  documents:state=>state.documents,
  reportStatusKeys:state=>GlobalService.capitalizeProperties(state.reportStatusKeys),
  accidentIncidentPerEvent:state=>state.accidentIncidentPerEvent
}

export default {
  state,
  mutations,
  actions,
  getters
}
