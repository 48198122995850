<template>
  <div>
    <h5 class="pl-1 font-weight-bold my-2">SEA EXPERIENCE</h5>
    <table class="table table-sm table-bordered small">
      <thead>
      <tr>
        <th class="align-middle text-center">Rank</th>
        <th class="align-middle text-center">Manning</th>
        <th class="align-middle text-center">Vessel Type</th>
        <th class="align-middle text-center">Vessel</th>
        <th class="align-middle text-center">Engine Capacity</th>
        <th class="align-middle text-center">Route</th>
        <th class="align-middle text-center">Sign In</th>
        <th class="align-middle text-center">Sign Off</th>
        <th class="align-middle text-center">Reason</th>
      </tr>
      </thead>
      <tbody>
          <template v-if="seaExperience">
            <tr v-for="experience in seaExperience">
              <td>{{experience.rankName}}</td>
              <td>{{experience.manningName ? experience.manningName.toUpperCase()  : ''}}</td>
              <td>{{experience.vesselTypeName ? experience.vesselTypeName.toUpperCase() : ''}}</td>
              <td>{{experience.vessel ? experience.vessel.toUpperCase()  : ''}}</td>
              <td>{{experience.engineCapacity ? experience.engineCapacity.toUpperCase() : ''}}</td>
              <td>{{experience.route ? experience.route.toUpperCase() : ''}}</td>
              <td>{{experience.signIn ? experience.signIn.toUpperCase() : ''}}</td>
              <td>{{experience.signOff ? experience.signOff.toUpperCase() : ''}}</td>
              <td :title="experience.reasonName ? experience.reasonName.toUpperCase() : ''">{{experience.reasonCode ? experience.reasonCode.toUpperCase() : ''}}</td>
            </tr>
          </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "SeaExperience",
   async created(){
    const params={
      cms_id:this.$route.params.id
    }
      await this.getSeaExperience(params)
  },
  methods:{
    ...mapActions(['getSeaExperience'])
  },
  computed:{
    ...mapGetters(['seaExperience'])
  }
}
</script>

<style scoped>

</style>
